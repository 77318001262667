import { QueryKeys } from "../../constants/query-keys";
import { fetchMmrFormData } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreateMRRFormDataQuery = (orderId) => {
  const mmrFormDataQuery = useQuery(
    [QueryKeys.queryCreateMRRFormData, orderId],
    () => fetchMmrFormData(orderId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(orderId)
    }
  );
  return mmrFormDataQuery;
};

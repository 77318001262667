import { Box, Button, Grid, Typography } from "@mui/material";
import { transport_labels, warehouse_labels } from "../../constants";

import TextInput from "../../shared/formElements/TextInput";
import { TruckFormKeys } from "../../constants/formKeys";
import { useCustomDialog } from "../../shared/customDialog";
import { useForm } from "react-hook-form";
import { useUpdateTruckMutateQuery } from "../../query-hooks/TransportDashboard/useUpdateTruckMutateQuery";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const UpdateTruckDetailsForm = (props) => {
  const { hideDialog } = useCustomDialog();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      [TruckFormKeys.truckMake]: props.make.name,
      [TruckFormKeys.truckModel]: props.model.name,
      [TruckFormKeys.pricePerKm]: props.rate,
      [TruckFormKeys.truckNumber]: props.regNo,
    },
  });

  const { mutate, isLoading, isError, error } = useUpdateTruckMutateQuery();

  const onSubmit = (data) => {
    mutate(
      {
        partnerId: props.partnerId,
        data: {
          rate: data[TruckFormKeys.pricePerKm],
        },
        vehicleId: props._id,
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("Vehicles Price/Km Updated", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.data.errors, error?.response.data.message, isError]);

  return (
    <Box
      sx={{
        padding: "18px 16px",
      }}
    >
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        marginBottom={4}
      >
        {transport_labels.truckDetailsLabelText}
      </Typography>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              control={control}
              name={TruckFormKeys.truckNumber}
              label={transport_labels.truckNumberLabelText}
              placeholder={transport_labels.truckNumberPlaceholderText}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={control}
              name={TruckFormKeys.truckMake}
              label={transport_labels.truckMakeLabelText}
              placeholder={transport_labels.truckMakePlaceholderText}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={control}
              name={TruckFormKeys.truckModel}
              label={transport_labels.truckModelLabelText}
              placeholder={transport_labels.truckModelPlaceholderText}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              control={control}
              name={TruckFormKeys.pricePerKm}
              label={transport_labels.pricePerKmLabelText}
              placeholder={transport_labels.pricePerKmPlaceholderText}
              type="number"
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="soft"
                sx={{
                  height: 64,
                  mt: "14px",
                }}
                onClick={hideDialog}
              >
                {warehouse_labels.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  height: 64,
                  mt: "14px",
                }}
                component={LoadingButton}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {transport_labels.updatePriceButtonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateTruckDetailsForm;

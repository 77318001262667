import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { CustomCheckedIcon, CustomIcon } from "./CheckBoxGroup";

import { Controller } from "react-hook-form";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { checkboxSelectTheme } from "../theme/checkboxSelectTheme";

const CheckBoxSelect = ({
  label,
  placeholder,
  helperText,
  name,
  control,
  options,
  disabled = false,
  startIcon: StartIcon,
  isLoading = false,
}) => {
  return (
    <ThemeProvider theme={checkboxSelectTheme}>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth disabled={disabled}>
            {label && (
              <InputLabel shrink htmlFor={name}>
                {label}
              </InputLabel>
            )}
            <Select
              {...field}
              inputProps={{
                id: name,
              }}
              multiple={true}
              error={!!error}
              IconComponent={
                isLoading ? CircularProgress : KeyboardArrowDownOutlined
              }
              displayEmpty
              startAdornment={
                StartIcon && (
                  <InputAdornment position="start">
                    <StartIcon />
                  </InputAdornment>
                )
              }
              renderValue={(value) => {
                const selected = value.map((item) => {
                  let valObj = options.find((val) => val.name === item);
                  return valObj;
                });

                return field.value.length > 0 ? (
                  selected.map((item) => item.displayName).join(", ")
                ) : (
                  <Typography fontSize="14px" color="text.disabled">
                    {placeholder}
                  </Typography>
                );
              }}
            >
              {options?.map(({ displayName, name }) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    checked={field.value.indexOf(name) > -1}
                    checkedIcon={<CustomCheckedIcon />}
                    icon={<CustomIcon />}
                  />
                  <ListItemText primary={displayName} />
                </MenuItem>
              ))}
            </Select>
            {helperText && (
              <FormHelperText sx={{ letterSpacing: "0.1em" }}>
                {helperText}
              </FormHelperText>
            )}
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </ThemeProvider>
  );
};

export default CheckBoxSelect;

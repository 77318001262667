import { QueryKeys } from "../constants/query-keys";
import { getActiveTransportInquiry } from "../transport/actions";
import { getActiveWarehouseInquiry } from "../warehouse/actions";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchActiveInquiryQuery = (type = "warehouses") => {
  const activeInquiry = useInfiniteQuery(
    [QueryKeys.queryActiveInquiry],
    type === "warehouses"
      ? ({ pageParam = 1 }) => getActiveWarehouseInquiry(pageParam)
      : ({ pageParam = 1 }) => getActiveTransportInquiry(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.inquiries.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );

  return activeInquiry;
};

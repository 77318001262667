import { Box, Button, FormHelperText, Typography } from "@mui/material";
import DateInput from "../../shared/formElements/DateInput";
import TextInput from "../../shared/formElements/TextInput";
import { useForm } from "react-hook-form";
import { InvoiceUploadKeys } from "../../constants/formKeys";
import { warehouse_labels } from "../../constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PdfAndImageUpload from "../../shared/formElements/PdfAndImageUpload";

export const UploadSchema = yup.object({
  [InvoiceUploadKeys.invoiceId]: yup
    .string()
    .required(`Invoice ID ${warehouse_labels.isRequired}`),
  [InvoiceUploadKeys.invoiceDate]: yup
    .string()
    .required(`Invoice Date ${warehouse_labels.isRequired}`),
  [InvoiceUploadKeys.manualInvoice]: yup
    .mixed()
    .required("PDF file is required")
    .test(
      'fileType',
      'Kindly Upload images (jpg, jpeg, png) or PDF ',
      (value) => {
        const supportedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
        return supportedFileTypes.includes(value.type);
      }
    ),
});

const InvoiceDetailManualTab = ({
  setIsManualInvoice,
  setManualInvoiceData,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [InvoiceUploadKeys.invoiceId]: "",
      [InvoiceUploadKeys.invoiceDate]: "",
    },
    resolver: yupResolver(UploadSchema),
  });

  const onSubmit = (data) => {
    console.log(data)
    setManualInvoiceData(data);
    setIsManualInvoice(false);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={5}
      component={"form"}
      onSubmit={handleSubmit(onsubmit)}
    >
      <Box
        sx={{
          borderRadius: "8px",
          border: "0.4px solid #95A1B0",
        }}
      >
        <Box
          padding={5}
          display={"flex"}
          justifyContent={"space-between"}
          gap={2}
          flexDirection={"column"}
        >
          <Box>
            <Typography fontWeight={600}> Upload Invoice</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} gap={2}>
            <TextInput
              control={control}
              label={"Invoice ID"}
              placeholder={"Enter ID"}
              name={InvoiceUploadKeys.invoiceId}
            />
            <DateInput
              control={control}
              placeholder={"Enter date"}
              label={"Invoice date"}
              name={InvoiceUploadKeys.invoiceDate}
              minDate={"1900/01/01"}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ background: "#F3F5F9", borderTop: "0.4px solid #95A1B0" }}
        >
          <PdfAndImageUpload
            control={control}
            name={InvoiceUploadKeys.manualInvoice}
          />
          <FormHelperText error>{errors.manualInvoice?.message}</FormHelperText>
        </Box>
      </Box>
      <Box
        display={"flex"}
        gap={"10px"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
        justifyContent={"flex-end"}
      >
        <Button
          fullWidth
          variant="soft"
          sx={{
            height: 42,
            width: {
              xs: "100%",
              md: "172px",
            },
          }}
          onClick={() => setIsManualInvoice(false)}
        >
          Skip and Raise Invoice
        </Button>
        <Button
          fullWidth
          variant="contained"
          sx={{
            height: 42,
            width: {
              xs: "100%",
              md: 164,
            },
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Upload
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceDetailManualTab;

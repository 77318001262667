import { Alert, Box, Button, Grid, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { StorageFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { storageAreaFormValidation } from "./SearchFormValidation";
import { useCustomDialog } from "../../shared/customDialog";
import { useForm } from "react-hook-form";
import { useUpdateStorageAreaMutateQuery } from "../../query-hooks/warehouseDashboard/useUpdateStorageAreaMutateQuery";
import { warehouse_labels } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

const StorageAreaForm = (props) => {
  const { hideDialog } = useCustomDialog();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      [StorageFormKeys.available]: props.available,
      [StorageFormKeys.total]: props.total,
    },
    resolver: yupResolver(storageAreaFormValidation),
  });

  const { mutate, isError, error, isLoading } =
    useUpdateStorageAreaMutateQuery();

  const onSubmit = (data) => {
    mutate(
      {
        partnerId: props.partnerId,
        data: {
          total: data[StorageFormKeys.total],
          available: data[StorageFormKeys.available],
        },
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("storage space Updated", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  return (
    <Box
      sx={{
        padding: "18px 16px",
      }}
    >
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        marginBottom={4}
      >
        {warehouse_labels.storageTotalAndAvailableSpace}
      </Typography>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {isError && (
            <Grid item xs={12}>
              <Alert severity="error">{error.response.data.message}</Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={StorageFormKeys.total}
              label={warehouse_labels.storageSpaceLabelText}
              placeholder={`Enter total space`}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={StorageFormKeys.available}
              label={warehouse_labels.availableSpaceLabelText}
              placeholder={`Enter available total space`}
              type="number"
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="soft"
                sx={{
                  height: 64,
                  mt: "14px",
                }}
                onClick={hideDialog}
              >
                {warehouse_labels.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{
                  height: 64,
                  mt: "14px",
                }}
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              >
                {warehouse_labels.updateAreaButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StorageAreaForm;

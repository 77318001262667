import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider
} from "@mui/material";

import DropDown from "../../shared/formElements/DropDown";
import { ExpandMore } from "@mui/icons-material";
import React from "react";
import { StyledAccordion } from "./InquiryDetails";
import { StyledBox, packagingConditionOptions } from "./PackageItemCard";
import { packageConditionOptions } from "../pages/constant";

const GatePassItemCard = (props) => {
  return (
    <Box>
      <Box
        padding={3}
        borderRadius={2}
        border={"0.7px solid #B7BEC7"}
        justifyContent={"space-between"}
        sx={{
          display: {
            xs: "none",
            lg: "flex"
          }
        }}
        width={"100%"}
      >
        <StyledBox label={"Package name"} value={props.packageName} />
        <StyledBox label={"Packing Material"} value={props.packagingMaterial} />
        <StyledBox label={"Package Size"} value={props.packageSize} />
        <Box display={"flex"} gap={10}>
          <Box minWidth={182} width={"100%"}>
            <DropDown
              control={props.control}
              name={`reports.${props.index}.packageCondition`}
              label={"Package Condition"}
              options={
                props?.isFormFetching
                  ? []
                  : packagingConditionOptions(props?.formData)
              }
              isLoading={props.isFetchingForm}
            />
          </Box>
          <Box minWidth={182} width={"100%"}>
            <StyledBox label={"Rack number"} value={props.rackNumber} />
          </Box>
        </Box>
      </Box>
      <StyledAccordion
        disableGutters
        elevation={0}
        sx={{
          display: {
            xs: "block",
            lg: "none"
          },
          boxShadow: "0px 8px 24px 0px rgba(21, 21, 22, 0.10)",
          padding: "20px 0"
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
            <StyledBox label={"Package name"} value={props.packageName} />
            <Divider />
            <Box display={"flex"} gap={5}>
              <StyledBox
                label={"Packing Material"}
                value={props.packagingMaterial}
              />
              <StyledBox label={"Package Size"} value={props.packageSize} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Divider />
            <DropDown
              control={props.control}
              name={`reports.${props.index}.packageCondition`}
              options={packageConditionOptions}
              label={"Package Condition"}
            />

            <StyledBox label={"Rack number"} value={props.rackNumber} />
          </Box>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default GatePassItemCard;

import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import { East } from "@mui/icons-material";
import OrderStatus from "./OrderStatus";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { useDrawer } from "../../shared/customSideDrawer";
import { warehouse_labels } from "../../constants";
import { Link } from "react-router-dom";

const OrderMobileCard = ({
  code,
  orderId,
  createdAt,
  inboundDate,
  outboundDate,
  material,
  status,
  actions
}) => {
  const { showDrawer, hideDrawer } = useDrawer();
  return (
    <Grid container spacing={"17px"}>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >{`ID ${code}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
            textAlign={"end"}
          >
            {createdAt}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container marginTop={"3px"}>
        <Grid
          item
          xs={9}
          display={"flex"}
          gap={"12px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
            <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
              {inboundDate}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
            >
              {warehouse_labels.inbound}
            </Typography>
          </Box>
          <East fontSize="16" />
          <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
            <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
              {outboundDate}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
            >
              {warehouse_labels.outbound}
            </Typography>
          </Box>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              fontSize={13}
              fontWeight={500}
              lineHeight={"18px"}
              textAlign={"end"}
            >
              {material}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
              textAlign={"end"}
            >
              {warehouse_labels.material}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container marginTop={"6px"}>
        <Grid item xs={6}>
          <Typography fontSize={12} lineHeight={"16px"}>
            <StyledChip
              label={status}
              variant="filled"
              onClick={() => {
                showDrawer({
                  component: (
                    <OrderStatus
                      close={hideDrawer}
                      status={status}
                      orderId={orderId}
                    />
                  ),
                  closeIcon: false
                });
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
          {!actions ? (
            <Box display={"flex"} gap={4}>
              {status === "ReceivedAtFacility" ? (
                <Button
                  variant="link"
                  disableRipple
                  sx={{ fontWeight: 600 }}
                  component={Link}
                  to={`/orders/material-received-report/${orderId}`}
                >
                  Add Storage detail
                </Button>
              ) : (
                <Button
                  variant="link"
                  disableRipple
                  sx={{ fontWeight: 600 }}
                  component={Link}
                  to={`/orders/material-received-report/${orderId}`}
                >
                  Create MRR
                </Button>
              )}
            </Box>
          ) : (
            <Typography fontSize={12}>{actions}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderMobileCard;

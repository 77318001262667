export const QueryKeys = {
  queryGetProfile: "query-get-profile",
  queryGetWarehouseFormData: "query-get-warehouse-form-data",
  queryWarehouseDetail: "query-warehouse-detail",
  queryGetTransportFormData: "query-get-transport-form-data",
  queryGetVehicleFormData: "query-get-vehicle-form-data",
  queryTransportDetail: "query-transport-detail",
  queryActiveInquiry: "query-active-inquiry",
  queryPastInquiry: "query-past-inquiry",
  queryRetrieve: "query-retrieve",
  queryIncomingOrders: "query-incoming-orders",
  queryStoredOrders: "query-stored-orders",
  queryCompletedOrders: "query-completed-orders",
  queryActiveOrders: "query-active-orders",
  queryTransportOrderDetails: "query-transport-orders-details",
  queryWarehouseOrderDetails: "query-warehouse-orders-details",
  queryVehicles: "query-get-vehicles",
  queryWarehouseInquiry: "query-get-warehouse-inquiry",
  queryWarehouseInquiryQuoteForm: "query-get-warehouse-inquiry-quote-form",
  queryWarehouseRegretFormData: "query-warehouse-regret-form-data",
  queryTransportInquiry: "query-get-transport-inquiry",
  queryTransportInquiryQuoteForm: "query-get-transport-inquiry-quote-form",
  queryTransportRegretFormData: "query-transport-regret-form-data",
  queryFetchNotifications: "query-get-notifications",
  queryCreateMRRFormData: "query-get-create-mrr-form-data",
  queryFetchPackagingNotes: "query-get-packaging-notes",
  queryFetchWarehouseInvoices: "query-get-warehouse-invoices",
  queryFetchWarehouseInvoicesFormData: "query-get-warehouse-invoices-form-data",
  queryFetchTransportInvoices: "query-get-transport-invoices",
  queryFetchTransportInvoicesFormData: "query-get-transport-invoices-form-data",
  queryFetchRetrievePackagingNotes: "query-get-retrieve-packaging-notes",
  queryFetchGatePassFormData:"query-get-gate-pass-form-data"
};

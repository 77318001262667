import { QueryKeys } from "../../constants/query-keys";
import { getWarehouseRetrieve } from "../../warehouse/actions";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchRetrieveQuery = () => {
  const retrieve = useInfiniteQuery(
    [QueryKeys.queryRetrieve],
    ({ pageParam = 1 }) => getWarehouseRetrieve(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.retrieves.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );
  return retrieve;
};
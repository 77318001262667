export const labels = {
  appTitle: "Warex",
  warehouse: "Warehouse",
  transporter: "Transporter",
  transport: "Transport",
  proceed: "Proceed",
  continue: "Continue",
  notifications: "Notification",
  demo_string:
    "amet, consetetur sadipscing elitr, sed diam nonumy eirmod temporinvidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duodolores et ea rebum. Stet clita kasdgubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet",
  monthlyRentalText: "Monthly rental",
  addonFeesText: "Addon fees",
  totalAmountText: "Total amount",
  transportServiceNeededLabelText: "Do you need a transport service?",
  pickupLocationLabelText: "Pickup Location",
  pickupLocationPlaceholderText: "Select Pickup location from maps",
  destinationLabelText: "Destination",
  destinationPlaceholderText:
    "Select desired area of the warehouse within the city",
  inboundDateLabelText: "Inbound date",
  datePlaceholderText: "Enter date",
  outboundDateLabelText: "Outbound date",
  materialDetailsLabelText: "Material details",
  materialDetailsPlaceholderText: "Select material",
  sortByPlaceholderText: "Select sort",
  packagingTypeLabelText: "Packaging type",
  packagingPlaceholderText: "Select packaging",
  itemDescriptionLabelText: "Item description",
  itemDescriptionPlaceholderText: "Add item description",
  dimensionPlaceholderText: "Select dimension",
  dimensionLabelText: "Package Dimensions",
  noOfPackagesLabelText: "No. of Packages",
  totalWeightOfMaterialLabelText: "Total Weight of material",
  totalWeightOfMaterialPlaceholderText: "Enter weight in Tons (1Ton=1000KGs)",
  noOfPackagesPlaceholderText: "Enter no of packages",
  spaceTypePlaceholderText: "Select storage type",
  spaceTypeLabelText: "Storage type",
  spaceOrPalletsAccommodated: "Space or Pallets to be accommodated?",
  spaceOrPalletsAccommodatedPlaceholderText:
    "Enter Area in Sft or No. of Pallets",
  additionalServicesLabelText: "Additional Services",
  showListingsButtonText: "Show listings",
  findTrucksButtonText: "Find Trucks",
  sendRFQButtonPluralLabelText: "Send RFQs",
  sendRFQButtonSingleLabelText: "Send RFQ",
  bookOrderButtonLabelText: "Book Order",
  moveMapToAdjustLocation: "Move map to adjust location",
  confirm: "Confirm",
  enterMobileNumber: "+91 | Enter mobile number",
  verifyWithOTP: "Verify with OTP",
  sentTo: "Sent to",
  resentOtpIn: "Resend OTP",
  havingTroubleLoggingIn: " Having trouble logging in?",
  getHelp: "Get Help",
  byContinueIAgree: "By continuing, I agree to the",
  termsOfUse: "Terms of Use",
  privacyPolicy: "Privacy Policy",
  login: "Login",
  signup: "Signup",
  storageDetailsTitleText: "Storage details",
  yearEstablishedLabelText: "Year established",
  totalStorageSpaceLabelText: "Total storage space",
  availableSpaceLabelText: "Available space",
  storageTypesAndPricingTitleText: "Storage types & pricing",
  squareFeetLabelText: "Square feet",
  palletLabelText: "Pallet",
  boxesLabelText: "Boxes",
  containerLabelText: "Container",
  additionalDetailsTitleText: "Additional details",
  moreSearchTitleText: "More warehouse from your search",
  pricePerSqftLabelText: "Price / Sq ft",
  spaceRequiredLabelText: "Space required",
  handlingChargesLabelText: "Handling charges",
  transportedFeeLabelText: "Transporter fee",
  servicesAvailableLabelText: "Services available",
  approvedProductsLabelText: "Approved products",
  bookNowButtonText: "Book now",
  pickupDateLabelText: "Pickup Date",
  requestForQuotes: "Request For Quotes",
  viewDetail: "View detail",
  setYourPriceExpectation: "Set your price expectation",
  pricePerKmLabelText: "Price /Km",
  addToCartButtonLabelText: "Add to cart",
  rfqSentToWarehousesSuccess: "RFQ sent to warehouses successfully!",
  warehouseRespondMsgSuccess:
    "You can expect a response in 4-5 working hours from the warehouses.",
  continueWithListing: "Continue with listing",
  goToInquiry: "Go to Inquiry",
  Cart: "Cart",
  add: "Add",
  continueWithoutTransporter: "Continue without Transporter",
  sortBy: "Sort by",
  sendRFQ: "Send RFQ",
  back: "Back",
  next: "Next",
  quantityPallets: "Quantity (Pallets)",
  partnerDetails: "Partner details",
  dateRangePlaceholderText: "Select date range",
  apply: "Apply",
  materialTypeLabel: "Material Type",
  packagingLabelText: "Packaging",
  dimensionAndUnits: "Dimensions & Units",
  spaceType: "Space type",
  areaInSqft: "Area in Sqft",
  productDetails: "Product details",
  pickupTimeApproxLabelText: "Pickup time (Approx)",
  timePlaceholderText: "Select time",
  billingDetails: "Billing details",
  firstNameLabelText: "First Name",
  lastNameLabelText: "Last Name",
  firmsNameLabelText: "Firms Name",
  firmsEmailLabelText: "Firms Email",
  gstinLabelText: "GSTIN",
  contactLabelText: "Contact",
  billingAddressLabelText: "Billing Address",
  cityPlaceholderText: "City",
  countryPlaceholderText: "Country",
  zipCodePlaceholderText: "Zip Code",
  firstNamePlaceholderText: "First name",
  lastNamePlaceholderText: "Last name",
  firmsNamePlaceholderText: "Firms name",
  firmsEmailPlaceholderText: "Enter email ID",
  gstinPlaceholderText: "Enter GSTIN number",
  contactPlaceholderText: "+91 XXXX XX XXXX",
  billingAddressPlaceholderText: "Street Address",
  ratePerUnits: "Rate / per units",
  proceedToPay: "Proceed to pay",
  storageOrderForm: "Storage Order Form",
  nameLabelText: "First & Last name",
  numberLabelText: "Mobile number",
  emailLabelText: "Email ID",
  subjectLabelText: "Subject",
  descriptionLabelText: "Description",
  namePlaceholderText: "Enter your Name",
  numberPlaceholderText: "Enter your Number",
  emailPlaceholderText: "Enter your Email ID",
  subjectPlaceholderText: "Select your reason",
  descriptionPlaceholderText: "Enter the description",
  savedAddress: "Saved address",
  useThisAddress: "Use this address",
  orderNo: "Order no",
  orderDate: "Order date",
  sgst: "SGST",
  cgst: "CGST",
  inWord: "In Word",
  total: "Total",
  customerOrBuyer: "Customer / Buyer",
  storageFacility: "Storage Facility",
  serviceProvider: "Service provider",
  acceptAll: "Accept all ",
  tnc: "Terms & Conditions",
  descriptionText:
    "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
  notificationMsgCount: "You have $COUNT new notification",
  markAllAsRead: "Mark all as read",
  notificationMsgTemplate: "$MESSAGE, dated $DATE."
};

export const error_msg = {
  required: "Field is required",
  onlyDigits: "Must be only digits",
  enterLeast10Digits: "Must be at least 10 digits",
  enterOnly10Digits: "Cannot exceed 10 digits",
  enterMobileNumber: "Mobile number is required",
  enterOtp:
    "Please enter the OTP sent to your registered mobile number to verify your account.",
  errorAuthLogin: "Error while verifying user login, Please try again",
  errorLoggingIn:
    "Error while logging in with Email/Mobile and Password, Please try again",
  acceptTnC: "Please accept the terms and conditions."
};

export const DUMMY_ITEMS = [
  {
    label: "Yes",
    value: "yes"
  },
  {
    label: "No",
    value: "no"
  }
];

export const regimeRadioGroup = [
  {
    label: "5%",
    value: "5"
  },
  {
    label: "12%",
    value: "12"
  },
  {
    label: "18%",
    value: "18"
  }
];

export const appConstants = {
  dateFormat: "D MMM, YY",
  orderDateFormat: "D/M/YYYY",
  timeFormat: "h:mm A",
  sgst: 9,
  cgst: 9
};

export const home_labels = {
  titleText: "Redesign Your Supply Chain",
  titleSubtext:
    "Explore our Marketplace of Warehouses and Transporters for On-Demand Storage and Logistics Solutions",
  findWarehouseButtonText: "Find Warehouse",
  findTruckButtonText: "Find Truck",
  whatAreYouLookingForText: "What are you looking for?",
  howWeSolveText: "How do we Solve ?",
  howWeSolveSubText:
    "We increase your Distribution Efficiency. Reduce your Storage and Logistics Cost.",
  searchText: "Search",
  searchSubText: "Search for a Warehouse or Truck",
  findText: "Find",
  findSubText: "Find matching spaces and trucks.",
  rfqText: "RFQs",
  rfqSubText: "Request Quote from any 3 selected partners",
  bookText: "Book",
  bookSubText: "Compare Quotes and Book Order on the Partner you select.",
  manageText: "Manage",
  manageSubText: "Create Packing Notes, Manage Order Flow.",
  shipText: "Ship",
  shipSubText: "Ship Partial/ Full Consignments.",
  productFeatures: "Product Features",
  otherProductFeatures: "Other Product Features",
  Explore: "Explore",
  exploreSubtext: "Find warehouses and/ or transporters",
  RFQ: "RFQ",
  RFQSubtext: "Request Quote from any 3 selected partners",
  Quote: "Quote",
  QuoteSubtext: "Partners Competitively bid against the inquiry",
  compareQuotes: "Compare Quotes",
  compareQuotesSubtext:
    "Compare responses/ quotes and place order on the best quote.",
  orderManagement: "Order Management",
  orderManagementSubtext:
    "Manage your order by adding packing note, modify, tracking, view invoices, MRRs, Gatepass etc",
  ship: "Ship",
  shipSubTextonly: "Dispatch Partial or Full Packages",
  industriesServedText: "Industries served",
  industriesServedSubText:
    "Warex is a Marketplace for Warehouses and Transporters to be accessed by SMEs for their Short term Storage demand",
  "apparel&TextilesText": "Apparel & Textiles",
  "FMCG/F&B": "FMCG / F&B",
  Furniture: "Furniture",
  showAllButtonText: "Show All",
  aboutUsTitleText: "About us",
  aboutUsSubText:
    "Our <strong><em>Mission</em></strong> is to become <strong><em>'A One Stop Supply Chain Solution'</em></strong>  to all SMEs. Our SAAS based marketplace enables Distributors, Dealers, Wholesalers and Small manufacturers to grow/ expand to new territories, handle new products/ brands/ SKUs by reducing their distribution costs. ",
  learnMoreButtonText: "Learn more",
  contactUsTitleText: "Contact us",
  contactUsSubText1: "Office:",
  contactUsSubText2:
    " 2nd Floor, 91 Springboard, Laxmi Cyber City, White Fields, Kondapur, Hyderabad - 500081.",
  contactNumberText: "+91 - 905 958 8686",
  contactEmailText: "Email : info@warex.in",
  infoEmailText: "Write to : info@warex.in",
  footerSubtext:
    "Conhex Technologies Private Limited CIN : U63030TG2022PTC169538",
  partnerLabelText: "Partner Login",
  partnerSubText1:
    "Warex hosts a SAAS based marketplace with a network of Warehouses and Transporters connecting SMEs.  Partners can Login and unleash multiple features of our advanced Warehouse and Transport Management Systems.",
  partnerSubText2:
    "Partners can Login and unleash multiple features of our advanced Warehouse and Transport Management Systems.",
  partnerWithUsTitleText: "Want to Partner with us ?",
  loginViaText: "Login via ",
  mobileOrEmailText: "mobile or email",
  loginFormCaptionText: "By continuing, I agree to the ",
  termOfUseText: "Terms of Use",
  privacyPolicyText: "Privacy Policy",
  forgotPasswordText: "Forgot password?",
  provideEmailTitle: "Provide Registered emailId",
  confirmationCode: "Confirmation Code will be sent to this emaild",
  confirmCodeTitle: "Confirm Code sent to Registered emailId",
  validationOfCode: "On validation of code, new password can be set ",
  setNewPassword: "Set New Password"
};

export const warehouse_labels = {
  listYourText: "List your",
  warehouseText: "warehouse here",
  dashboardCaptionText:
    "Share with us details about your requirement. You an change them later through our filters.",
  addWarehouseButtonText: "+ Add warehouse",
  addTransportButtonText: "+ Add transport",
  warehouseDetailsTitleText: "Warehouse details",
  warehouseNameLabelText: "Warehouse name",
  warehouseNamePlaceholderText: "Enter warehouse name",
  establishedYearLabelText: "Established year",
  establishedYearPlaceholderText: "Enter date",
  mapLocationLabelText: "Warehouse address",
  mapLocationPlaceholderText: "Map your location",
  addressPlaceholderText: "Address",
  cityPlaceholderText: "City",
  statePlaceholderText: "State",
  zipCodePlaceholderText: "Zip Code",
  sameBillingAddressLabelText: "Use the same address for billing",
  billingAddressLabelText: "Billing address",
  billingAddressPlaceholderText: "Enter area name",
  gstinLabelText: "GSTIN",
  gstinPlaceholderText: "Enter GSTIN number",
  warehouseDescriptionLabelText: "Warehouse description",
  warehouseDescriptionPlaceholderText: "Add warehouse description",
  storageDetailsLabelText: "Storage details",
  storageSpaceLabelText: "Total storage space",
  storageSpacePlaceholderText: "Enter total space",
  availableSpaceLabelText: "Available space",
  availableSpacePlaceholderText: "Enter available space",
  storageTypeAllowedLabelText: "Storage type allowed",
  storageTypeAllowedPlaceholderText: "Select storage type",
  materialDetailsLabelText: "Material Details (Allowed Materials for Storage)",
  materialDetailsPlaceholderText: "Select products",
  availableFeaturesLabelText: "Features Available",
  additionalServicesTitleText: "Additional Services/ Material handling",
  temperatureControlLabelText: "Temperature controls",
  cctvAndSurveillanceLabelText: "CCTV & Surveillance",
  securityGuardsLabelText: "Security Guards",
  pestControlLabelText: "Pest Control",
  uploadPhotoLabelText: "Upload warehouse photos",
  uploadButtonText: "Browse photo",
  reviewButtonText: "Submit for review",
  previewButtonText: "Preview",
  listedOnText: "Listed on",
  viewDetailsButtonText: "View details",
  squareFeetLabelText: "sqft.",
  usedUpSpaceText: "Used up space :",
  submittedReviewStatusText: "Submitted for review",
  storageOptionsAndPriceText: "Storage options & price",
  storageTotalAndAvailableSpace: "Storage total & available space",
  perUnitText: "/ Unit",
  perKgText: "/ Kg",
  cancelButtonText: "Cancel",
  updatePriceButtonText: "Update Price",
  updateAreaButtonText: "Update Area",
  additionalPricesText: "Additional Prices",
  inquiryIdTitleText: "Inquiry ID",
  inquiryDateTitleText: "Inquiry date",
  regretButtonText: "Regret",
  resetChangesButtonText: "Reset changes",
  keepUpdatingButtonText: "Keep updating",
  goBackInquiryButtonText: "Go back to inquiry",
  sendQuoteButtonText: "Send quote",
  reviseQuotationTitleText: "Revise quotation",
  pendingReviewStatusText: "Pending",
  material: "Material",
  status: "Status",
  apparels: "Apparels",
  inbound: "Inbound",
  outbound: "Outbound",
  otherReasonPlaceholderText: "Mention the reason",
  regretInquiryTitleText: "Are you sure you want to regret this Inquiry?",
  regretInquirySubText:
    "This will delete the Inquiry permanently. You cannot undo this action later.",
  yesRegretButtonText: "Yes, regret",
  isRequired: "is required",
  addMoreItemsButtonText: "Add more items",
  updatedAmountText: "Updated Subtotal",
  gstLabel: "GST @ 18%",
  updatedTotalLabel: "Updated Total",
  materialReceivedReportText: "Material Received Report",
  addStorageDetailsText: "Add Storage Details",
  customerNameText: "Customer name",
  transactionIdText: "Transaction ID",
  addressText: "Address",
  customerDetailsLabelText: "Customer details",
  customerPaymentsLabelText: "Customer payment",
  viewOrderFormButtonText: "View Order form",
  paymentDetailsLabelText: "Payment details",
  createInvoiceButtonText: "+ Create Invoice",
  amountPaidText: "Amount paid",
  paymentDateText: "Payment date",
  paymentMethodText: "Payment method",
  accountDetailsText: "Account details",
  paymentAmountText: "Payment amount",
  commissionDetailsText: "Commission details",
  commissionAmountText: "Commission amount",
  commissionClaimedText: "Commissioned claimed",
  invoiceNo: "Invoice No.",
  invoiceDate: "Invoice Date",
  netPayout: "Net Payout",
  paymentId: "Payment ID",
  balanceText: "Balance",
  quoteSuccessMessage:
    "You can expect a response in 4-5 working hours from the customer.",
  quoteSuccessTitle: "Quote sent successfully!",
  quoteRegretMessage:
    "You can expect a response in 4-5 working hours from the customer.",
  quoteRegretTitle: "Quote is regretted!",
  closeButtonText: "Close"
};

export const transport_labels = {
  transportDetailsTitleText: "Transport details",
  transportNameLabelText: "Transport name",
  transportNamePlaceholderText: "Enter transport name",
  establishedYearLabelText: "Established year",
  establishedYearPlaceholderText: "Enter date",
  mapLocationLabelText: "Truck Depot Address",
  mapLocationPlaceholderText: "Map your location",
  addressPlaceholderText: "Address",
  cityPlaceholderText: "City",
  statePlaceholderText: "State",
  zipCodePlaceholderText: "Zip Code",
  sameBillingAddressLabelText: "Use the same address for billing",
  billingAddressLabelText: "Billing address",
  billingAddressPlaceholderText: "Enter area name",
  gstinLabelText: "GSTIN",
  gstRegimeLabelText: "GSTIN Regime",
  gstinPlaceholderText: "Enter GSTIN number",
  transportDescriptionLabelText: "Transport description",
  transportDescriptionPlaceholderText: "Add transport description",
  addTruckDetailLabelText: "Add truck details",
  addAnotherTruckButtonText: "+ Add another truck",
  additionalServicesTitleText: "Additional services",
  truckNumberLabelText: "Truck number",
  truckNumberPlaceholderText: "Enter truck number",
  truckMakeLabelText: "Truck Make",
  truckMakePlaceholderText: "Select Truck Make",
  truckModelLabelText: "Truck Model",
  truckModelPlaceholderText: "Select Truck Model",
  pricePerKmLabelText: "Price per KM",
  pricePerKmPlaceholderText: "Enter price",
  truckUploadButtonText: "Add truck photos",
  submitPreviewButtonText: "Submit for review",
  truckCardPriceText: "Price / km : ₹",
  loadingText: "Loading",
  packagingText: "Packaging",
  unloadingText: "Unloading",
  truckDetailsLabelText: "Truck details",
  transportText: "transport here",
  updatePriceButtonText: "Update Price"
};

export const statusKeys = {
  Completed: "Completed",
  ToBePickedUp: "To be picked",
  Cancelled: "Cancelled",
  VehicleAssigned: "VehicleAssigned",
  InTransit: "InTransit",
  ReceivedAtFacility: "ReceivedAtFacility",
  Stored: "Stored",
  PartiallyRetrieved: "Partially Retrieved",
  Partial: "Partial",
  InvoicePending: "InvoicePending",
  Paid: "Paid",
  Responded: "Responded",
  Regret: "Regret",
  Pending: "Pending",
  QuoteSent: "QuoteSent",
  YetToBePicked: "YetToBePicked",
  Received: "Received",
  SubmittedForReview: "SubmittedForReview",
  GatepassRequested: "GatePassRequested",
  GatepassPrepared: "GatePassPrepared",
  Lost: "Lost",
  OrderBooked: "OrderBooked",
  YetToPicked: "Yet to picked",
  PartiallyPaid: "Partially paid",
  Shipped: "Shipped",
  ToBePicked: "ToBePicked"
};

export const profile_labels = {
  personalDetails: "Personal details",
  businessAddress: "Business Address",
  changePassword: "Change Password",
  OldPassword: "Enter Old Password",
  NewPassword: "Enter New Password",
  isRequired: "is Required",
  ConfirmPassword: "Confirm New Password",
  address: "Address",
  otherSavedAddress: "Other saved Address",
  saveChanges: "Save changes",
  cancel: "Cancel",
  edit: "Edit",
  firstNameLabelText: "First Name",
  lastNameLabelText: "Last Name",
  firmsNameLabelText: "Firms Name",
  emailLabelText: "Email",
  gstinLabelText: "GSTIN",
  phoneLabelText: "Phone",
  brandsLabelText: "Brands",
  brandsPlaceholderText: "Add brands",
  productsLabelText: "Products",
  productsPlaceholderText: "Select products",
  cityPlaceholderText: "City",
  countryPlaceholderText: "Country",
  zipCodePlaceholderText: "Zip Code",
  firstNamePlaceholderText: "First name",
  lastNamePlaceholderText: "Last name",
  firmsNamePlaceholderText: "Firms name",
  emailPlaceholderText: "Enter email ID",
  gstinPlaceholderText: "Enter GSTIN number",
  phonePlaceholderText: "+91 XXXX XX XXXX",
  verified: "Verified",
  natureOfBusinessLabelText: "Nature of business",
  natureOfBusinessPlaceholderText: "Select nature of business",
  streetAddressLabelText: "Street",
  streetAddressPlaceholderText: "Street address",
  stateLabelText: "State",
  setAsDefault: "Set as default"
};

export const AssignDriver_labels = {
  trucks: "Select truck",
  driverName: "Driver name",
  driverNumber: "Driver number",
  pickupDate: "Pickup date",
  pickupTime: "Pickup time",
  required: "is required"
};

export const contactUsSubject = [
  { displayName: "Inquiry", name: "Inquiry" },
  {
    displayName: "Explore Warex’s Solutions",
    name: "Explore Warex’s Solutions"
  },
  { displayName: "Partner with Warex", name: "Partner with Warex" },
  { displayName: "Service and Support", name: "Service and Support" },
  { displayName: "Others", name: "Others" }
];

export const gatePass_labels = {
  transporter: "Transporter",
  truckRegNo: "Truck Reg No.",
  gatepassPreparedBy: "Gatepass prepared by"
};

export const MrrUpload_labels = {
  mrrId: "MRR ID",
  receivedDate: "Received Date",
  receivedTime: "Time Picker"
};
import { client } from "../../shared/api/client";

export const fetchProfile = async () => {
  return await client.get("/profile").then((res) => res.data.data);
};

export const fetchWarehouseFormData = async (partnerId) => {
  return await client
    .get(`/warehouses/${partnerId}/complete/form-data`)
    .then((res) => res.data.data);
};

export const addWarehouse = async (partnerId, formData) => {
  return await client.put(`/warehouses/${partnerId}`, formData);
};

export const createWarehouseInvoice = async (formData) => {
  return await client.post(`/warehouses/invoices`, formData);
};

export const uploadMrr = async (orderId, data) => {
  return await client.post(`/warehouses/orders/${orderId}/mrr`, data);
};

export const updateMrr = async (orderId, data) => {
  return await client.put(
    `/warehouses/orders/${orderId}/packaging-notes/mrr`,
    data
  );
};

export const updateStorageDetails = async (orderId, formData) => {
  return await client.put(
    `/warehouses/orders/${orderId}/packaging-notes/storage-details`,
    formData
  );
};

export const fetchWarehouseDetails = async (partnerId) => {
  return await client
    .get(`/warehouses/${partnerId}`)
    .then((res) => res.data.data);
};

export const updateStorageTypes = async (partnerId, data) => {
  return await client.put(`/warehouses/${partnerId}/storage-types`, data);
};

export const updateStorageArea = async (partnerId, data) => {
  return await client.put(`/warehouses/${partnerId}/storage-area`, data);
};

export const getActiveWarehouseInquiry = async (page = 1) => {
  return await client
    .get(`/warehouses/inquiries/active?page=${page}`)
    .then((res) => res.data.data);
};

export const getClosedWarehouseInquiry = async (page = 1) => {
  return await client
    .get(`/warehouses/inquiries/closed?page=${page}`)
    .then((res) => res.data.data);
};

export const getIncomingOrders = async (page = 1) => {
  return await client
    .get(`/warehouses/orders/incoming?page=${page}`)
    .then((res) => res.data.data);
};

export const getStoredOrders = async (page = 1) => {
  return await client
    .get(`/warehouses/orders/stored?page=${page}`)
    .then((res) => res.data.data);
};

export const getCompletedOrders = async (page = 1) => {
  return await client
    .get(`/warehouses/orders/completed?page=${page}`)
    .then((res) => res.data.data);
};

export const getWarehouseInquiryData = async (inquiryId) => {
  return await client
    .get(`/warehouses/inquiries/${inquiryId}`)
    .then((res) => res.data.data);
};

export const getWarehouseInquiryQuoteFormData = async (inquiryId) => {
  return await client
    .get(`/warehouses/inquiries/${inquiryId}/quote/form-data`)
    .then((res) => res.data.data);
};

export const sendQuote = async (inquiryId, data) => {
  return await client.put(`/warehouses/inquiries/${inquiryId}/quote`, data);
};

export const getWarehouseRegretFormData = async (inquiryId) => {
  return await client
    .get(`/warehouses/inquiries/${inquiryId}/regret/form-data`)
    .then((res) => res.data.data);
};

export const regretWarehouse = async (inquiryId, data) => {
  return await client.put(`/warehouses/inquiries/${inquiryId}/regret`, data);
};

export const getWarehouseRetrieve = async (page = 1) => {
  return await client
    .get(`/warehouses/retrieves?page=${page}`)
    .then((res) => res.data.data);
};

export const getNotifications = async (page = 1) => {
  return await client
    .get(`/notifications?page=${page}`)
    .then((res) => res.data.data);
};

export const getWarehouseInvoices = async (page = 1) => {
  return await client
    .get(`/warehouses/invoices?page=${page}`)
    .then((res) => res.data.data);
};

export const getWarehouseInvoicesFormData = async (orderId) => {
  return await client
    .get(`/warehouses/invoices/form-data?orderId=${orderId}`)
    .then((res) => res.data.data);
};

export const readOneNotification = async (data) => {
  return await client.put(`/notifications/${data.notificationId}/read`, null);
};

export const readAllNotification = async () => {
  return await client.put(`/notifications/read`, null);
};

export const fetchMmrFormData = async (orderId) => {
  return await client
    .get(`/warehouses/orders/${orderId}/packaging-notes/mrr/form-data`)
    .then((res) => res.data.data);
};

export const getPackagingNotes = async (orderId) => {
  return await client
    .get(`/warehouses/orders/${orderId}/packaging-notes`)
    .then((res) => res.data.data);
};

export const fetchWarehouseOrderDetails = async (orderId) => {
  return await client
    .get(`/warehouses/orders/${orderId}`)
    .then((res) => res.data.data);
};

export const createGatePass = async (retrieveId, data) => {
  return await client.post(
    `/warehouses/retrieves/${retrieveId}/gate-pass`,
    data
  );
};

export const getRetrievePackagingNotes = async (retrieveId) => {
  return await client
    .get(`/warehouses/retrieves/${retrieveId}/packaging-notes`)
    .then((res) => res.data.data);
};

export const getGatePassFormData = async (retrieveId) => {
  return await client
    .get(`/warehouses/retrieves/${retrieveId}/gate-pass/form-data`)
    .then((res) => res.data.data);
};

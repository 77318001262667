import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import CustomTable from "../../shared/customTable";
import RetrieveMobileCard from "../components/RetrieveMobileCard";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { stringCapitalization } from "../../shared/utils";
import { useFetchRetrieveQuery } from "../../query-hooks/warehouseRetreive/useFetchRetrieveQuery";

import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Retrieve = () => {
  const retrieve_columns = [
    {
      Header: "Order ID",
      accessor: "code",
      Cell: ({ value }) => {
        return (
          <Typography sx={{ fontSize: 14, fontWeight: 600, color: "#0F2744" }}>
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "Customer details",
      accessor: "customerDetails"
    },
    {
      Header: "No of packages",
      accessor: "noOfPackages"
    },
    {
      Header: "Outbound date",
      accessor: "outboundDate"
    },
    {
      Header: "Retrieve date",
      accessor: "retrieveDate"
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, cell }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Button disableRipple variant="link" onClick={value[1]}>
            {value[0]}
          </Button>
        );
      }
    }
  ];
  const navigate = useNavigate();
  const { ref, inView } = useInView({ threshold: 0 });
  const {
    data: retrieveData,
    isFetching: isRetrieveFetching,
    hasNextPage: hasNextRetrievePage,
    fetchNextPage: fetchNextRetrievePage
  } = useFetchRetrieveQuery();

  const getRetrieves = (rows = []) => {
    const actionButton = ["GatePassRequested", "GatePassPrepared"];
    return rows.map((item, index) => {
      return {
        retrieveId: item._id,
        code: item.order.warehouseOrder.code,
        customerDetails: `{{No Data}}`,
        noOfPackages: (
          <Typography
            sx={{ fontSize: "14px", lineHeight: "20px", color: "text.primary" }}
          >
            {`${item.packagingNote.retrieveCount}/`}
            <b>{`${item.packagingNote.totalCount}`}</b>
          </Typography>
        ),
        outboundDate: moment(item.order.recentOrderItem.outboundDate).format(
          "DD MMMM YYYY"
        ),
        retrieveDate: moment(item.shippingDate).format("DD MMMM YYYY"),
        status: item.status.name,
        ...{
          ...(item.status.name === actionButton[0]
            ? {
                actions: [
                  `Create Gatepass`,
                  () => navigate(`/orders/${item._id}/create-gate-pass`)
                ]
              }
            : {
                actions: [`Download Gatepass`, () => console.log("Download")]
              })
        }
      };
    });
  };

  useEffect(() => {
    if (inView && hasNextRetrievePage) {
      fetchNextRetrievePage();
    }
  }, [fetchNextRetrievePage, hasNextRetrievePage, inView]);

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <CustomTable
        searchPlaceholderText={"Search by order ID partner name, etc"}
        columns={retrieve_columns}
        data={
          isRetrieveFetching
            ? []
            : retrieveData?.pages.flatMap((page) =>
                getRetrieves(page?.retrieves)
              )
        }
        mobileComponent={RetrieveMobileCard}
        isLoading={isRetrieveFetching}
      />
      {hasNextRetrievePage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Retrieve;

import { QueryKeys } from "../../constants/query-keys";
import { getWarehouseInquiryQuoteFormData } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseInquiryQuoteFormData = (inquiryId) => {
  const warehouseInquiryQuoteFormQuery = useQuery(
    [QueryKeys.queryWarehouseInquiryQuoteForm, inquiryId],
    () => getWarehouseInquiryQuoteFormData(inquiryId),
    {
      enabled: Boolean(inquiryId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return warehouseInquiryQuoteFormQuery;
};

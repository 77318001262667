import { Chip } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { statusKeys } from "../../constants";
import { stringCapitalization } from "../utils";

const SuccessCode = {
  color: "success.dark",
  backgroundColor: "success.background"
};

const WarningCode = {
  color: "warning.dark",
  backgroundColor: "warning.background"
};

const ErrorCode = {
  color: "error.dark",
  backgroundColor: "error.background"
};

// eslint-disable-next-line no-unused-vars
const TransitCode = {
  color: "transit.dark",
  backgroundColor: "transit.background"
};

const ReceivedCode = {
  color: "received.dark",
  backgroundColor: "received.background"
};

export const statusCode = {
  [statusKeys.Completed]: SuccessCode,
  [statusKeys.ToBePickedUp]: ReceivedCode,
  [statusKeys.Cancelled]: ErrorCode,
  [statusKeys.VehicleAssigned]: ErrorCode,
  [statusKeys.InTransit]: ErrorCode,
  [statusKeys.ReceivedAtFacility]: ReceivedCode,
  [statusKeys.Stored]: SuccessCode,
  [statusKeys.PartiallyRetrieved]: SuccessCode,
  [statusKeys.Paid]: SuccessCode,
  [statusKeys.Partial]: ReceivedCode,
  [statusKeys.InvoicePending]: WarningCode,
  [statusKeys.Responded]: SuccessCode,
  [statusKeys.Regret]: ErrorCode,
  [statusKeys.Pending]: WarningCode,
  [statusKeys.QuoteSent]: SuccessCode,
  [statusKeys.YetToBePicked]: WarningCode,
  [statusKeys.Received]: SuccessCode,
  [statusKeys.SubmittedForReview]: WarningCode,
  [statusKeys.GatepassRequested]: WarningCode,
  [statusKeys.GatepassPrepared]: SuccessCode,
  [statusKeys.Lost]: WarningCode,
  [statusKeys.OrderBooked]: SuccessCode,
  [statusKeys.YetToPicked]: WarningCode,
  [statusKeys.PartiallyPaid]: WarningCode,
  [statusKeys.Shipped]: SuccessCode,
  [statusKeys.ToBePicked] : ReceivedCode
};

export const StyledChip = ({ label, variant = "outlined", ...props }) => {
  return (
    <Chip
      {...props}
      sx={{
        color: statusCode[label]?.color,
        backgroundColor:
          variant === "outlined"
            ? statusCode[label]?.backgroundColor
            : "transparent",
        border: variant === "outlined" ? "0.4px solid" : 0,
        borderColor: statusCode[label]?.color,
        fontSize: 13,
        fontWeight: 500,
        lineHeight: "18px",
        paddingLeft: variant === "outlined" ? "7px" : 0,
        height: variant === "outlined" ? 32 : "auto",
        ".MuiChip-icon": {
          color: statusCode[label]?.color
        },
        ".MuiChip-label": {
          textOverflow: "unset"
        },
        "& > svg": {
          marginLeft: variant === "outlined" ? "5px" : "0px !important"
        }
      }}
      icon={
        <Circle
          sx={{
            fontSize: 12
          }}
        />
      }
      label={stringCapitalization(
        label?.replace(/([a-z0-9])([A-Z])/g, "$1 $2").toLowerCase()
      )}
    />
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  Box,
  Button,
  // CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import InvoiceDetailTab from "../components/InvoiceDetailTab";
import OrderDetailTab from "../../warehouse/components/OrderDetailTab";
import { labels, statusKeys } from "../../constants";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "@emotion/styled";
import { isMobileDevice } from "../../App";
import { East, ExpandMore } from "@mui/icons-material";
import { useFetchWarehouseOrderDetailsQuery } from "../../query-hooks/warehouseOrders/useFetchWarehouseOrderDetailsQuery";
import moment from "moment";
import {
  DetailsGrid,
  TextBox,
} from "../../warehouse/components/InquiryDetails";
import { useFetchWarehouseInvoiceFormData } from "../../query-hooks/warehouseInvoice/useFetchWarehouseInvoiceFormData";
import InvoiceDetailManualTab from "../../warehouse/components/InvoiceDetailManualTab";

const StyleBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  background: isMobileDevice ? "#F3F5F9" : theme.palette.success.background,
  borderRadius: 4,
  marginTop: isMobileDevice ? 0 : "20px",
  padding: "12px",
}));

const StyledAccordion = styled(Accordion)(() => ({
  border: "0.4px solid #95A1B0",
  borderRadius: "8px !important",
  padding: "8px 0px",
  marginBottom: "0 !important",
}));

const OrderSummary = () => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.isCreateInvoice ? 1 : 0);
  const [showMore, setShowMore] = useState(false);
  const [isManualInvoice, setIsManualInvoice] = useState(true);
  const [isCreateInvoice, setIsCreateInvoice] = useState(
    state?.isCreateInvoice || false
  );
  const [manualInvoiceData, setManualInvoiceData] = useState(null);

  const { orderId } = useParams();

  const { data } = useFetchWarehouseOrderDetailsQuery(orderId);

  const { data: invoiceFormData, isFetched: isFormFetched } =
    useFetchWarehouseInvoiceFormData(orderId);

  const handleTabChange = (index) => {
    setActiveTab(index);
    setIsCreateInvoice(index);
  };

  const warehouseOrderDetail = {
    detailsList: [
      {
        itemNo: 1,
        itemDesc: `Storage services Material : ${data?.order?.searchForm?.materialCategory?.displayName} HSN Code : 99679`,
        uom: `${data?.order?.recentOrderItem?.warehouseCharges?.services?.[0].rateUnit}`,
        quantity:
          data?.order?.recentOrderItem?.warehouseCharges?.services?.[0].quantity,
        duration: parseFloat(
          data?.order?.recentOrderItem?.warehouseCharges?.services?.[0].duration / 30
        ).toFixed(2),
        rate: data?.order?.recentOrderItem?.warehouseCharges?.services?.[0].rate,
        totalAmount:
          data?.order?.recentOrderItem?.warehouseCharges?.services?.[0]?.totalAmount,
      },
      {
        itemNo: 2,
        itemDesc: `Additional Costs Material Handling`,
        uom: "",
        duration: "",
        rate: "",
        totalAmount:
          data?.order?.recentOrderItem?.warehouseCharges?.additionalServices?.[0]?.totalAmount || 0,
      },
      {
        itemNo: "",
        itemDesc: `Sub Total`,
        uom: "",
        duration: "",
        rate: "",
        totalAmount: data?.order?.recentOrderItem?.warehouseCharges?.subTotalAmount,
      },
    ],
    gstRegime: data?.order?.recentOrderItem?.warehouseCharges?.taxes?.[0].rate,
    gstAmount: data?.order?.recentOrderItem?.warehouseCharges?.taxes?.[0]?.totalAmount,
    totalAmount: data?.order?.recentOrderItem?.warehouseCharges?.totalAmount,
  };

  return (
    <Box
      sx={{
        padding: {
          xs: 2,
          md: "40px  0",
        },
      }}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
    >
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <Box
          display={"flex"}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 5 },
          }}
        >
          <Box display={"flex"} gap={1}>
            <Typography fontSize={14} color={"text.secondary"}>
              Order ID :
            </Typography>
            <Typography fontSize={14} fontWeight={500}>
              {data?.order?.warehouseOrder?.code}
            </Typography>
          </Box>
          <Box display={"flex"} gap={1}>
            <Typography fontSize={14} color={"text.secondary"}>
              Order date :
            </Typography>
            <Typography fontSize={14} fontWeight={500}>
              {moment(data?.order?.createdAt).format("DD MMM,YYYY")}
            </Typography>
          </Box>
        </Box>

        {!isMobileDevice && !isCreateInvoice && (
          <Box display={"flex"} gap={"10px"}>
            {data?.order?.warehouseOrder?.warehouseStatus?.name ===
              statusKeys.ToBePicked && (
              <Button variant="soft" sx={{ padding: "14px 16px" }}>
                Create Gatepass
              </Button>
            )}
            <Button
              variant="soft"
              sx={{ padding: "14px 16px" }}
              onClick={() => {
                setIsCreateInvoice(true);
                setActiveTab(1);
              }}
            >
              Create Invoice
            </Button>
            {data?.order?.documents?.length > 0 && (
              <Button variant="soft" sx={{ padding: "14px 16px" }}>
                View documents
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          borderRadius: "8px",
          border: "0.4px solid #95A1B0",
        }}
      >
        {/* mobile view */}
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <StyledAccordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMore color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                "& > div": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                },
              }}
            >
              <Box display={"flex"} flexDirection={"row"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography fontSize={14} fontWeight={500}>
                    {moment(data?.order?.searchForm?.inboundDate).format(
                      "DD MMM,YYYY"
                    )}
                  </Typography>
                  <Typography fontSize={14} color={"text.secondary"}>
                    {labels.inboundDateLabelText}
                  </Typography>
                </Box>
                <East sx={{ m: "0 16px" }} />
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography fontSize={14} fontWeight={500}>
                    {moment(data?.order?.searchForm?.outboundDate).format(
                      "DD MMM,YYYY"
                    )}
                  </Typography>
                  <Typography fontSize={14} color={"text.secondary"}>
                    {labels.outboundDateLabelText}
                  </Typography>
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.inboundDate).format("DD MMM,YYYY")}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupDateLabelText}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {showMore ? (
                    <div onClick={() => setShowMore((prev) => !prev)}>
                      {data?.order?.searchForm?.destination?.address}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowMore((prev) => !prev)}
                      style={{ display: showMore ? "none" : "flex" }}
                    >
                      {data?.order?.searchForm?.destination?.address.slice(0, 15)}
                      {"..."}
                    </div>
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupLocationLabelText}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <DetailsGrid data={data?.order?.searchForm} />
              <Box marginTop={3} marginBottom={3}>
                <TextBox
                  direction={true}
                  value={"Item Description"}
                  label={data?.order?.searchForm?.itemDescription}
                  gap={"8px"}
                />
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </Box>

        {/* desktop view */}
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            flexDirection: "column",
            gap: "12px",
            padding: 3,
          }}
        >
          <Box display={"flex"} gap={"60px"}>
            <StyleBox display={"flex"} gap={6}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.inboundDate).format("DD MMM,YYYY")}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.inboundDateLabelText}
                </Typography>
              </Box>
              <East sx={{ m: "0 16px" }} />
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.outboundDate).format("DD MMM,YYYY")}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.outboundDateLabelText}
                </Typography>
              </Box>
            </StyleBox>
            <Divider orientation="vertical" sx={{ height: "auto" }} />
            <StyleBox display={"flex"} gap={6}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.inboundDate).format("DD MMM,YYYY")}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupDateLabelText}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {showMore ? (
                    <div onClick={() => setShowMore((prev) => !prev)}>
                      {data?.order?.searchForm?.destination?.address}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowMore((prev) => !prev)}
                      style={{ display: showMore ? "none" : "flex" }}
                    >
                      {data?.order?.searchForm?.destination?.address.slice(0, 15)}
                      {"..."}
                    </div>
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupLocationLabelText}
                </Typography>
              </Box>
            </StyleBox>
          </Box>
          <DetailsGrid data={data?.order?.searchForm} />
          <Box display={"flex"} flexDirection={"column"}>
            <Typography fontSize={13} fontWeight={500}>
              {labels.itemDescriptionLabelText}
            </Typography>
            <Typography fontSize={13} color={"text.secondary"} maxWidth={412}>
              {data?.order?.searchForm?.itemDescription}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box marginTop={2}>
        <CustomTabs
          tabs={[
            {
              label: "Order detail",
              Component: (
                <OrderDetailTab
                  customerBillingDetails={data?.order?.customerBillingDetails}
                  inboundDate={data?.order?.recentOrderItem?.inboundDate}
                  outboundDate={data?.order?.recentOrderItem?.outboundDate}
                  orderCode={data?.order?.warehouseOrder?.code}
                  orderDate={data?.order?.createdAt}
                  orderDetail={warehouseOrderDetail}
                  partnerBillingDetails={invoiceFormData?.warehouse}
                />
              ),
            },
            {
              label: "Invoice detail",
              Component: isManualInvoice ? (
                <InvoiceDetailManualTab
                  setIsManualInvoice={setIsManualInvoice}
                  setManualInvoiceData={setManualInvoiceData}
                />
              ) : (
                <InvoiceDetailTab
                  customerBillingDetails={data?.order?.customerBillingDetails}
                  invoiceFormData={invoiceFormData}
                  isFormFetched={isFormFetched}
                  setIsManualInvoice={setIsManualInvoice}
                  manualInvoiceData={manualInvoiceData}
                  orderId={orderId}
                />
              ),
            },
          ]}
          activeTab={activeTab}
          onChange={handleTabChange}
        />
      </Box>
    </Box>
  );
};

export default OrderSummary;

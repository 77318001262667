import CreateGatePass from "../../warehouse/pages/CreateGatePass";
import Dashboard from "../../warehouse/pages";
import TransportInvoices from "../../transport/pages/Invoices";
import WarehouseInvoices from "../../warehouse/pages/Invoices";
import MaterialReceivedReport from "../../warehouse/pages/MaterialReceivedReport";
import MaterialReceivedReportFile from "../../warehouse/pages/MaterialReceivedReportFile";
import MyTransportOrders from "../../transport/pages/MyOrders";
import MyWarehouseOrders from "../../warehouse/pages/MyOrders";
import OrderSummary from "../../warehouse/pages/OrderSummary";
import Orders from "../../warehouse/pages/Orders";
import Partner from "../../home/pages/Partner";
import Retrieve from "../../warehouse/pages/Retrieve";
import { Route } from "react-router-dom";
import TransportActiveInquiry from "../../transport/pages/ActiveInquiry";
import TransportDetail from "../../transport/pages/TransportDetail";
import TransportInquiry from "../../transport/pages/Inquiry";
import TransportOrderSummary from "../../transport/pages/OrderSummary";
import TransportOrders from "../../transport/pages/Orders";
import WarehouseActiveInquiry from "../../warehouse/pages/ActiveInquiry";
import WarehouseDetail from "../../warehouse/pages/WarehouseDetail";
import WarehouseInquiry from "../../warehouse/pages/Inquiry";
import Faqs from "../../home/pages/Faqs";
import WarehouseProfile from "../../warehouse/pages/Profile";
import TransportProfile from "../../transport/pages/Profile";

export const publicRoutes = (
  <>
    <Route path="/" element={<Partner />} />
    <Route path="/faqs" element={<Faqs />} />
  </>
);

const warehouse_routes = (
  <>
    <Route path="/dashboard" element={<Dashboard />} />
    <Route
      path="/inquiry/active-inquiry/:inquiryId"
      element={<WarehouseActiveInquiry />}
    />
    <Route path="/inquiry" element={<WarehouseInquiry />} />
    <Route path="/dashboard/warehouse/:pid" element={<WarehouseDetail />} />
    <Route
      path="/orders/my-orders/:orderId/summary"
      element={<OrderSummary />}
    />
    <Route path="/orders/invoices-docs" element={<WarehouseInvoices />} />
    <Route path="/orders/retrieve" element={<Retrieve />} />
    <Route path="/orders/my-orders" element={<MyWarehouseOrders />} />
    <Route
      path="/orders/material-received-report/:orderId"
      element={<MaterialReceivedReport />}
    />
    <Route path="/orders/:retrieveId/create-gate-pass" element={<CreateGatePass />} />
    <Route
      path="/orders/material-received-report-file"
      element={<MaterialReceivedReportFile />}
    />
    <Route path="/orders" element={<Orders />} />
    <Route path={"/profile"} element={<WarehouseProfile />} />
    <Route
      path={"/profile/change-password"}
      element={<>Warehouse Change Password</>}
    />
    <Route
      path={"/profile/help-and-support"}
      element={<>Warehouse Help & Support</>}
    />
    <Route path={"/profile/faqs"} element={<>Warehouse FAQ'S</>} />
    <Route
      path={"/profile/terms-and-conditions"}
      element={<>Warehouse Terms & Conditions</>}
    />
  </>
);

const transporter_routes = (
  <>
    <Route path="/dashboard" element={<Dashboard />} />
    <Route
      path="/inquiry/active-inquiry/:inquiryId"
      element={<TransportActiveInquiry />}
    />
    <Route path="/inquiry" element={<TransportInquiry />} />
    <Route path="/dashboard/transport/:pid" element={<TransportDetail />} />
    <Route
      path="/orders/my-orders/:orderId/summary"
      element={<TransportOrderSummary />}
    />
    <Route path="/orders/invoices-docs" element={<TransportInvoices />} />
    <Route path="/orders/my-orders" element={<MyTransportOrders />} />
    <Route path="/orders" element={<TransportOrders />} />
    <Route path={"/profile"} element={<TransportProfile />} />
    <Route path={"/profile/change-password"} element={<>Change Password</>} />
    <Route path={"/profile/help-and-support"} element={<>Help & Support</>} />
    <Route path={"/profile/faqs"} element={<>FAQ'S</>} />
    <Route
      path={"/profile/terms-and-conditions"}
      element={<>Terms & Conditions</>}
    />
  </>
);

export const publicRoutesWarehouse = (
  <>
    {publicRoutes}
    {warehouse_routes}
  </>
);

export const publicRoutesTransporter = (
  <>
    {publicRoutes}
    {transporter_routes}
  </>
);

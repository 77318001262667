import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import CustomTable from "../../shared/customTable";
import InvoiceMobileCard from "../../warehouse/components/InvoiceMobileCard";
import InvoiceStatus from "../../warehouse/components/InvoiceStatus";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { currencyFormatter, stringCapitalization } from "../../shared/utils";
// import testData from "../../warehouse/actions/testData.json";
import { useFetchWarehouseInvoices } from "../../query-hooks/warehouseInvoice/useFetchWarehouseInvoices";
import { useDrawer } from "../../shared/customSideDrawer";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import moment from "moment";

const WarehouseInvoices  = () => {
  const { ref, inView } = useInView({ threshold: 0 });
  const { showDrawer, hideDrawer } = useDrawer();

  const { data, isFetched, hasNextPage, fetchNextPage } =
  useFetchWarehouseInvoices();

  const invoice_columns = [
    {
      Header: "Order ID",
      accessor: "orderId",
      Cell: ({ value }) => {
        return (
          <Typography sx={{ fontSize: 14, fontWeight: 600, color: "#0F2744" }}>
            {value}
          </Typography>
        );
      },
    },
    {
      Header: "Invoice No",
      accessor: "invoiceNo",
    },
    {
      Header: "Invoice date",
      accessor: "invoiceDate",
    },
    {
      Header: "Total amount",
      accessor: "totalAmount",
    },
    {
      Header: "Amount paid",
      accessor: "amountPaid",
    },
    {
      Header: "Balance",
      accessor: "balance",
    },
    {
      Header: "Payment status",
      accessor: "paymentStatus",
      Cell: ({ value, cell }) => {
        return (
          <StyledChip
            label={stringCapitalization(value)}
            onClick={() => {
              showDrawer({
                component: <InvoiceStatus close={hideDrawer} status={value} />,
                closeIcon: false,
              });
            }}
          />
        );
      },
    },
  ];

  const getInvoices = (rows = []) => {
    return rows.map((item, index) => {
      return {
        orderId: item.orderCode,
        invoiceNo: item.invoiceCode,
        invoiceDate: moment(item.invoiceDate).format("DD MMMM YYYY"),
        totalAmount: currencyFormatter(
          parseFloat(item.charges.totalAmount).toFixed(2)
        ),
        amountPaid: currencyFormatter(
          parseFloat(
            Math.abs(item.charges.totalAmount - item.pendingAmount)
          ).toFixed(2)
        ),
        balance: currencyFormatter(parseFloat(item.pendingAmount).toFixed(2)),
        paymentStatus: item.status.name,
      };
    });
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0,
        },
      }}
    >
      <CustomTable
        searchPlaceholderText={"Search by order ID partner name, etc"}
        columns={invoice_columns}
        data={
          isFetched
            ? data?.pages.flatMap((page) => getInvoices(page?.invoices))
            : []
        }
        mobileComponent={InvoiceMobileCard}
        isLoading={!isFetched}
      />
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default WarehouseInvoices;

import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { DUMMY_ITEMS, warehouse_labels } from "../../constants";
import { Fragment, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import AutoCompleteMapField from "../../shared/formElements/AutoCompleteMapField";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import DateInput from "../../shared/formElements/DateInput";
import DropDown from "../../shared/formElements/DropDown";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import { LoadingButton } from "@mui/lab";
import RadioButtonGroup from "../../shared/formElements/RadioButtonGroup";
import { StyledButton } from "../../shared/formElements/StyledButton";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import TextArea from "../../shared/formElements/TextArea";
import TextInput from "../../shared/formElements/TextInput";
import WarehouseSearchFormBg from "../../assets/search-form.png";
import { isMobileDevice } from "../../App";
import { searchFormSchema } from "./SearchFormValidation";
import { useAddWarehouseMutateQuery } from "../../query-hooks/warehouseDashboard/useAddWarehouseMutateQuery";
import { useFetchProfileQueryHook } from "../../query-hooks/useFetchProfileQueryHook";
import { useFetchWarehouseFormDataQueryHook } from "../../query-hooks/warehouseDashboard/useFetchWarehouseFormDataQueryHook";
import { warehouseSearchFormKeys } from "../../constants/formKeys";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

export const StyledBoxContainer = styled((props) => (
  <Box
    {...props}
    sx={{
      padding: {
        xs: "25px 12px",
        md: "25px 16px",
      },
      backgroundColor: "#F3F5F9",
      borderRadius: 2,
    }}
  />
))``;

export const StyledFormTitle = styled((props) => (
  <StyledResponsiveText
    {...props}
    desktop={{
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: "0.002em",
      fontWeight: 500,
    }}
    mobile={{
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: 600,
    }}
  />
))``;

export const SearchForm = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted },
    reset,
  } = useForm({
    defaultValues: {
      [warehouseSearchFormKeys.storageTypeAllowed]: [],
      [warehouseSearchFormKeys.additionalServices]: [],
      [warehouseSearchFormKeys.features]: [],
      [warehouseSearchFormKeys.storageTypes]: [],
      [warehouseSearchFormKeys.additionalServicesFields]: [],
    },
    resolver: yupResolver(searchFormSchema),
  });

  const { fields: featureFields, append: appendFeature } = useFieldArray({
    control,
    name: warehouseSearchFormKeys.features,
  });

  const {
    fields: storageTypesFields,
    append: appendStorageTypes,
    remove: removeStorageType,
  } = useFieldArray({
    control,
    name: warehouseSearchFormKeys.storageTypes,
  });

  const {
    fields: additionalServicesFields,
    append: appendAdditionalService,
    remove: removeAdditionalService,
  } = useFieldArray({
    control,
    name: warehouseSearchFormKeys.additionalServicesFields,
  });

  const storageTypesAllowedWatch = watch(
    warehouseSearchFormKeys.storageTypeAllowed
  );

  const featuresWatch = watch(warehouseSearchFormKeys.features);

  const additionalServicesWatch = watch(
    warehouseSearchFormKeys.additionalServices
  );

  const isUseSameBillingAddress = watch(
    warehouseSearchFormKeys.useSameBillingAddress
  )?.[0];

  const warehouseLocation = watch(warehouseSearchFormKeys.warehouseLocation);

  const { data: profileData } = useFetchProfileQueryHook();

  const { data: formData, isSuccess } = useFetchWarehouseFormDataQueryHook(
    profileData?.user?.partner
  );

  const { mutate, isError, error, isLoading } = useAddWarehouseMutateQuery();

  useEffect(() => {
    if (isUseSameBillingAddress) {
      setValue(warehouseSearchFormKeys.billingAddress, warehouseLocation);
    } else {
      setValue(warehouseSearchFormKeys.billingAddress, "");
    }
  }, [isUseSameBillingAddress, setValue, warehouseLocation]);

  useEffect(() => {
    if (isSuccess) {
      reset({ [warehouseSearchFormKeys.features]: [] });
      formData?.features.forEach((item) =>
        appendFeature({ [item.name]: "", ...item })
      );
    }
  }, [appendFeature, formData?.features, isSuccess, reset]);

  useEffect(() => {
    storageTypesAllowedWatch?.forEach((item) => {
      let storageFormData = formData?.storageTypes.find(
        (type) => type.name === item
      );
      let existingField = storageTypesFields.find(
        (field) => field.name === item
      );
      if (!existingField) {
        appendStorageTypes({ [item]: "", ...storageFormData });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageTypesAllowedWatch]);

  useEffect(() => {
    additionalServicesWatch?.forEach((item) => {
      let storageFormData = formData?.additionalServices.find(
        (service) => service.name === item
      );
      let existingField = additionalServicesFields.find(
        (field) => field.name === item
      );
      if (!existingField) {
        appendAdditionalService({
          [item]: "",
          name: storageFormData.name,
          displayName: storageFormData.displayName,
          rateUnit: storageFormData.rateUnit,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServicesWatch]);

  const onSubmit = (data) => {
    const warehouseFormData = new FormData();
    warehouseFormData.append(
      "name",
      data[warehouseSearchFormKeys.warehouseName]
    );
    warehouseFormData.append(
      "establishedYear",
      new Date(data[warehouseSearchFormKeys.establishedYear]).getFullYear()
    );
    warehouseFormData.append(
      "gstNo",
      data[warehouseSearchFormKeys.gstInNumber]
    );
    warehouseFormData.append("gstRegime", [18]);
    warehouseFormData.append(
      "description",
      data[warehouseSearchFormKeys.warehouseDescription]
    );
    warehouseFormData.append(
      "address[area]",
      data[warehouseSearchFormKeys.warehouseLocation]
    );
    warehouseFormData.append(
      "address[city]",
      data[warehouseSearchFormKeys.warehouseCity]
    );
    warehouseFormData.append(
      "address[state]",
      data[warehouseSearchFormKeys.warehouseState]
    );
    warehouseFormData.append(
      "address[pincode]",
      +data[warehouseSearchFormKeys.warehouseZipCode]
    );
    warehouseFormData.append(
      "address[location][coordinates][]",
      data[warehouseSearchFormKeys.lat]
    );
    warehouseFormData.append(
      "address[location][coordinates][]",
      data[warehouseSearchFormKeys.lng]
    );
    warehouseFormData.append(
      "billingAddress[sameAsAddress]",
      data[warehouseSearchFormKeys.useSameBillingAddress][0] === "yes"
    );

    if (data[warehouseSearchFormKeys.useSameBillingAddress][0] !== "yes") {
      warehouseFormData.append(
        "billingAddress[area]",
        data[warehouseSearchFormKeys.billingAddress]
      );
      warehouseFormData.append(
        "billingAddress[city]",
        data[warehouseSearchFormKeys.warehouseCity]
      );
      warehouseFormData.append(
        "billingAddress[state]",
        data[warehouseSearchFormKeys.warehouseState]
      );
      warehouseFormData.append(
        "billingAddress[pincode]",
        +data[warehouseSearchFormKeys.warehouseZipCode]
      );
    }

    warehouseFormData.append(
      "storageArea[total]",
      data[warehouseSearchFormKeys.totalStorageSpace]
    );
    warehouseFormData.append(
      "storageArea[available]",
      data[warehouseSearchFormKeys.availableSpace]
    );
    data[warehouseSearchFormKeys.storageTypes]?.forEach((item, index) => {
      warehouseFormData.append(`storageTypes[${index}][name]`, item.name);
      warehouseFormData.append(`storageTypes[${index}][rate]`, item[item.name]);
    });

    data[warehouseSearchFormKeys.additionalServicesFields]?.forEach(
      (item, index) => {
        warehouseFormData.append(
          `additionalServices[${index}][name]`,
          item.name
        );
        warehouseFormData.append(
          `additionalServices[${index}][rate]`,
          item[item.name]
        );
      }
    );

    data[warehouseSearchFormKeys.materialDetails]?.forEach((item, index) => {
      warehouseFormData.append(`materialCategories[${index}][name]`, item);
    });

    data[warehouseSearchFormKeys.features]
      .filter((item) => item[item.name] === "yes")
      .forEach((item, index) => {
        warehouseFormData.append(`features[${index}][name]`, item.name);
      });

    const files = data[warehouseSearchFormKeys.warehouseImages];

    for (let i = 0; i < files.length; i++) {
      warehouseFormData.append("files", files[i]);
    }

    mutate(
      {
        partnerId: profileData?.user?.partner,
        formData: warehouseFormData,
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.data.errors, error?.response.data.message, isError]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      sx={{
        padding: {
          xs: "40px 16px",
          sm: "initial",
        },
        paddingBottom: {
          sm: 2,
        },
        mt: {
          md: 7,
        },
        "& > form": {
          width: "100%",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <img
              src={WarehouseSearchFormBg}
              alt="warehouse"
              style={{
                position: "sticky",
                top: 152,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledFormTitle>
                  {warehouse_labels.warehouseDetailsTitleText}
                </StyledFormTitle>
              </Grid>
              <Grid item xs={12} marginBottom={"4px"}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.warehouseName}
                  label={warehouse_labels.warehouseNameLabelText}
                  placeholder={warehouse_labels.warehouseNamePlaceholderText}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateInput
                  control={control}
                  name={warehouseSearchFormKeys.establishedYear}
                  label={warehouse_labels.establishedYearLabelText}
                  placeholder={warehouse_labels.establishedYearPlaceholderText}
                  showYearPicker={true}
                  minDate={"1900-01-01"}
                  format="yyyy"
                />
              </Grid>
              <Grid item xs={12} marginTop={1}>
                <AutoCompleteMapField
                  control={control}
                  name={warehouseSearchFormKeys.warehouseLocation}
                  label={warehouse_labels.mapLocationLabelText}
                  placeholder={warehouse_labels.mapLocationPlaceholderText}
                  reset={(address, location) => {
                    setValue(
                      warehouseSearchFormKeys.warehouseLocation,
                      address
                    );
                    setValue(warehouseSearchFormKeys.lat, location.lat);
                    setValue(warehouseSearchFormKeys.lng, location.lng);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.warehouseCity}
                  placeholder={warehouse_labels.cityPlaceholderText}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.warehouseState}
                  placeholder={warehouse_labels.statePlaceholderText}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.warehouseZipCode}
                  placeholder={warehouse_labels.zipCodePlaceholderText}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    "& label > span > span, & label > span > span::before": {
                      height: {
                        md: 16,
                      },
                      width: { md: 16 },
                    },
                    "& label > span:last-child": {
                      fontSize: { md: 12 },
                      lineHeight: "16px",
                    },
                  }}
                >
                  <CheckBoxGroup
                    control={control}
                    name={warehouseSearchFormKeys.useSameBillingAddress}
                    options={[
                      {
                        name: "yes",
                        displayName:
                          warehouse_labels.sameBillingAddressLabelText,
                      },
                    ]}
                    setValue={setValue}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.billingAddress}
                  label={warehouse_labels.billingAddressLabelText}
                  placeholder={warehouse_labels.billingAddressPlaceholderText}
                  disabled={isUseSameBillingAddress}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: { xs: "4px", md: "12px" } }}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.gstInNumber}
                  label={warehouse_labels.gstinLabelText}
                  placeholder={warehouse_labels.gstinPlaceholderText}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: { xs: "4px", md: "12px" } }}>
                <TextArea
                  control={control}
                  name={warehouseSearchFormKeys.warehouseDescription}
                  label={warehouse_labels.warehouseDescriptionLabelText}
                  placeholder={
                    warehouse_labels.warehouseDescriptionPlaceholderText
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: { xs: 1, md: 3 } }}>
                <StyledFormTitle>
                  {warehouse_labels.storageDetailsLabelText}
                </StyledFormTitle>
              </Grid>
              <Grid item xs={12} marginBottom={"4px"}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.totalStorageSpace}
                  label={warehouse_labels.storageSpaceLabelText}
                  placeholder={warehouse_labels.storageSpacePlaceholderText}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: { xs: "4px", md: 0 } }}>
                <TextInput
                  control={control}
                  name={warehouseSearchFormKeys.availableSpace}
                  label={warehouse_labels.availableSpaceLabelText}
                  placeholder={warehouse_labels.availableSpacePlaceholderText}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: { xs: "4px", md: 1 } }}>
                <DropDown
                  name={warehouseSearchFormKeys.storageTypeAllowed}
                  control={control}
                  placeholder={
                    warehouse_labels.storageTypeAllowedPlaceholderText
                  }
                  label={warehouse_labels.storageTypeAllowedLabelText}
                  multiple={true}
                  options={formData?.storageTypes}
                  setValue={setValue}
                  onRemove={(value, index) => removeStorageType(index)}
                />
              </Grid>
              {storageTypesFields.length > 0 && (
                <Grid item xs={12}>
                  <StyledBoxContainer>
                    <Grid container spacing={2}>
                      {storageTypesFields.map((item, index) => (
                        <Grid item xs={6} key={item.id}>
                          <TextInput
                            control={control}
                            name={`${warehouseSearchFormKeys.storageTypes}.${index}.${item.name}`}
                            label={item.displayName}
                            placeholder={`Enter in ${item.rateUnit}`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </StyledBoxContainer>
                </Grid>
              )}
              <Grid item xs={12} sx={{ mt: { xs: "4px", md: 1 } }}>
                <CheckBoxSelect
                  name={warehouseSearchFormKeys.materialDetails}
                  control={control}
                  placeholder={warehouse_labels.materialDetailsPlaceholderText}
                  label={warehouse_labels.materialDetailsLabelText}
                  options={formData?.materialCategories}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: { xs: "4px", md: 1 } }}>
                <CheckBoxGroup
                  label={warehouse_labels.additionalServicesTitleText}
                  control={control}
                  name={warehouseSearchFormKeys.additionalServices}
                  errors={errors}
                  options={formData?.additionalServices}
                  setValue={setValue}
                  onRemove={(value, index) => removeAdditionalService(index)}
                />
              </Grid>
              {additionalServicesFields.length > 0 && (
                <Grid item xs={12}>
                  <StyledBoxContainer>
                    <Grid container spacing={2}>
                      {additionalServicesFields.map((item, index) => (
                        <Grid item xs={6} key={item.id}>
                          <TextInput
                            control={control}
                            name={`${warehouseSearchFormKeys.additionalServicesFields}.${index}.${item.name}`}
                            label={`${item.displayName} (per ${item.rateUnit})`}
                            placeholder={`Enter in per ${item.rateUnit}`}
                            type="number"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </StyledBoxContainer>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body1" fontSize={14}>
                  {warehouse_labels.availableFeaturesLabelText}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledBoxContainer>
                  <Grid container spacing={3}>
                    {featureFields.map((item, index) => (
                      <Fragment key={item.id}>
                        <Grid item xs={6} lg={8}>
                          <Typography fontSize={14} lineHeight={"20px"}>
                            {item.displayName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} lg={4} textAlign={"right"}>
                          <RadioButtonGroup
                            control={control}
                            name={`${warehouseSearchFormKeys.features}.${index}.${item.name}`}
                            options={DUMMY_ITEMS}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </StyledBoxContainer>
              </Grid>
              {featuresWatch.some((item) => item[item.name] === "") &&
                isSubmitted && (
                  <Grid item xs={12}>
                    <FormHelperText error>
                      Please select features available
                    </FormHelperText>
                  </Grid>
                )}
              <Grid item xs={12} sx={{ marginTop: { xs: 1, md: 3 } }}>
                <StyledFormTitle>
                  {warehouse_labels.uploadPhotoLabelText}
                </StyledFormTitle>
              </Grid>
              <Grid item xs={12} marginBottom={"4px"}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ImageUpload
                  name={warehouseSearchFormKeys.warehouseImages}
                  control={control}
                  isMultiple={true}
                  setValue={setValue}
                  uploadButtonText={warehouse_labels.uploadButtonText}
                />
              </Grid>
              <Grid
                item
                xs
                sx={{
                  mt: {
                    xs: 2,
                    md: 4,
                  },
                }}
              >
                <StyledButton
                  fullWidth={isMobileDevice ? true : false}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  component={LoadingButton}
                  loading={isLoading}
                  sx={{
                    textTransform: "none",
                    width: {
                      xs: "100%",
                      md: 180,
                    },
                    whiteSpace: "nowrap",
                  }}
                >
                  {warehouse_labels.reviewButtonText}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

import { Grid, Typography } from "@mui/material";

const AdditionalDetailsCard = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Typography
          fontSize={16}
          lineHeight={"19.36px"}
          sx={{
            fontWeight: {
              xs: 500,
              md: 600,
            },
          }}
          letterSpacing={"0.005em"}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} container spacing={2}>
        {props.services?.map((item) => (
          <Grid
            item
            xs={12}
            key={item.name}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-start"}
          >
            {item.icon && item.icon}
            <Typography fontSize={14} lineHeight={"16px"}>
              {item.displayName}
            </Typography>
          </Grid>
        ))}
        {props.products && (
          <Grid item xs={12} md={8}>
            <Typography fontSize={14} lineHeight={"20px"}>
              {props.products.join(", ")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AdditionalDetailsCard;

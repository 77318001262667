import { Button, Divider, Grid, Typography } from "@mui/material";
import { labels } from "../../constants";

import React from "react";
import { StyledChip } from "../../shared/UiElements/StatusChip";

const RetrieveMobileCard = ({
  code,
  customerDetails,
  status,
  actions,
  outboundDate,
  retrieveDate
}) => {
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >
            {customerDetails}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
            textAlign={"end"}
          >
            {`${code}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={0.5}>
          <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
            {outboundDate}
          </Typography>
          <Typography
            fontSize={12}
            lineHeight={"16px"}
            color={"text.secondary"}
          >
            {labels.outboundDateLabelText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          flexDirection={"column"}
          gap={0.5}
          textAlign={"end"}
        >
          <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
            {retrieveDate}
          </Typography>
          <Typography
            fontSize={12}
            lineHeight={"16px"}
            color={"text.secondary"}
          >
            Retrieval date
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={0.5}>
          <Typography fontSize={12} lineHeight={"16px"}>
            <StyledChip label={status} variant="filled" />
          </Typography>
          <Typography fontSize={12} color={"text.secondary"}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
          <Button disableRipple variant="link" onClick={actions[1]}>
            {actions[0]}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RetrieveMobileCard;

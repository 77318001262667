import { createWarehouseInvoice } from "../../warehouse/actions";
import { useMutation } from "@tanstack/react-query";

export const useMutateCreateWarehouseInvoice = () => {
  const createWarehouseInvoiceMutate = useMutation(({formData}) =>
    createWarehouseInvoice(formData)
  );

  return createWarehouseInvoiceMutate;
};

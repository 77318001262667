import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { statusKeys, warehouse_labels } from "../../constants";

import { Close } from "@mui/icons-material";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { stringCapitalization } from "../../shared/utils";

const InvoiceStatus = ({ close, status }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          padding: "25px 20px",
          background:
            status === statusKeys.PartiallyPaid ? "#CC9D42" : "#0F8B6C"
        }}
        flexDirection={"column"}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} gap={"12px"} alignItems={"center"}>
            <Typography fontSize={14} fontWeight={600} color={"white"}>
              ₹80,000
            </Typography>
          </Box>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: {
                xs: 13,
                md: 14
              },
              textDecoration: {
                xs: "underline",
                md: "none"
              },
              color: "white"
            }}
          >
            View Invoice
          </Button>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
          <Box display={"flex"} gap={0.5}>
            <Typography fontSize={14} color={"white"}>
              Invoice No :
            </Typography>
            <Typography fontSize={14} color={"white"}>
              1234 2345
            </Typography>
          </Box>
          <Box display={"flex"} gap={0.5}>
            <Typography fontSize={14} color={"white"}>
              Invoice Date :
            </Typography>
            <Typography fontSize={14} color={"white"}>
              15 Mar, 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        flexDirection={"column"}
        sx={{
          boxShadow: {
            xs: "none",
            md: "0px 8px 24px 0px #1515161A"
          },
          padding: {
            xs: "15px 20px",
            md: "60px 40px"
          },
          background: "#fff",
          display: { xs: "none", md: "flex" }
        }}
        gap={3}
      >
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} gap={"12px"} alignItems={"center"}>
            <Typography fontSize={20} fontWeight={700} letterSpacing={"0.2%"}>
              ₹80,000
            </Typography>
            <StyledChip label={stringCapitalization(status)} />
          </Box>
          <Box height={"fit-content"} onClick={close}>
            <Close
              sx={{
                color: "#607088",
                cursor: "pointer"
              }}
            />
          </Box>
        </Box>
        <Box
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
            <Box display={"flex"} gap={0.5}>
              <Typography fontSize={14} color={"text.secondary"}>
                Invoice No :
              </Typography>
              <Typography fontSize={14}>1234 2345</Typography>
            </Box>
            <Box display={"flex"} gap={0.5}>
              <Typography fontSize={14} color={"text.secondary"}>
                Invoice Date :
              </Typography>
              <Typography fontSize={14}>15 Mar, 2023</Typography>
            </Box>
          </Box>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: {
                xs: 13,
                md: 14
              },
              textDecoration: {
                xs: "underline",
                md: "none"
              }
            }}
          >
            View Invoice
          </Button>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: {
            xs: "none",
            md: "0px 8px 24px 0px #1515161A"
          },
          padding: {
            xs: "0 20px",
            md: "28px 40px 40px 40px"
          },
          background: "#fff"
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 16
              }
            }}
            fontWeight={500}
            letterSpacing={"0.08px"}
          >
            {warehouse_labels.customerPaymentsLabelText}
          </Typography>
        </Box>
        <Box marginTop={3} width={"100%"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.paymentAmountText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  fontWeight={700}
                >
                  ₹ 70,800
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                md={4}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex"
                  }
                }}
              >
                <Typography fontSize={13}>13 March 2023</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                >
                  {warehouse_labels.transactionIdText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  fontWeight={400}
                >
                  XXXX122345
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={"62px"}
              sx={{
                display: {
                  xs: "flex",
                  md: "none"
                }
              }}
            >
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                >
                  Transaction date
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  fontWeight={400}
                >
                  13 March 2023
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{
            display: {
              xs: "block",
              md: "none"
            },
            margin: "24px 0"
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: {
            xs: "none",
            md: "0px 8px 24px 0px #1515161A"
          },
          padding: {
            xs: "0 20px",
            md: "28px 40px 40px 40px"
          },
          background: "#fff"
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 16
              }
            }}
            fontWeight={500}
            letterSpacing={"0.08px"}
          >
            {warehouse_labels.commissionDetailsText}
          </Typography>
        </Box>
        <Box marginTop={3} width={"100%"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.commissionAmountText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  fontWeight={700}
                >
                  ₹ 12,000
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.commissionClaimedText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                >
                  ₹ 10,500
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.invoiceNo}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                >
                  -
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.invoiceDate}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                >
                  -
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{
            display: {
              xs: "block",
              md: "none"
            },
            margin: "24px 0"
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          background: "#fff",
          padding: {
            xs: "0 20px",
            md: "28px 40px 40px 40px"
          }
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 16
              }
            }}
            fontWeight={500}
            letterSpacing={"0.08px"}
          >
            {warehouse_labels.netPayout}
          </Typography>
        </Box>
        <Box marginTop={3} width={"100%"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.paymentAmountText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  fontWeight={700}
                >
                  ₹ 59,000
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex"
                  }
                }}
              >
                <Typography fontSize={13}>16 March 2023</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                >
                  {warehouse_labels.paymentId}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography fontSize={13}>XXXX122345</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={"62px"}
              sx={{
                display: {
                  xs: "flex",
                  md: "none"
                }
              }}
            >
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  color={"text.secondary"}
                >
                  Payment date
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14
                    }
                  }}
                  fontWeight={400}
                >
                  16 March 2023
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceStatus;

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import GatePass from "../components/GatePass";
import GatePassItemCard from "../components/GatePassItemCard";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import GatePassMobile from "../components/GatePassMobile";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchRetrievePackagingNotes } from "../../query-hooks/warehouseRetreive/useFetchRetrievePackagingNotes";
import { useFetchGatePassFormData } from "../../query-hooks/warehouseRetreive/useFetchGatePassFormData";
import moment from "moment";
import { useMutateCreateGatePass } from "../../query-hooks/warehouseRetreive/useMutateCreateGatePass";
import { enqueueSnackbar } from "notistack";
import { isMobile } from "react-device-detect";

const CreateGatePass = () => {
  const { retrieveId } = useParams();
  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      reports: []
    }
  });

  const { fields, append } = useFieldArray({
    name: "reports",
    control
  });

  const { data, isFetching, isFetched } =
    useFetchRetrievePackagingNotes(retrieveId);

  const { data: formData, isFetching: isFetchingForm } =
    useFetchGatePassFormData(retrieveId);

  const { mutate, isLoading, isError, error } = useMutateCreateGatePass();

  useEffect(() => {
    if (isFetched) {
      data?.packagingNotes?.forEach((item) => {
        append({
          packageId: item?._id,
          packageName: item?.name,
          packagingMaterial: item?.packagingType?.displayName,
          packageSize: item?.packageDimension?.displayName,
          packageCondition: item?.receivedConditionStatus?.name,
          rackNumber: ""
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, data?.packagingNotes, append]);

  const onSendGatePass = (data) => {
    mutate(
      {
        retrieveId: retrieveId,
        data: {
          transport: {
            name: data.Transporter,
            regNo: data.truckRegNo
          },
          gatePassPreparedBy: data.gatepassPreparedBy,
          packagingNotes: data.reports.map((item) => {
            return {
              packagingNoteId: item.packageId,
              retrievedConditionStatus: item.packageCondition
            };
          })
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("GatePass Sent!", { variant: "success" });
          navigate("/orders/retrieve");
          if (isMobile) {
            hideDrawer();
          } else {
            hideDialog();
          }
        }
      }
    );
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
    }
  }, [error?.response.data.message, isError]);

  return isFetching ? (
    <Backdrop
      sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSendGatePass)}
      display={"flex"}
      flexDirection={"column"}
      gap={"20px"}
      sx={{
        padding: {
          xs: "30px 16px",
          md: 0
        }
      }}
      marginBottom={3}
    >
      <Box
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          display: {
            xs: "none",
            md: "flex"
          }
        }}
      >
        <Typography fontSize={20} fontWeight={500} letterSpacing={"0.04px"}>
          {`Selected Packages (${"No Data"}/${"No Data"})`}
        </Typography>
        <Box display={"flex"} gap={"20px"}>
          <Button
            disableRipple
            variant="link"
            onClick={() => {
              showDialog({
                component: (
                  <GatePass
                    control={control}
                    handleSubmit={handleSubmit}
                    onSendGatePass={onSendGatePass}
                    hideDialog={hideDialog}
                    isLoading={isLoading}
                    fields={fields}
                    customerBillingDetails={
                      formData?.order?.customerBillingDetails
                    }
                    orderCode={formData?.order?.warehouseOrder?.code}
                    outboundDate={
                      formData?.order?.recentOrderItem?.outboundDate
                    }
                    warehouseBillingDetails={formData?.warehouse}
                  />
                ),
                size: "lg",
                backdropOff: true
              });
            }}
          >
            Create Gatepass
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{
          display: {
            xs: "none",
            md: "block"
          }
        }}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Box display={"flex"} gap={"4px"}>
            <Typography fontSize={14} fontWeight={600}>
              Order No :
            </Typography>
            <Typography fontSize={14} color={"text.secondary"}>
              {formData?.order?.warehouseOrder?.code}
            </Typography>
          </Box>
          <Box display={"flex"} gap={"4px"}>
            <Typography fontSize={14} fontWeight={600}>
              Order date :
            </Typography>
            <Typography fontSize={14} color={"text.secondary"}>
              {moment(formData?.order?.createdAt).format("DD-MM-YYYY")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        {fields.map((item, index) => (
          <GatePassItemCard
            key={item.id}
            {...item}
            control={control}
            index={index}
            formData={formData?.retrievedConditionStatuses}
            isFetchingForm={isFetchingForm}
          />
        ))}
      </Box>
      <Button
        variant="contained"
        sx={{
          height: 48,
          display: {
            xs: "block",
            md: "none"
          }
        }}
        onClick={() => {
          showDrawer({
            component: (
              <GatePassMobile
                control={control}
                handleSubmit={handleSubmit}
                onSendGatePass={onSendGatePass}
                close={hideDrawer}
                isLoading={isLoading}
                fields={fields}
                customerBillingDetails={formData?.order?.customerBillingDetails}
                orderCode={formData?.order?.warehouseOrder?.code}
                outboundDate={formData?.order?.recentOrderItem?.outboundDate}
                warehouseBillingDetails={formData?.warehouse}
              />
            ),
            closeIcon: false
          });
        }}
      >
        Create Gatepass
      </Button>
    </Box>
  );
};

export default CreateGatePass;

import { Box, Button, Grid } from "@mui/material";
import { StorageKeys, UserRoles } from "../../constants/storage-keys";
import { transport_labels, warehouse_labels } from "../../constants";

import Storage from "../../shared/utils/Storage";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import { SearchForm as TransportSearchForm } from "../../transport/pages/SearchForm";
import { SearchForm as WarehouseSearchForm } from "./SearchForm";
import { useState } from "react";

const DashboardForm = () => {
  const type = Storage.getItem(StorageKeys.dashboardUser);
  const [showForm, setShowForm] = useState(false);
  return !showForm ? (
    <Grid container spacing={"120px"} height={"100vh"}>
      <Grid item xs={12} md={6}></Grid>
      <Grid
        item
        xs={12}
        md={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Box
          sx={{
            marginBottom: {
              xs: 2,
              md: "27px",
            },
          }}
        >
          <StyledResponsiveText
            variant="h3"
            desktop={{
              fontSize: 24,
              fontWeight: 500,
              lineHeight: "29px",
            }}
            mobile={{
              fontSize: 28,
              fontWeight: 400,
              lineHeight: "33.89px",
              letterSpacing: "0.002em",
            }}
          >
            {warehouse_labels.listYourText}
          </StyledResponsiveText>
          <StyledResponsiveText
            variant="h2"
            desktop={{
              fontSize: 28,
              fontWeight: 600,
              lineHeight: "33.89px",
              letterSpacing: "-0.002em",
            }}
            mobile={{
              fontSize: 24,
              fontWeight: 700,
              lineHeight: "29.05px",
            }}
          >
            {type === UserRoles.warehouseAdmin
              ? warehouse_labels.warehouseText
              : transport_labels.transportText}
          </StyledResponsiveText>
        </Box>
        <Box
          maxWidth={380}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"stretch"}
        >
          <StyledResponsiveText
            variant="body1"
            color={"text.secondary"}
            desktop={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
            }}
            mobile={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
            }}
          >
            {warehouse_labels.dashboardCaptionText}
          </StyledResponsiveText>
          {type === UserRoles.warehouseAdmin ? (
            <Button
              variant="contained"
              sx={{ mt: 8, height: 64 }}
              onClick={() => {
                setShowForm(true);
              }}
            >
              {warehouse_labels.addWarehouseButtonText}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ mt: 8, height: 64 }}
              onClick={() => setShowForm(true)}
            >
              {warehouse_labels.addTransportButtonText}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  ) : type === UserRoles.warehouseAdmin ? (
    <WarehouseSearchForm />
  ) : (
    <TransportSearchForm />
  );
};

export default DashboardForm;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  styled,
} from "@mui/material";
import { East, ExpandMore } from "@mui/icons-material";

import PriceBenchmarks from "./PriceBenchmarks";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import Storage from "../../shared/utils/Storage.js";
import { StorageKeys } from "../../constants/storage-keys.js";
import moment from "moment";

const StyledDivider = () => (
  <Divider orientation="vertical" sx={{ borderColor: "#B7BEC7", height: 38 }} />
);

export const StyledAccordion = styled(Accordion)(() => ({
  border: "0.4px solid #95A1B0",
  borderRadius: "8px !important",
  padding: "8px 0px",
  marginBottom: "0 !important",
  "&:before": {
    display: "none",
  },
}));

export const TextBox = ({ value, label, direction = false, gap = "4px" }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        flexDirection: direction
          ? "column"
          : {
              xs: "column-reverse",
              md: "column",
            },
      }}
      gap={gap}
    >
      <Typography
        sx={{
          fontSize: 13,
          fontWeight: 500,
          lineHeight: "18px",
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: direction ? 13 : 12,
            md: 13,
          },
          fontWeight: {
            xs: direction ? 400 : 500,
            md: 400,
          },
          lineHeight: "18px",
        }}
        color={"text.secondary"}
      >
        {label}
      </Typography>
    </Box>
  );
};

const DateRangeBox = ({ inboundDate, outboundDate }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
      <Box display={"flex"} gap={"19.3px"} alignItems={"center"}>
        <TextBox
          label={"Inbound date"}
          value={moment(inboundDate).format("DD MMMM YYYY")}
        />
        <East />
        <TextBox
          label={"Outbound date"}
          value={moment(outboundDate).format("DD MMMM YYYY")}
        />
      </Box>
    </Box>
  );
};

export const DetailsGrid = ({ data }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        borderTop: "0.5px solid #B7BEC7",
        paddingTop: 3,
        flexWrap: "wrap",
        gap: {
          xs: 2,
          md: 3,
        },
      }}
    >
      <TextBox
        label={"Material type"}
        value={data?.materialCategory?.displayName || "--"}
        direction="true"
      />
      <StyledDivider />
      <TextBox
        label={"Packaging"}
        value={data?.packagingType?.displayName || "--"}
        direction="true"
      />
      <StyledDivider />
      <TextBox
        label={"Space type"}
        value={data?.storageType?.displayName || "--"}
        direction="true"
      />
      <StyledDivider />
      <TextBox
        label={"Dimensions & Units"}
        value={`${data?.packageDimension?.displayName || "--"} | ${data?.packageQuantity || "-- "}pcs`}
        direction="true"
      />
      <StyledDivider />
      <TextBox
        label={`Area in ${data?.storageType?.name || "--"}`}
        value={`${data?.storageQuantity} ${data?.storageType?.name || "--"}`}
        direction="true"
      />
    </Box>
  );
};

const InquiryDetails = ({ searchForm, warehouseRfq, status }) => {
  const partnerId = Storage.getItem(StorageKeys.PARTNER_ID);
  return (
    <>
      {/**
       * for mobile view
       */}
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <StyledAccordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <DateRangeBox
              inboundDate={searchForm.inboundDate}
              outboundDate={searchForm.outboundDate}
            />
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <DetailsGrid data={searchForm} />
            <Box marginTop={3} marginBottom={3}>
              <TextBox
                direction={true}
                value={"Item Description"}
                label={searchForm.itemDescription}
                gap={"8px"}
              />
            </Box>
          </AccordionDetails>
        </StyledAccordion>
        <Box
          sx={{
            border: "0.4px solid #95A1B0",
            borderRadius: "8px",
          }}
        >
          <PriceBenchmarks
            competitors={warehouseRfq?.partners?.filter(
              (item) => item.partner !== partnerId
            )}
            proposedPrice={warehouseRfq?.customerExpectedPrice}
            yourPrice={
              warehouseRfq?.partners?.find((item) => item.partner === partnerId)
                ?.totalAmount
            }
            maxValue={warehouseRfq?.partners?.reduce(
              (max, obj) => (obj.totalAmount > max ? obj.totalAmount : max),
              -Infinity
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          borderTop: "0.5px solid #B7BEC7",
          borderBottom: "0.5px solid #B7BEC7",
        }}
      >
        <Box
          sx={{
            borderRight: "0.5px solid #B7BEC7",
            display: "flex",
            width: "60%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              padding: "40px 40px 24px 0",
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <DateRangeBox
              inboundDate={searchForm.inboundDate}
              outboundDate={searchForm.outboundDate}
            />
            <StyledChip label={status} />
          </Box>
          <Box marginBottom={5}>
            <DetailsGrid data={searchForm} />
          </Box>
          <Box marginBottom={5} maxWidth={594} marginRight={1}>
            <TextBox
              value={"Item Description"}
              label={searchForm.itemDescription}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "40%",
            flexDirection: "column",
          }}
        >
          <PriceBenchmarks
            competitors={warehouseRfq?.partners?.filter(
              (item) => item.partner !== partnerId
            )}
            proposedPrice={warehouseRfq?.customerExpectedPrice}
            yourPrice={
              warehouseRfq?.partners.find((item) => item.partner === partnerId)
                ?.totalAmount
            }
            maxValue={warehouseRfq?.partners?.reduce(
              (max, obj) => (obj.totalAmount > max ? obj.totalAmount : max),
              -Infinity
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default InquiryDetails;

import { Box, Grid, IconButton, Typography } from "@mui/material";
import * as yup from "yup";
import { Close } from "@mui/icons-material";
import DateInput from "../../shared/formElements/DateInput";
import DropDown from "../../shared/formElements/DropDown";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import TextInput from "../../shared/formElements/TextInput";
import { useCustomDialog } from "../../shared/customDialog";
import { useForm } from "react-hook-form";
import { useAssignDriverMutateQuery } from "../../query-hooks/TransportOrders/useAssignDriverMutateQuery";
import { useFetchVehicles } from "../../query-hooks/TransportOrders/useFetchVehicles";
import { AssignDriverKeys } from "../../constants/formKeys";
import { yupResolver } from "@hookform/resolvers/yup";
import { AssignDriver_labels, error_msg } from "../../constants";
import { useEffect } from "react";
import moment from "moment";
import { isMobileDevice } from "../../App";
import { useDrawer } from "../../shared/customSideDrawer";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

const AssignDriverFormSchema = yup.object({
  [AssignDriverKeys.trucks]: yup
    .string()
    .required(`${AssignDriver_labels.trucks} ${AssignDriver_labels.required}`),
  [AssignDriverKeys.driverName]: yup
    .string()
    .required(
      `${AssignDriver_labels.driverName} ${AssignDriver_labels.required}`
    ),
  [AssignDriverKeys.driverNumber]: yup
    .string()
    .required(
      `${AssignDriver_labels.driverNumber} ${AssignDriver_labels.required}`
    )
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  [AssignDriverKeys.pickupTime]: yup
    .string()
    .required(
      `${AssignDriver_labels.pickupTime} ${AssignDriver_labels.required}`
    ),
});

const AssignDriverForm = ({
  close,
  createInvoiceHandler,
  orderId,
  refetchOrders,
}) => {
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(AssignDriverFormSchema),
  });
  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const { data, isFetching } = useFetchVehicles(orderId);
  const { mutate, isLoading, isError, error } = useAssignDriverMutateQuery();

  useEffect(() => {
    if (!isFetching) {
      setValue(AssignDriverKeys.pickupDate, new Date(data?.inboundDate));
    }
  }, [isFetching, setValue, data?.inboundDate]);

  const getVehicleDropDown = (options) => {
    return {
      ...{
        name: options._id,
        displayName: `${options?.make?.displayName} - ${options?.model?.displayName}`,
      },
    };
  };

  const onSubmit = (data) => {
    mutate(
      {
        orderId: orderId,
        formData: {
          vehicleId: data.trucks,
          pickupTime: moment(data.pickupTime).format("HH:mm"),
          driver: { name: data.driverName, mobile: data.driverNumber },
        },
      },
      {
        onSuccess: () => {
          isMobileDevice
            ? showDrawer({
                component: (
                  <SuccessfulDialog
                    text={"Driver assigned successfully!"}
                    subText={
                      "You can expect a response in 4-5 working hours from the customer."
                    }
                    btn1Text={"Go Back"}
                    btn2Text={"Create Invoice"}
                    btn1Callback={hideDrawer}
                    btn2Callback={createInvoiceHandler}
                  />
                ),
              })
            : showDialog({
                component: (
                  <SuccessfulDialog
                    text={"Driver assigned successfully!"}
                    subText={
                      "You can expect a response in 4-5 working hours from the customer."
                    }
                    btn1Text={"Go Back"}
                    btn2Text={"Create Invoice"}
                    btn1Callback={hideDialog}
                    btn2Callback={createInvoiceHandler}
                  />
                ),
              });
          refetchOrders();
        },
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.data.errors, error?.response.data.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      padding={3}
      gap={4}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontSize={20} fontWeight={500} letterSpacing={"0.04px"}>
          Assign Driver
        </Typography>
        <IconButton sx={{ p: 0 }} disableRipple onClick={close}>
          <Close />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropDown
            name={AssignDriverKeys.trucks}
            options={
              !isFetching
                ? data?.vehicles?.map((item) => getVehicleDropDown(item))
                : []
            }
            label={AssignDriver_labels.trucks}
            control={control}
            isLoading={isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name={AssignDriverKeys.driverName}
            placeholder={AssignDriver_labels.driverName}
            label={AssignDriver_labels.driverName}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name={AssignDriverKeys.driverNumber}
            placeholder={AssignDriver_labels.driverNumber}
            label={AssignDriver_labels.driverNumber}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            name={AssignDriverKeys.pickupDate}
            placeholder={AssignDriver_labels.pickupDate}
            label={AssignDriver_labels.pickupDate}
            control={control}
            minDate={"1900-01-01"}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            name={AssignDriverKeys.pickupTime}
            placeholder={AssignDriver_labels.pickupTime}
            label={AssignDriver_labels.pickupTime}
            control={control}
            showTimeSelectOnly={true}
            showTimeSelect={true}
            timeIntervals={15}
            format="h:mm aa"
          />
        </Grid>
      </Grid>
      <LoadingButton
        variant="contained"
        sx={{ height: 64 }}
        onClick={handleSubmit(onSubmit)}
        loading={isLoading}
      >
        Share details
      </LoadingButton>
    </Box>
  );
};

export default AssignDriverForm;

import * as yup from "yup";

import {
  StorageFormKeys,
  warehouseSearchFormKeys
} from "../../constants/formKeys";

import { warehouse_labels } from "../../constants";

export const dynamicFormShape = yup.lazy((value) => {
  const shapes = {};
  const DATA_OBJ_KEYS = Object.keys(value);
  DATA_OBJ_KEYS.forEach((parameter) => {
    shapes[parameter] = yup
      .string()
      .required(`${parameter} ${warehouse_labels.isRequired}`);
  });
  return yup.object().shape(shapes);
});

// const dynamicFormShapeFeatures = yup.lazy((value) => {
//   const shapes = {};
//   const DATA_OBJ_KEYS = Object.keys(value).filter(
//     (item) => !["name", "displayName"].includes(item)
//   );
//   DATA_OBJ_KEYS.forEach((parameter) => {
//     shapes[parameter] = yup
//       .string()
//       .matches(/yes/)
//       .required(`${parameter} ${warehouse_labels.isRequired}`);
//   });
//   return yup.object().shape(shapes);
// });

export const searchFormSchema = yup.object({
  [warehouseSearchFormKeys.warehouseName]: yup
    .string()
    .required(
      `${warehouse_labels.warehouseNameLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.establishedYear]: yup
    .string()
    .required(
      `${warehouse_labels.establishedYearLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.gstInNumber]: yup
    .string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter a correct GST number"
    )
    .required(
      `${warehouse_labels.gstinLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.warehouseDescription]: yup
    .string()
    .required(
      `${warehouse_labels.warehouseDescriptionLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.warehouseLocation]: yup
    .string()
    .required(
      `${warehouse_labels.mapLocationLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.warehouseCity]: yup
    .string()
    .required(
      `${warehouse_labels.cityPlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.warehouseState]: yup
    .string()
    .required(
      `${warehouse_labels.statePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.warehouseZipCode]: yup
    .string()
    .required(
      `${warehouse_labels.zipCodePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.billingAddress]: yup
    .string()
    .required(
      `${warehouse_labels.billingAddressLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.totalStorageSpace]: yup
    .string()
    .required(
      `${warehouse_labels.storageSpaceLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.availableSpace]: yup
    .string()
    .required(
      `${warehouse_labels.availableSpaceLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.storageTypeAllowed]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 storage type")
    .required(
      `${warehouse_labels.storageTypeAllowedLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.materialDetails]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 material")
    .required(
      `${warehouse_labels.materialDetailsLabelText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.additionalServices]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 service")
    .required(
      `${warehouse_labels.additionalServicesTitleText} ${warehouse_labels.isRequired}`
    ),
  [warehouseSearchFormKeys.additionalServicesFields]: yup
    .array()
    .of(dynamicFormShape),
  [warehouseSearchFormKeys.storageTypes]: yup.array().of(dynamicFormShape),
  [warehouseSearchFormKeys.warehouseImages]: yup
    .array()
    .of(yup.mixed())
    .min(5, "Upload upto 05 photos")

  // [warehouseSearchFormKeys.features]: yup
  //   .array()
  //   .of(dynamicFormShapeFeatures)
  //   .min(1),
});

export const storageTypesFormValidation = yup.object({
  [StorageFormKeys.types]: yup.array().of(dynamicFormShape)
});

export const storageAreaFormValidation = yup.object({
  [StorageFormKeys.available]: yup
    .string()
    .required(
      `${warehouse_labels.availableSpaceLabelText} ${warehouse_labels.isRequired}`
    ),
  [StorageFormKeys.total]: yup
    .string()
    .required(
      `${warehouse_labels.storageSpaceLabelText} ${warehouse_labels.isRequired}`
    )
});

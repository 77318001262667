import {
  AppBar,
  Badge,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Close, Menu as MenuIcon, Person } from "@mui/icons-material";
import { DesktopSocialBar, MobileSocialBar } from "./SocialBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NotificationsOutlined } from "@mui/icons-material";
import { StorageKeys, UserRoles } from "../../constants/storage-keys";
import {
  StyledMobileDrawer,
  StyledMobileIconButton,
  StyledToolBar,
  headerTheme,
} from "../theme/headerTheme";
import { useContext, useEffect, useState } from "react";

import AppLogo from "../../assets/Warex.svg";
import { AuthContext } from "../context/auth-context";
import Login from "./Login";
import Storage from "../utils/Storage";
import { drawerWidth } from "../theme/drawerTheme";
import { labels } from "../../constants";
import styled from "@emotion/styled";
import { useCustomDialog } from "../customDialog";
import { useDrawer } from "../customSideDrawer";
import Notifications from "../../warehouse/components/Notifications";
import { useFetchNotifications } from "../../query-hooks/Notifications/useFetchNotifications";

const menu = [
  {
    label: "Product",
    to: `${process.env.REACT_APP_REDIRECT_URL}`,
  },
  {
    label: "Industries",
    to: `${process.env.REACT_APP_REDIRECT_URL}`,
    children: [
      {
        label: "Food & FMCG",
        to: `${process.env.REACT_APP_REDIRECT_URL}`,
      },
      {
        label: "Apparel",
        to: `${process.env.REACT_APP_REDIRECT_URL}`,
      },
      {
        label: "Furniture",
        to: `${process.env.REACT_APP_REDIRECT_URL}`,
      },
    ],
  },
  {
    label: "Partner",
    to: "/",
  },
  {
    label: "Blog",
    to: `${process.env.REACT_APP_REDIRECT_URL}`,
  },
  {
    label: "About Us",
    to: `${process.env.REACT_APP_REDIRECT_URL}`,
  },
];

const StyledPrimaryText = styled(Typography)(({ theme, isActive }) => ({
  color: isActive ? "#2773FF" : theme.palette.text.primary,
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px",
  fontWeight: isActive ? 500 : 400,
}));

const StyledLoginText = styled(Typography)(({ theme, isActive }) => ({
  color: isActive ? "#2773FF" : theme.palette.text.primary,
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px",
  fontWeight: 600,
}));

const StyledListItemText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "19px",
  color: "#FFFFFF",
  textDecoration: "none",
}));

const StyledListSubItemText = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: 14,
  lineHeight: "17px",
  color: "#C7CED4",
  textDecoration: "none",
  letterSpacing: "0.01em",
}));

const MobileMenuList = ({ onClose }) => {
  return (
    <Grid container spacing={9}>
      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
        <StyledMobileIconButton onClick={onClose}>
          <Close sx={{ color: "white" }} />
        </StyledMobileIconButton>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        {menu.map((item) => (
          <Grid item xs={12}>
            <StyledListItemText component={Link} to={item.to} onClick={onClose}>
              {item.label}
            </StyledListItemText>
            {item.children && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                  "& > a": {
                    mb: "12px",
                  },
                }}
              >
                {item.children.map((child) => (
                  <StyledListSubItemText
                    component={Link}
                    to={child.to}
                    onClick={onClose}
                  >
                    {child.label}
                  </StyledListSubItemText>
                ))}
              </Box>
            )}
          </Grid>
        ))}
        <Grid item xs={12} mt={"138px"}>
          <StyledListItemText>Logout / Login</StyledListItemText>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Header = ({ home = false }) => {
  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog } = useCustomDialog();

  const [mobileDrawer, seMobileDrawer] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { currentUser, logout } = useContext(AuthContext);
  const [isBackNavigationOnScreen, setIsBackNavigationOnScreen] =
    useState(false);

  const { data } = useFetchNotifications(1);

  const navigate = useNavigate();
  const location = useLocation();

  const isRoot = ["/", "/faqs"].includes(location.pathname);

  const isValid = Boolean(currentUser);

  const settings = isValid ? ["Profile", "Logout"] : ["Login"];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const redirectToMainSite = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL;
  };

  const handleCloseUserMenu = (e) => {
    const action = e.target.id;
    // eslint-disable-next-line default-case
    switch (action) {
      case "Login":
        showDialog({
          component: <Login />,
          size: "xs",
          backdropOff: true,
        });
        break;
      case "Logout":
        logout();
        redirectToMainSite();
        break;
      case "Profile":
        navigate("/profile");
        break;
    }

    setAnchorElUser(null);
  };

  const openSideDrawer = () => {
    showDrawer({
      title: labels.notifications,
      component: <Notifications hideDrawer={hideDrawer} />,
      closeIcon: false,
    });
  };

  const LoggedInHeader = (
    <>
      {isValid && (
        <>
          <Grid
            item
            sx={{
              display: {
                sm: "flex",
                xs: "none",
              },
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              left: 0,
              top: 0,
              height: "100%",
              padding: 0,
            }}
          >
            <Typography
              sx={{
                color: "#2773FF",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "20px",
              }}
            >
              {Storage.getItem(StorageKeys.dashboardUser) ===
              UserRoles.warehouseAdmin
                ? "WAREHOUSE MANAGEMENT SYSTEM"
                : "TRANSPORT MANAGEMENT SYSTEM"}
            </Typography>
          </Grid>
          <Grid item xs="auto" display="flex" alignItems="center">
            <Badge
              badgeContent={data?.pages[0]?.unreadCount ?? 0}
              color="primary"
              overlap="circular"
            >
              <IconButton onClick={openSideDrawer} sx={{ p: 0 }}>
                <NotificationsOutlined />
              </IconButton>
            </Badge>
          </Grid>
        </>
      )}
      <Grid item xs="auto" display="flex" alignItems="center">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          <Person />
        </IconButton>
      </Grid>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((menu) => (
          <MenuItem key={menu} onClick={handleCloseUserMenu}>
            <Typography textAlign="center" id={menu} whiteSpace={"nowrap"}>
              {menu}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const GlobalHeader = (
    <>
      <Grid
        item
        xs
        display="flex"
        alignItems="center"
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        {menu.map((option, index) => (
          <StyledPrimaryText
            key={index}
            variant="body2"
            sx={{ px: 3, textDecoration: "none", whiteSpace: "nowrap" }}
            component={Link}
            to={option.to}
            isActive={location.pathname === option.to}
          >
            {option.label}
          </StyledPrimaryText>
        ))}
        <StyledLoginText
          fontWeight={800}
          component="div"
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={redirectToMainSite}
        >
          <Person fontSize="small" sx={{ mr: 1 }} />
          Customer Login
        </StyledLoginText>
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: { xs: "flex", md: "none", alignItems: "center" },
        }}
      >
        <StyledMobileIconButton>
          <Person />
        </StyledMobileIconButton>
        <StyledMobileIconButton onClick={() => seMobileDrawer(true)}>
          <MenuIcon />
        </StyledMobileIconButton>
        <StyledMobileDrawer open={mobileDrawer}>
          <MobileMenuList onClose={() => seMobileDrawer(false)} />
        </StyledMobileDrawer>
      </Grid>
    </>
  );

  useEffect(() => {
    const isBackNavigationPresent = Boolean(
      document.getElementById("crumb-back-navigation")
    );
    if (isBackNavigationPresent) {
      setIsBackNavigationOnScreen(isBackNavigationPresent);
    } else {
      setIsBackNavigationOnScreen(isBackNavigationPresent);
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={headerTheme}>
      <Box
        sx={{
          display: {
            xs: "none",
            md: isRoot ? "block" : "none",
          },
        }}
      >
        <DesktopSocialBar />
      </Box>
      <AppBar
        position={isRoot ? "relative" : "fixed"}
        sx={{
          width: {
            sm: !isRoot && isValid ? `calc(100% - ${drawerWidth}px)` : "100%",
            xs: "100%",
          },
          ml: {
            sm: !isRoot && isValid ? `${drawerWidth}px` : "auto",
            xs: "auto",
          },
          boxShadow: (isRoot || isBackNavigationOnScreen) && "none",
          backgroundColor: isRoot && "#F7F8FA",
          border: isRoot && "1px solid #F0F2F5",
          zIndex: 1,
        }}
      >
        <StyledToolBar
          component={Container}
          token={!isRoot ? isValid : undefined}
          sx={
            !isRoot
              ? {
                  padding: {
                    sm: "0 40px",
                    xs: "0 16px",
                  },
                }
              : {
                  padding: {
                    sm: "0 95px",
                    xs: "0 16px",
                  },
                }
          }
          maxWidth={!isRoot ? "auto" : "xl"}
        >
          <Box
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              "& > img": {
                display: {
                  sm: !isRoot && isValid ? "none" : "flex",
                },
              },
            }}
            onClick={() => redirectToMainSite()}
          >
            <img src={AppLogo} alt={labels.appTitle} />
          </Box>
          <Box>
            <Grid container spacing={1}>
              {isRoot ? GlobalHeader : LoggedInHeader}
            </Grid>
          </Box>
        </StyledToolBar>
      </AppBar>
      <Box
        sx={{
          display: {
            xs: isRoot ? "block" : "none",
            md: "none",
          },
        }}
      >
        <MobileSocialBar />
      </Box>
    </ThemeProvider>
  );
};

export default Header;

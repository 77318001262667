/* eslint-disable no-unused-vars */

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import PackageItemCard from "../components/PackageItemCard";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import UploadMrr from "../components/UploadMrr";
import { useCustomDialog } from "../../shared/customDialog";
import { useNavigate, useParams } from "react-router-dom";
import { statusKeys, warehouse_labels } from "../../constants";
import { useFetchPackagingNote } from "../../query-hooks/warehousePackagingNote/useFetchPackagingNote";
import { useUpdateMrrMutateQuery } from "../../query-hooks/warehousePackagingNote/useUpdateMrrMutateQuery";
import { useFetchWarehouseOrderDetailsQuery } from "../../query-hooks/warehouseOrders/useFetchWarehouseOrderDetailsQuery";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useUpdateStorageDetailsMutateQuery } from "../../query-hooks/warehousePackagingNote/useUpdateStorageDetailsMutateQuery";
import { useFetchCreateMRRFormDataQuery } from "../../query-hooks/warehousePackagingNote/useFetchCreateMRRFormDataQuery";
import { useDrawer } from "../../shared/customSideDrawer";

const mrrFormSchema = yup.object({
  reports: yup
    .array()
    .of(
      yup.object().shape({
        packageCondition: yup.string().required("Package Condition is required")
      })
    )
    .required()
});

const storageFormSchema = yup.object({
  reports: yup
    .array()
    .of(
      yup.object().shape({
        rackNumber: yup.string().required("Rack Number is required")
      })
    )
    .required()
});

const MaterialReceivedReport = () => {
  const { orderId } = useParams();

  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      reports: []
    },
    resolver: yupResolver(isSaved ? storageFormSchema : mrrFormSchema)
  });
  const { fields, append } = useFieldArray({
    name: "reports",
    control
  });
  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer } = useDrawer();

  const { data: packageData, isFetching: isPackageFetching } =
    useFetchPackagingNote(orderId);

  const { data: orderData, isFetching: isOrderFetching } =
    useFetchWarehouseOrderDetailsQuery(orderId);

  const { data: formData, isFetching: isFormFetching } =
    useFetchCreateMRRFormDataQuery(isSaved ? null : orderId);

  const {
    mutate: saveMrr,
    isLoading: isMrrSaving,
    isError: isMrrError,
    error: mrrError
  } = useUpdateMrrMutateQuery();

  const {
    mutate: updateStorage,
    isLoading: isStorageupdating,
    isError: isStorageError,
    error: storageError
  } = useUpdateStorageDetailsMutateQuery();

  useEffect(() => {
    if (!isPackageFetching) {
      packageData?.packagingNotes?.forEach((item) => {
        append({
          packageId: item?._id,
          packageName: item?.name,
          packagingMaterial: item?.packagingType?.displayName,
          packageSize: item?.packageDimension?.displayName,
          packageCondition: item?.receivedConditionStatus?.name || "",
          displayPackageCondition:
            item?.receivedConditionStatus?.displayName || "",
          rackNumber: item?.rackNumber || ""
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPackageFetching, packageData?.packagingNotes, append]);

  useEffect(() => {
    if (
      orderData?.order?.warehouseOrder?.warehouseStatus?.name ===
        statusKeys.ReceivedAtFacility ||
      orderData?.order?.warehouseOrder?.warehouseStatus?.name ===
        statusKeys.Stored
    ) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }, [orderData?.order?.warehouseOrder?.warehouseStatus?.name]);

  useEffect(() => {
    if (
      orderData?.order?.warehouseOrder?.warehouseStatus?.name ===
      statusKeys.Stored
    ) {
      setIsSubmitted(true);
    } else {
      setIsSubmitted(false);
    }
  }, [orderData?.order?.warehouseOrder?.warehouseStatus?.name]);

  const onSaveMrr = (data) => {
    saveMrr(
      {
        orderId: orderId,
        data: {
          packagingNotes: data.reports.map((item) => {
            return {
              packagingNoteId: item.packageId,
              receivedConditionStatus: item.packageCondition
            };
          })
        }
      },
      {
        onSuccess: () => {
          setIsSaved(true);
          showDialog({
            component: (
              <SuccessfulDialog
                text={`Material Received on ${moment(new Date()).format(
                  "DD-MM-YYYY HH:mm"
                )} `}
                subText={
                  "MRR is under saved mode. Please Add Storage Details after the packages are stored."
                }
                btn2Text={"Go back to Orders"}
                btn2Callback={() => {
                  navigate("/orders/my-orders");
                  hideDialog();
                }}
              />
            )
          });
        }
      }
    );
  };

  const onStorageSubmit = (data) => {
    updateStorage(
      {
        orderId: orderId,
        data: {
          packagingNotes: data.reports.map((item) => {
            return {
              packagingNoteId: item.packageId,
              rackNumber: item.rackNumber
            };
          })
        }
      },
      {
        onSuccess: () => {
          setIsSubmitted(true);
          showDialog({
            component: (
              <SuccessfulDialog
                text={"MRR submitted successfully!"}
                subText={"Order is in Stored Status now."}
                btn2Text={"Go to Stored Orders"}
                btn2Callback={() => {
                  hideDialog();
                  navigate("/orders/my-orders");
                }}
              />
            )
          });
        }
      }
    );
  };

  useEffect(() => {
    if (isMrrError || isStorageError) {
      enqueueSnackbar(
        mrrError?.response.data.message || storageError?.response.data.message,
        { variant: "error" }
      );
    }
  }, [
    mrrError?.response.data.message,
    storageError?.response.data.message,
    isMrrError,
    isStorageError
  ]);

  return isPackageFetching ? (
    <Backdrop
      sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isPackageFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"20px"}
      sx={{
        padding: {
          xs: "30px 16px",
          md: 0
        }
      }}
      marginBottom={3}
    >
      <Box
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          display: {
            xs: "none",
            md: "flex"
          }
        }}
      >
        <Typography fontSize={20} fontWeight={500} letterSpacing={"0.04px"}>
          {!isSaved
            ? warehouse_labels.materialReceivedReportText
            : !isSubmitted
            ? warehouse_labels.addStorageDetailsText
            : "Material Received Report"}
        </Typography>
        {!isSubmitted && (
          <Box display={"flex"} gap={"20px"}>
            <Button
              onClick={() => {
                showDialog({
                  component: <UploadMrr orderId={orderId} />,
                  size: "lg",
                  backdropOff: true,
                  closeIcon: true
                });
              }}
            >
              Upload MRR
            </Button>
            {isSaved ? (
              <Button
                component={LoadingButton}
                loading={isStorageupdating}
                onClick={handleSubmit(onStorageSubmit)}
              >
                Submit
              </Button>
            ) : (
              <Button
                component={LoadingButton}
                loading={isMrrSaving}
                onClick={handleSubmit(onSaveMrr)}
              >
                Save MRR
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          display: {
            xs: "none",
            md: "block"
          }
        }}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Box display={"flex"} gap={"4px"}>
            <Typography fontSize={14} fontWeight={600}>
              Order No :
            </Typography>
            <Typography fontSize={14} color={"text.secondary"}>
              {orderData?.order?.warehouseOrder?.code}
            </Typography>
          </Box>
          <Box display={"flex"} gap={"4px"}>
            <Typography fontSize={14} fontWeight={600}>
              Order date :
            </Typography>
            <Typography fontSize={14} color={"text.secondary"}>
              {moment(orderData?.order?.createdAt).format("DD-MM-YYYY")}
            </Typography>
          </Box>
        </Box>
        <Box
          flexDirection={"column"}
          gap={"10px"}
          sx={{
            display: {
              xs: "none",
              md: "flex"
            }
          }}
        >
          <Box display={"flex"} gap={"4px"}>
            <Typography fontSize={14} fontWeight={600}>
              MRR No :
            </Typography>
            <Typography fontSize={14} color={"text.secondary"}>
              --
            </Typography>
          </Box>
          <Box display={"flex"} gap={"4px"}>
            <Typography fontSize={14} fontWeight={600}>
              Received on :
            </Typography>
            <Typography fontSize={14} color={"text.secondary"}>
              {!isOrderFetching && orderData.order.mrrReceivedAt
                ? moment(orderData?.order?.mrrReceivedAt).format(
                    "DD-MM-YYYY/HH:mm"
                  )
                : "-- / --"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        {fields.map((item, index) => (
          <PackageItemCard
            key={item.id}
            {...item}
            control={control}
            index={index}
            isSaved={isSaved}
            isSubmitted={isSubmitted}
            formData={formData}
            isFormFetching={isFormFetching}
          />
        ))}
      </Box>
      <Box
        flexDirection={"column"}
        gap={"10px"}
        sx={{
          display: { xs: "flex", md: "none" }
        }}
      >
        <Button
          fullWidth
          variant="soft"
          sx={{ height: 48 }}
          onClick={() =>
            showDrawer({
              component: <UploadMrr orderId={orderId} />,
              closeIcon: false
            })
          }
        >
          Upload MRR
        </Button>
        {isSaved ? (
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 48 }}
            component={LoadingButton}
            loading={isStorageupdating}
            onClick={handleSubmit(onStorageSubmit)}
          >
            Submit
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 48 }}
            loading={isMrrSaving}
            onClick={handleSubmit(onSaveMrr)}
          >
            Save MRR
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MaterialReceivedReport;

import { getNotifications } from "../../warehouse/actions/index";
import { QueryKeys } from "../../constants/query-keys";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";

export const useFetchNotifications = () => {
  const { currentUser } = useContext(AuthContext);

  const queryWarehouses = useInfiniteQuery(
    [QueryKeys.queryFetchNotifications],
    ({ pageParam = 1 }) => getNotifications(pageParam),
    {
      enabled: Boolean(currentUser),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.notifications.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );

  return queryWarehouses;
};

import { QueryKeys } from "../../constants/query-keys";
import { getPackagingNotes } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchPackagingNote = (orderId) => {
  const packagingNotes = useQuery(
    [QueryKeys.queryFetchPackagingNotes, orderId],
    () => getPackagingNotes(orderId),
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );
  return packagingNotes;
};

import { getTransportInvoicesFormData } from "../../transport/actions/index";
import { QueryKeys } from "../../constants/query-keys";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportInvoiceFormData = (orderId) => {
  const transportInvoicesFormData = useQuery(
    [QueryKeys.queryFetchTransportInvoicesFormData, orderId],
    () => getTransportInvoicesFormData(orderId)
  );

  return transportInvoicesFormData;
};

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  styled
} from "@mui/material";
import { packageConditionColorCodes } from "../pages/constant";

import DropDown from "../../shared/formElements/DropDown";
import { ExpandMore } from "@mui/icons-material";
import { StyledAccordion } from "./InquiryDetails";
import TextInput from "../../shared/formElements/TextInput";

export const StyledBox = styled((props) => (
  <Box
    maxWidth={182}
    display={"flex"}
    sx={{
      flexDirection: {
        xs: "column-reverse",
        lg: "column"
      }
    }}
  >
    <Typography fontSize={14} color={"text.secondary"} lineHeight={"20px"}>
      {props.label}
    </Typography>
    <Typography
      fontSize={14}
      fontWeight={600}
      color={props.color || "text.primary"}
    >
      {props.value}
    </Typography>
  </Box>
))``;

export const packagingConditionOptions = (options = []) => {
  return [
    {
      name: options[0]?.name,
      displayName: (
        <Typography fontSize={14} color={"error.dark"}>
          {options[0]?.displayName}
        </Typography>
      )
    },
    {
      name: options[1]?.name,
      displayName: (
        <Typography fontSize={14} color={"error.main"}>
          {options[1]?.displayName}
        </Typography>
      )
    },
    {
      name: options[2]?.name,
      displayName: (
        <Typography fontSize={14} color={"success.dark"}>
          {options[2]?.displayName}
        </Typography>
      )
    }
  ];
};

const PackageItemCard = (props) => {
  const { control, index, isSaved, isSubmitted, formData, isFormFetching } =
    props;
  return (
    <Box>
      <Box
        padding={3}
        borderRadius={2}
        border={"0.7px solid #B7BEC7"}
        justifyContent={"space-between"}
        sx={{
          display: {
            xs: "none",
            lg: "flex"
          }
        }}
      >
        <StyledBox label={"Package name"} value={props.packageName} />
        <StyledBox label={"Packing Material"} value={props.packagingMaterial} />
        <StyledBox label={"Package Size"} value={props.packageSize} />
        <Box display={"flex"} gap={2}>
          <Box minWidth={182} width={"100%"}>
            {isSaved ? (
              <StyledBox
                label={"Package Condition"}
                value={props?.displayPackageCondition}
                color={packageConditionColorCodes[props.displayPackageCondition]}
              />
            ) : (
              <DropDown
                control={control}
                name={`reports.${index}.packageCondition`}
                options={
                  isFormFetching
                    ? []
                    : packagingConditionOptions(
                        formData?.receivedConditionStatuses
                      )
                }
                isLoading={isFormFetching}
                label={"Package Condition"}
              />
            )}
          </Box>
          <Box minWidth={182} width={"100%"}>
            {isSubmitted ? (
              <StyledBox label={"Rack number"} value={props?.rackNumber} />
            ) : (
              <TextInput
                control={control}
                name={`reports.${index}.rackNumber`}
                disabled={!isSaved}
                label={"Rack number"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <StyledAccordion
        disableGutters
        elevation={0}
        sx={{
          display: {
            xs: "block",
            lg: "none"
          },
          boxShadow: "0px 8px 24px 0px rgba(21, 21, 22, 0.10)",
          padding: "20px 0"
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
            <StyledBox label={"Package name"} value={props.packageName} />
            <Divider />
            <Box display={"flex"} gap={5}>
              <StyledBox
                label={"Packing Material"}
                value={props.packagingMaterial}
              />
              <StyledBox label={"Package Size"} value={props.packageSize} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Divider />
            {isSaved ? (
              <StyledBox
                label={"Package Condition"}
                value={props?.displayPackageCondition}
                color={packageConditionColorCodes[props.displayPackageCondition]}
              />
            ) : (
              <DropDown
                control={control}
                name={`reports.${index}.packageCondition`}
                options={
                  isFormFetching
                    ? []
                    : packagingConditionOptions(
                        formData?.receivedConditionStatuses
                      )
                }
                isLoading={isFormFetching}
                label={"Package Condition"}
              />
            )}
            {isSubmitted ? (
              <StyledBox label={"Rack number"} value={props?.rackNumber} />
            ) : (
              <TextInput
                control={control}
                name={`reports.${index}.rackNumber`}
                disabled={!isSaved}
                label={"Rack number"}
              />
            )}
          </Box>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default PackageItemCard;

import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

const OrderStatusStepper = ({
  activeStep,
  steps = [],
  handleStepper,
  orderId,
  ...props
}) => {
  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step
          key={index}
          {...(step?.isCompleted && {
            onClick: () => handleStepper(index),
            sx: { cursor: "pointer" },
          })}
          completed={Boolean(step?.isCompleted)}
          sx={{
            position: "relative",
          }}
        >
          <StepLabel>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: 14,
                    md: 16,
                  },
                }}
                fontWeight={600}
                letterSpacing={"0.08px"}
              >
                {step.status}
              </Typography>
              {index === 0 && (
                <Typography fontSize={14} fontWeight={500}>
                  {step.date}
                </Typography>
              )}
            </Box>
          </StepLabel>
          <StepContent sx={{ alignItems: "flex-start" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              {index === 0 && (
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                    <Box display={"flex"} gap={0.5}>
                      <Typography
                        fontSize={13}
                        lineHeight={"18px"}
                        color={"text.secondary"}
                      >
                        Vehicle no
                      </Typography>
                      <Typography
                        fontSize={13}
                        lineHeight={"18px"}
                        fontWeight={500}
                      >
                        {step.vehicle}
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={0.5}>
                      <Typography
                        fontSize={13}
                        lineHeight={"18px"}
                        color={"text.secondary"}
                      >
                        Driver name
                      </Typography>
                      <Typography
                        fontSize={13}
                        lineHeight={"18px"}
                        fontWeight={500}
                      >
                        {step.driver}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderStatusStepper;

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { Fragment, useEffect } from "react";
import IMAGE from "../../assets/banner.png";
import { dateTimeFormatter } from "../../shared/utils";
import { labels } from "../../constants";
import moment from "moment";
import { primaryBackground } from "../../shared/theme/globalTheme";
import { useFetchNotifications } from "../../query-hooks/Notifications/useFetchNotifications";
import { useReadAllNotification } from "../../query-hooks/Notifications/useReadAllNotification";
import { useReadOneNotification } from "../../query-hooks/Notifications/useReadOneNotification";
import { useInView } from "react-intersection-observer";
import { LoadingButton } from "@mui/lab";

const Notifications = ({ hideDrawer }) => {
  const { data, isFetched, hasNextPage, fetchNextPage, refetch } =
    useFetchNotifications();
  const { mutate: readOneNotification } = useReadOneNotification();
  const { mutate: readAllNotifications , isLoading} = useReadAllNotification();

  const { ref: notificationRef, inView: notificationView } = useInView({
    threshold: 0,
  });

  const unreadNotificationsCount = data?.pages[0].unreadCount ?? 0;

  let notifications = data?.pages.flatMap((item) => item.notifications);

  useEffect(() => {
    if (notificationView && hasNextPage) {
      fetchNextPage();
    }
  }, [notificationView, fetchNextPage, hasNextPage]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        sx={{
          padding: {
            xs: 2,
            md: "60px 40px 30px 40px",
          },
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={24} fontWeight={500}>
            {labels.notifications}
          </Typography>
          <IconButton onClick={hideDrawer}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ borderColor: "#B7BEC7" }} />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: "20px",
              whiteSpace: "nowrap",
            }}
          >
            {labels.notificationMsgCount.replace(
              "$COUNT",
              unreadNotificationsCount
            )}
          </Typography>
          {unreadNotificationsCount !== 0 && (
            <Button
              variant="link"
              component={LoadingButton}
              sx={{ textTransform: "none", fontWeight: 600 }}
              disableRipple
              onClick={() => {
                if (unreadNotificationsCount !== 0)
                  readAllNotifications(null, {
                    onSuccess: () => {
                      refetch();
                    },
                  });
              }}
              loading={isLoading}
            >
              {labels.markAllAsRead}
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <Grid container>
          {isFetched &&
            notifications?.map((item) => (
              <Fragment key={item._id}>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    padding={"20px 16px"}
                    gap={2}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      backgroundColor: !item.isRead
                        ? primaryBackground
                        : "transparent",
                    }}
                    onClick={() => {
                      if (!item.isRead)
                        readOneNotification(
                          {
                            notificationId: item._id,
                          },
                          {
                            onSuccess: () => {
                              refetch();
                            },
                          }
                        );
                    }}
                  >
                    <Box display={"flex"}>
                      <img
                        src={IMAGE}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                      <Typography
                        sx={{
                          display: "flex",
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        <p style={{ margin: "unset" }}>
                          {labels.notificationMsgTemplate
                            .replace("$MESSAGE", `${item.message}`)
                            .replace(
                              "$DATE",
                              dateTimeFormatter(item.createdAt)
                            )}
                        </p>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#161616",
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: "18px",
                        }}
                      >
                        {moment(item.createdAt).fromNow()}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: "#B7BEC7" }} />
                </Grid>
              </Fragment>
            ))}
        </Grid>
      </Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={notificationRef}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Notifications;

import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { StorageFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { storageTypesFormValidation } from "./SearchFormValidation";
import { useCustomDialog } from "../../shared/customDialog";
import { useEffect } from "react";
import { useUpdateStorageTypesMutateQuery } from "../../query-hooks/warehouseDashboard/useUpdateStorageTypesMutateQuery";
import { warehouse_labels } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

const StorageOptionForm = (props) => {
  const { hideDialog } = useCustomDialog();
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      [StorageFormKeys.types]: []
    },
    resolver: yupResolver(storageTypesFormValidation)
  });

  const { fields, append } = useFieldArray({
    control,
    name: StorageFormKeys.types
  });

  const { mutate, isError, error, isLoading } =
    useUpdateStorageTypesMutateQuery();

  useEffect(() => {
    reset({ [StorageFormKeys.types]: [] });
    props.storageTypes.forEach((item) => {
      append({ [item.name]: item.rate, ...item });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    const storageTypes = data[StorageFormKeys.types].map((item) => {
      return {
        name: item.name,
        rate: item[item.name]
      };
    });

    mutate(
      {
        partnerId: props.partnerId,
        data: {
          storageTypes
        }
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("storage Price Updated", {
            variant: "success",
          });
          props.refetch();
        }
      }
    );
  };

  return (
    <Box
      sx={{
        padding: "18px 16px"
      }}
    >
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        marginBottom={4}
      >
        {warehouse_labels.storageOptionsAndPriceText}
      </Typography>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {isError && (
            <Grid item xs={12}>
              <Alert severity="error">{error.response.data.message}</Alert>
            </Grid>
          )}
          {fields.map((field, index) => (
            <Grid item xs={6} key={field.id}>
              <TextInput
                control={control}
                name={`${StorageFormKeys.types}.${index}.${field.name}`}
                label={field.displayName}
                placeholder={`Enter in per ${field.rateUnit}`}
              />
            </Grid>
          ))}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="soft"
                sx={{
                  height: 64,
                  mt: "14px"
                }}
                onClick={hideDialog}
              >
                {warehouse_labels.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{
                  height: 64,
                  mt: "14px"
                }}
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              >
                {warehouse_labels.updatePriceButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StorageOptionForm;

import { Backdrop, Box, CircularProgress } from "@mui/material";
import { StorageKeys, UserRoles } from "../../constants/storage-keys";

import DashboardForm from "./DashboardForm";
import Storage from "../../shared/utils/Storage";
import TransportListing from "../../transport/pages/Listings";
import WarehouseListing from "./Listings";
import { useFetchProfileQueryHook } from "../../query-hooks/useFetchProfileQueryHook";
import { useFetchTransportDetailsQuery } from "../../query-hooks/TransportDashboard/useFetchTransportDetailsQuery";
import { useFetchWarehouseDetailsQuery } from "../../query-hooks/warehouseDashboard/useFetchWarehouseDetailsQuery";

const Dashboard = () => {
  const type = Storage.getItem(StorageKeys.dashboardUser);
  const { data, isFetching: isProfileFetching } = useFetchProfileQueryHook();
  const {
    data: warehouseDetail,
    isFetching: isWarehouseFetching,
    isFetched: isWarehouseFetched,
  } = useFetchWarehouseDetailsQuery(
    data?.user?.partner,
    type === UserRoles.warehouseAdmin
  );

  const {
    data: transportDetail,
    isFetching: isTransportFetching,
    isFetched: isTransportFetched,
  } = useFetchTransportDetailsQuery(
    data?.user?.partner,
    type === UserRoles.transportAdmin
  );

  Storage.setItem(
    StorageKeys.PROFILE_DATA,
    type === UserRoles.warehouseAdmin ? warehouseDetail : transportDetail
  );

  return (
    <Box
      sx={{
        padding: {
          xs: 2,
          md: 0,
        },
      }}
    >
      <Backdrop
        sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          (isProfileFetching && isWarehouseFetching) ||
          (isProfileFetching && isTransportFetching)
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {warehouseDetail?.partner?.status?.displayName !== "Created" &&
      type === UserRoles.warehouseAdmin ? (
        <WarehouseListing />
      ) : transportDetail?.partner?.status?.displayName !== "Created" &&
        type === UserRoles.transportAdmin ? (
        <TransportListing />
      ) : (
        (isTransportFetched || isWarehouseFetched) && <DashboardForm />
      )}
    </Box>
  );
};

export default Dashboard;

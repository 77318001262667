import { QueryKeys } from "../../constants/query-keys";
import { fetchVehicles } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchVehicles = (orderId) => {
  const transportOrdersDetails = useQuery(
    [QueryKeys.queryVehicles, orderId],
    () => fetchVehicles(orderId)
  );
  return transportOrdersDetails;
};

import * as yup from "yup";

import { transport_labels, warehouse_labels } from "../../constants";

import { dynamicFormShape } from "../../warehouse/pages/SearchFormValidation";
import { transportSearchFormKeys } from "../../constants/formKeys";

export const searchFormSchema = yup.object({
  [transportSearchFormKeys.transportName]: yup
    .string()
    .required(
      `${transport_labels.transportNameLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.establishedYear]: yup
    .string()
    .required(
      `${transport_labels.establishedYearLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.gstInNumber]: yup
    .string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter a correct GST number"
    )
    .required(
      `${transport_labels.gstinLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.gstRegime]: yup
    .string()
    .required(
      `${transport_labels.gstRegimeLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.transportDescription]: yup
    .string()
    .required(
      `${transport_labels.transportDescriptionLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.transportLocation]: yup
    .string()
    .required(
      `${transport_labels.mapLocationLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.transportCity]: yup
    .string()
    .required(
      `${transport_labels.cityPlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.transportState]: yup
    .string()
    .required(
      `${transport_labels.statePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.transportZipCode]: yup
    .string()
    .required(
      `${transport_labels.zipCodePlaceholderText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.billingAddress]: yup
    .string()
    .required(
      `${transport_labels.billingAddressLabelText} ${warehouse_labels.isRequired}`
    ),

  [transportSearchFormKeys.additionalServices]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 service")
    .required(
      `${transport_labels.additionalServicesTitleText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.additionalServicesFields]: yup
    .array()
    .of(dynamicFormShape)
});

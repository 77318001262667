import { Box } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import PartnerSection from "../components/PartnerSection";
import React from "react";
import { home_labels } from "../../constants";

const Partner = () => {

  return (
    <Box>
      <PartnerSection />
      <ContactUsSection
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </Box>
  );
};

export default Partner;

import { updateAdditionalServices } from "../../transport/actions";
import { useMutation } from "@tanstack/react-query";

export const useUpdateAdditionalServicesMutateQuery = () => {
  const additionalServicesMutate = useMutation(({ partnerId, data }) =>
    updateAdditionalServices(partnerId, data)
  );

  return additionalServicesMutate;
};

import { Box, Button, Divider, Typography } from "@mui/material";

import DateInput from "../../shared/formElements/DateInput";
import React from "react";
import TextInput from "../../shared/formElements/TextInput";
import { useForm } from "react-hook-form";
import { warehouse_labels } from "../../constants";

const MaterialReceivedReportFile = () => {
  const { control } = useForm({
    defaultValues: {}
  });
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"20px"}
      sx={{
        padding: {
          xs: "30px 16px",
          md: 0
        }
      }}
      marginBottom={3}
    >
      <Box
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          display: {
            xs: "none",
            md: "flex"
          }
        }}
      >
        <Typography fontSize={20} fontWeight={500} letterSpacing={"0.04px"}>
          {warehouse_labels.materialReceivedReportText}
        </Typography>
        <Button>Submit</Button>
      </Box>
      <Divider />
      <Box
        display={"flex"}
        flexDirection={"column"}
        border={"0.4px solid #95A1B0"}
        borderRadius={2}
      >
        <Box
          display={"flex"}
          gap={2}
          padding={5}
          borderBottom={"0.4px solid #95A1B0"}
          sx={{
            flexWrap: {
              xs: "wrap",
              md: "nowrap"
            }
          }}
        >
          <TextInput control={control} name="mrrId" label={"MRR ID"} />
          <DateInput
            control={control}
            placeholder={"Date Picker"}
            label={"Received Date"}
            name={"receivedDate"}
          />
          <DateInput
            control={control}
            placeholder={"Time Picker"}
            label={"Received Time"}
            name={"receivedTime"}
            showTimeSelectOnly={true}
            showTimeSelect={true}
            timeIntervals={15}
            format="h:mm aa"
          />
        </Box>
        <Box padding={8} sx={{ background: "#D4E3FF80" }}></Box>
      </Box>
    </Box>
  );
};

export default MaterialReceivedReportFile;

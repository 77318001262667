import { getWarehouseInvoicesFormData } from "../../warehouse/actions/index";
import { QueryKeys } from "../../constants/query-keys";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseInvoiceFormData = (orderId) => {
  const warehouseInvoicesFormData = useQuery(
    [QueryKeys.queryFetchWarehouseInvoicesFormData, orderId],
    () => getWarehouseInvoicesFormData(orderId)
  );

  return warehouseInvoicesFormData;
};

export const quotationTransportForm = [
  {
    label: "Transport Services",
    truckType: "transport_services_truck_type",
    uom: "transport_services_uom",
    quantity: "transport_services_quantity",
    rate: "transport_services_rate",
    total: "transport_services_total"
  },
  {
    label: "Loading",
    truckType: "loading_truck_type",
    uom: "loading_uom",
    quantity: "loading_quantity",
    rate: "loading_rate",
    total: "loading_total"
  },
  {
    label: "Unloading",
    truckType: "unloading_truck_type",
    uom: "unloading_uom",
    quantity: "unloading_quantity",
    rate: "unloading_rate",
    total: "unloading_total"
  },
  {
    label: "Packaging",
    truckType: "packaging_truck_type",
    uom: "packaging_uom",
    quantity: "packaging_quantity",
    rate: "packaging_rate",
    total: "packaging_total"
  },
  {
    label: "Other / Misc.",
    truckType: "other_truckType",
    uom: "other_uom",
    quantity: "other_quantity",
    rate: "other_rate",
    total: "other_total"
  }
];

export const quotation_transport_labels = [
  "Truck type",
  "UOM",
  "Quantity",
  "Rate",
  "Total"
];

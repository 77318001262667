import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: "14px 40px"
  },
  [theme.breakpoints.up("md")]: {
    padding: "22px 40px"
  }
}));

import { Box, Grid, Typography } from "@mui/material";
import { warehouse_labels } from "../../constants";

import { KeyboardArrowRight } from "@mui/icons-material";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const InquiryMobileCard = ({ code, createdAt, ...props }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={"17px"}
      onClick={() => {
        navigate(`/inquiry/active-inquiry/${props._id}`);
      }}
    >
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >{`ID ${code}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >
            {moment(createdAt).format("DD MMMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container marginTop={"3px"}>
        <Grid item xs={6}>
          <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
            <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
              {props?.searchForm?.storageType?.displayName || "--"}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
            >
              {warehouse_labels.apparels}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
            <StyledChip
              label={props.warehouseRfq.partners[0].status.name}
              variant="filled"
            />
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
            >
              {warehouse_labels.status}
            </Typography>
          </Box>
          <KeyboardArrowRight color="primary" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InquiryMobileCard;

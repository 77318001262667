import { QueryKeys } from "../../constants/query-keys";
import { getTransportInquiryData } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportInquiry = (inquiryId) => {
  const transportInquiryQuery = useQuery(
    [QueryKeys.queryTransportInquiry, inquiryId],
    () => getTransportInquiryData(inquiryId),
    {
      enabled: Boolean(inquiryId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return transportInquiryQuery;
};

import { QueryKeys } from "../constants/query-keys";
import { getClosedTransportInquiry } from "../transport/actions";
import { getClosedWarehouseInquiry } from "../warehouse/actions";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchClosedInquiryQuery = (type = "warehouses") => {
  const closedInquiry = useInfiniteQuery(
    [QueryKeys.queryPastInquiry],
    type === "warehouses"
      ? ({ pageParam = 1 }) => getClosedWarehouseInquiry(pageParam)
      : ({ pageParam = 1 }) => getClosedTransportInquiry(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.inquiries.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );

  return closedInquiry;
};

import { addTransporter } from "../../transport/actions";
import { useMutation } from "@tanstack/react-query";

export const useAddTransportMutateQuery = () => {
  const transportMutate = useMutation(({ partnerId, formData }) =>
    addTransporter(partnerId, formData)
  );

  return transportMutate;
};

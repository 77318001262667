import { Box, Grid, styled } from "@mui/material";

import AvailableSpaceCard from "./AvailableSpaceCard";
import ListItemWarehouseCard from "./ListItemWarehouseCard";
import StorageAreaForm from "../pages/StorageAreaForm";
import StorageOptionAndPrice from "./StorageOptionAndPrice";
import StorageOptionForm from "../pages/StorageOptionForm";
import { useCustomDialog } from "../../shared/customDialog";

export const StyledBox = styled(({ children, ...props }) => (
  <Box
    {...props}
    sx={{
      border: "0.6px solid #B7BEC7",
      padding: "20px 20px 23px 20px",
      borderRadius: 2,
      height: "100%",
    }}
  >
    {children}
  </Box>
))``;

const DashboardListItem = (props) => {
  const {
    address,
    name,
    listedAt,
    _id,
    status,
    storageArea,
    storageTypes,
    partnerId,
    refetch,
    heroImage,
  } = props;
  const { showDialog } = useCustomDialog();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ListItemWarehouseCard
          name={name}
          city={address.cluster}
          state={address.city}
          country={address.state}
          listingDate={listedAt}
          id={_id}
          status={status}
          partnerId={partnerId}
          heroImage={heroImage}
        />
      </Grid>
      {status.name === "Active" && (
        <>
          <Grid item xs={12} md={3}>
            <AvailableSpaceCard
              totalStorageSpace={storageArea.total}
              availableSpace={storageArea.available}
              usedUpSpace={
                100 - (Number(storageArea.available) / Number(storageArea.total)) *
                100
              }
              handleAreaEdit={() => {
                showDialog({
                  component: (
                    <StorageAreaForm
                      {...storageArea}
                      partnerId={partnerId}
                      refetch={refetch}
                    />
                  ),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StorageOptionAndPrice
              types={storageTypes}
              handleStorageEdit={() => {
                showDialog({
                  component: (
                    <StorageOptionForm
                      storageTypes={storageTypes}
                      partnerId={partnerId}
                      refetch={refetch}
                    />
                  ),
                });
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DashboardListItem;

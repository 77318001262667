import { QueryKeys } from "../../constants/query-keys";
import { getGatePassFormData } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchGatePassFormData = (retrieveId) => {
  const GatePassFormData = useQuery(
    [QueryKeys.queryFetchGatePassFormData, retrieveId],
    () => getGatePassFormData(retrieveId)
  );

  return GatePassFormData
};

import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";

import { Edit } from "@mui/icons-material";
import { StyledBox } from "./DashboardListItem";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import { warehouse_labels } from "../../constants";

const OptionBox = ({ title, value }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
      <Typography fontSize={14} color="text.secondary" lineHeight={"20px"}>
        {title}
      </Typography>
      <Box display={"flex"} alignItems={"baseline"} gap={"2px"}>
        <Typography fontSize={10} color="text.secondary" lineHeight={"16px"}>
          ₹
        </Typography>
        <Typography
          fontSize={14}
          color="text.primary"
          lineHeight={"16px"}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const StorageOptionAndPrice = ({ types = [], handleStorageEdit }) => {
  return (
    <StyledBox>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={5}
      >
        <StyledResponsiveText
          color="text.secondary"
          desktop={{
            fontSize: 14,
            lineHeight: "20px",
          }}
        >
          {warehouse_labels.storageOptionsAndPriceText}
        </StyledResponsiveText>
        <IconButton size="small" onClick={handleStorageEdit}>
          <Edit color="primary" fontSize="20px" />
        </IconButton>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
        <Grid container spacing={3}>
          {types.map((type, index) => (
            <>
              <Grid item xs={5}>
                <OptionBox
                  title={type.displayName}
                  value={`${type.rate} ${type.rateUnit}`}
                />
              </Grid>
              {Boolean(index % 2) && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Box>
    </StyledBox>
  );
};

export default StorageOptionAndPrice;

import { QueryKeys } from "../../constants/query-keys";
import { fetchWarehouseDetails } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseDetailsQuery = (partnerId, isEnabled = true) => {
  const warehouseDetailQuery = useQuery(
    [QueryKeys.queryWarehouseDetail, partnerId],
    () => fetchWarehouseDetails(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId) && isEnabled,
    }
  );
  return warehouseDetailQuery;
};

import * as yup from "yup";

import { Add, Remove } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { statusKeys, warehouse_labels } from "../../constants";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import DropDown from "../../shared/formElements/DropDown";
import InquiryDetails from "../components/InquiryDetails";
import { LoadingButton } from "@mui/lab";
import RegretForm from "../../shared/UiElements/ReasonForm";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { StyledChip } from "../../shared/UiElements/StatusChip.js";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import TextInput from "../../shared/formElements/TextInput";
import { currencyFormatterOnly } from "../../shared/utils";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { quotation_labels } from "./constant";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchWarehouseInquiry } from "../../query-hooks/warehouseInquiry/useFetchWarehouseInquiry";
import { useFetchWarehouseInquiryQuoteFormData } from "../../query-hooks/warehouseInquiry/useFetchWarehouseInquiryQuoteFormData.js";
import { useFetchWarehouseRegretForm } from "../../query-hooks/warehouseInquiry/useFetchWarehouseRegretForm.js";
import { useRegretWarehouseMutateHook } from "../../query-hooks/warehouseInquiry/useRegretWarehouseMutateHook.js";
import { useSendQuoteMutateHook } from "../../query-hooks/warehouseInquiry/useSendQuoteMutateHook.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

const DisabledTextBox = ({ value }) => (
  <Box
    padding={"12px 16px"}
    borderRadius={"8px"}
    border={"1px solid #F0F2F5"}
    display={"flex"}
    alignItems={"center"}
    gap={2}
    width={"100%"}
    minWidth={182}
  >
    <Typography
      component={"span"}
      fontSize={14}
      color={"text.disabled"}
      lineHeight={"20px"}
    >
      ₹
    </Typography>
    <Typography fontSize={14} color={"text.disabled"} lineHeight={"20px"}>
      {value}
    </Typography>
  </Box>
);

export const StyledTotalBox = styled((props) => (
  <Box
    {...props}
    display={"flex"}
    alignItems={"center"}
    gap={2}
    justifyContent={"space-between"}
    sx={{
      width: {
        xs: "100%",
        md: "auto",
      },
      "& > div": {
        maxWidth: {
          xs: 221,
          md: 182,
        },
      },
    }}
  />
))``;

const StyledButton = styled((props) => (
  <Button
    {...props}
    sx={{
      height: 48,
      whiteSpace: "nowrap",
      fontSize: 15,
      fontWeight: 600,
    }}
  />
))``;

export const SuccessAlert = ({ goBack }) => {
  return (
    <SuccessfulDialog
      text={warehouse_labels.quoteSuccessTitle}
      subText={warehouse_labels.quoteSuccessMessage}
      btn2Text={warehouse_labels.goBackInquiryButtonText}
      btn2Callback={goBack}
    />
  );
};

export const SuccessRegret = ({ btn2Callback }) => {
  return (
    <SuccessfulDialog
      text={warehouse_labels.quoteRegretTitle}
      subText={warehouse_labels.quoteRegretMessage}
      btn2Text={warehouse_labels.closeButtonText}
      btn2Callback={btn2Callback}
      color="warning"
    />
  );
};

const quoteFormSchema = yup
  .object({
    quotes: yup.array().of(
      yup.object().shape({
        rate: yup.string().required("Rate is required"),
        quantity: yup.string().required("Quantity is required"),
      })
    ),
  })
  .required();

const ActiveInquiry = () => {
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const partnerId = Storage.getItem(StorageKeys.PARTNER_ID);

  const { control, handleSubmit, watch, reset, getValues, setValue } = useForm({
    defaultValues: {
      quotes: [],
      subTotal: "",
      total: "",
      gst: "",
    },
    resolver: yupResolver(quoteFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quotes",
  });

  const quotesWatch = watch("quotes");

  const { inquiryId } = useParams();

  const { data, isFetching, isFetched } = useFetchWarehouseInquiry(inquiryId);
  const { data: otherData } = useFetchWarehouseInquiryQuoteFormData(inquiryId);
  const { data: regretFormData } = useFetchWarehouseRegretForm(inquiryId);

  const partner = data?.inquiry?.warehouseRfq.partners?.find(
    (item) => item.partner === partnerId
  );

  const quote = partner?.quote;

  const { mutate, isLoading, isError, error } = useSendQuoteMutateHook();
  const {
    mutate: regretInquiry,
    isLoading: isRegretInquiryLoading,
    isError: isRegretInquiryError,
    error: regretInquiryError,
    reset: resetRegretInquiry,
  } = useRegretWarehouseMutateHook();

  const appendQuoteForm = useCallback(
    (quote) => {
      append({
        label: "Storage Cost",
        uom: quote.services[0].name,
        duration: `${parseFloat(quote.services[0].duration / 30).toFixed(
          2
        )} Months`,
        durationValue: quote.services[0].duration / 30,
        quantity: `${
          quote.services[0].quantity.$numberDecimal
        } ${quote.services[0].rateUnit.toLowerCase()}`,
        quantityValue: quote.services[0].quantity.$numberDecimal,
        rate: quote.services[0].rate.$numberDecimal,
        total: quote.services[0].totalAmount.$numberDecimal,
        options: [
          {
            displayName: quote.services[0].displayName,
            name: quote.services[0].name,
          },
        ],
      });

      quote.additionalServices.forEach((item) => {
        append({
          label: item.displayName,
          uom: `Prices / ${item.rateUnit}`,
          name: item.name,
          duration: `-`,
          quantity: `${item.quantity.$numberDecimal} Kgs`,
          quantityValue: item.quantity.$numberDecimal,
          rate: item.rate.$numberDecimal,
          total: item.totalAmount.$numberDecimal,
          options: [
            {
              displayName: `Prices / ${item.rateUnit}`,
              name: `Prices / ${item.rateUnit}`,
            },
          ],
        });
      });

      if (quote.otherServices.length > 0)
        append({
          label: quote.otherServices[0].displayName,
          uom: quote.otherServices[0].rateUnit,
          name: quote.otherServices[0].name,
          duration: `-`,
          quantity: quote.otherServices[0].quantity.$numberDecimal,
          quantityValue: quote.otherServices[0].quantity.$numberDecimal,
          rate: quote.otherServices[0].rate.$numberDecimal,
          total: quote.otherServices[0].totalAmount.$numberDecimal,
          options: [
            {
              displayName: quote.otherServices[0].rateUnit,
              name: quote.otherServices[0].rateUnit,
            },
          ],
        });
    },
    [append]
  );

  useEffect(() => {
    if (isFetched) {
      if (quote) {
        appendQuoteForm(quote);
      }
    }
  }, [appendQuoteForm, isFetched, quote]);

  useEffect(() => {
    if (quotesWatch) {
      quotesWatch.forEach((item, index) => {
        const rate = item.rate;
        const quantity =
          item.name === "Other" ? item.quantity : item.quantityValue;
        index === 0
          ? setValue(
              `quotes.${index}.total`,
              parseFloat((+item.durationValue * +rate * +quantity)).toFixed(2)
            )
          : index > 0 && item.name !== "Other"
          ? setValue(
              `quotes.${index}.total`,
              parseFloat((+rate * +quantity) / 100).toFixed(0)
            )
          : setValue(
              `quotes.${index}.total`,
              parseFloat(+rate * +quantity).toFixed(0)
            );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(quotesWatch)]);

  const addOtherData = () => {
    const otherQuote = otherData.otherServices[0];
    append({
      label: otherQuote.displayName,
      uom: otherQuote.rateUnit,
      name: otherQuote.name,
      duration: "-",
      quantity: "",
      rate: "",
      total: "",
      options: [
        {
          displayName: otherQuote.rateUnit,
          name: otherQuote.rateUnit,
        },
      ],
    });
  };

  const handleQuote = (data) => {
    mutate(
      {
        inquiryId,
        data: {
          quote: {
            services: [
              {
                name: data.quotes[0].uom,
                rate: data.quotes[0].rate,
              },
            ],
            additionalServices: data.quotes
              .filter((item, index) => index > 0 && item.name !== "Other")
              .map((item) => {
                return {
                  name: item.name,
                  rate: item.rate,
                };
              }),

            otherServices: data.quotes
              .filter((item) => item.name === "Other")
              .map((item) => {
                return {
                  name: item.name,
                  rate: item.rate,
                  quantity: item.quantity,
                };
              }),
          },
        },
      },
      {
        onSuccess: () => {
          if (isMobile) {
            showDrawer({
              component: (
                <SuccessAlert
                  goBack={() => {
                    hideDrawer();
                    navigate("/inquiry");
                  }}
                />
              ),
              closeIcon: false,
            });
          } else {
            showDialog({
              component: (
                <SuccessAlert
                  hideDialog={hideDialog}
                  goBack={() => {
                    hideDialog();
                    navigate("/inquiry");
                  }}
                />
              ),
              backdropOff: true,
            });
          }
        },
      }
    );
  };

  const handleRegret = (data, openDialog, closeDialog) => {
    regretInquiry(
      {
        inquiryId,
        data: {
          reason: data.regretReason,
          ...{
            ...(data.otherReason
              ? { otherReason: data.otherReason }
              : {}),
          },
        },
      },
      {
        onSuccess: () => {
          openDialog({
            component: (
              <SuccessRegret
                btn2Callback={() => {
                  closeDialog();
                  navigate("/inquiry");
                }}
              />
            ),
          });
        },
      }
    );
  };

  const subTotal = parseFloat(
    getValues("quotes")?.reduce(function (previousTotal, currentTotal) {
      return previousTotal + +currentTotal.total;
    }, 0)
  ).toFixed(2);

  const gst = parseFloat(subTotal * 0.18).toFixed(2);

  useEffect(() => {
    if (isError || isRegretInquiryError) {
      enqueueSnackbar(
        error?.response.data.message ||
          regretInquiryError?.response.data.message,
        { variant: "error" }
      );
      if (isRegretInquiryError) {
        resetRegretInquiry();
      }
    }
  }, [
    error?.response.data.message,
    regretInquiryError?.response.data.message,
    isError,
    isRegretInquiryError,
    resetRegretInquiry
  ]);

  return isFetching ? (
    <Backdrop
      sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      sx={{
        padding: {
          xs: "24px 16px",
          sm: 0,
        },
      }}
      marginBottom={2}
    >
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        sx={{
          marginBottom: {
            xs: 3,
            md: "15px",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 20,
              },
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.002em",
            }}
            gutterBottom
          >
            {`${warehouse_labels.inquiryIdTitleText} : ${data.inquiry.code}`}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
            }}
            color={"text.secondary"}
          >
            {`${warehouse_labels.inquiryDateTitleText} : ${moment(
              data.inquiry.createdAt
            ).format("DD MMMM YYYY")}`}
          </Typography>
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          <StyledChip label={partner?.status?.name} />
        </Box>
      </Box>
      <Box marginBottom={5}>
        <InquiryDetails
          searchForm={data.inquiry.searchForm}
          warehouseRfq={data.inquiry.warehouseRfq}
          status={partner?.status?.name}
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={3} marginBottom={3}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0.005em",
          }}
        >
          {warehouse_labels.reviseQuotationTitleText}
        </Typography>
        <Box sx={{ overflowX: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2} flexWrap={"nowrap"}>
              <Grid
                item
                xs={2}
                minWidth={140}
                sx={{
                  background: {
                    xs: "#F0F2F5",
                    md: "transparent",
                  },
                }}
              />
              {quotation_labels.map((item, index) => (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    alignItems={"flex-end"}
                    minWidth={140}
                  >
                    <Typography fontSize={14} fontWeight={500}>
                      {item}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
            {fields.map((item, index) => (
              <Grid
                item
                xs={12}
                container
                spacing={2}
                key={item.id}
                flexWrap={"nowrap"}
              >
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  alignItems={"center"}
                  minWidth={140}
                  sx={{
                    background: {
                      xs: "#F0F2F5",
                      md: "transparent",
                    },
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    sx={{
                      paddingLeft: {
                        xs: "15px",
                        md: 0,
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <DropDown
                    control={control}
                    name={`quotes.${index}.uom`}
                    options={item.options}
                    disabled
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`quotes.${index}.duration`}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`quotes.${index}.quantity`}
                    type={item.quantity === "" ? "number" : "text"}
                    disabled={
                      partner?.status?.name === statusKeys.Pending &&
                      index === quote.additionalServices.length + 1
                        ? false
                        : true
                    }
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    startIcon={
                      <Box padding={"0 0 0 16px"} fontSize={14}>
                        ₹
                      </Box>
                    }
                    control={control}
                    name={`quotes.${index}.rate`}
                    type="number"
                    disabled={partner?.status?.name !== statusKeys.Pending}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <DisabledTextBox
                    value={currencyFormatterOnly(
                      getValues(`quotes.${index}.total`)
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider
          sx={{
            borderColor: "#B7BEC7",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        />
        <Box
          display={"flex"}
          justifyContent={
            partner?.status?.name !== statusKeys.Pending ||
            quote.otherServices.length > 0
              ? "flex-end"
              : "space-between"
          }
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: "flex-start",
          }}
          gap={3}
        >
          {partner?.status?.name === statusKeys.Pending &&
            quote.otherServices.length < 1 && (
              <Box>
                {fields.length === quote.additionalServices.length + 1 ? (
                  <Button
                    variant="text"
                    startIcon={<Add />}
                    onClick={addOtherData}
                  >
                    {warehouse_labels.addMoreItemsButtonText}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    startIcon={<Remove />}
                    color="error"
                    onClick={() => remove(fields.length - 1)}
                  >
                    {`Remove ${otherData?.otherServices?.[0].displayName}`}
                  </Button>
                )}
              </Box>
            )}
          <Divider
            sx={{
              borderColor: "#B7BEC7",
              width: "100%",
              display: {
                xs: "block",
                md: "none",
              },
            }}
          />
          <Box display={"flex"} flexDirection={"column"} gap={"9px"}>
            <StyledTotalBox>
              <Typography fontSize={14} fontWeight={700} whiteSpace={"nowrap"}>
                {warehouse_labels.updatedAmountText}
              </Typography>
              <DisabledTextBox value={currencyFormatterOnly(subTotal)} />
            </StyledTotalBox>
            <StyledTotalBox>
              <Typography fontSize={14} fontWeight={700} whiteSpace={"nowrap"}>
                {warehouse_labels.gstLabel}
              </Typography>
              <DisabledTextBox value={currencyFormatterOnly(gst)} />
            </StyledTotalBox>
            <StyledTotalBox>
              <Typography fontSize={14} fontWeight={700} whiteSpace={"nowrap"}>
                {warehouse_labels.updatedTotalLabel}
              </Typography>
              <DisabledTextBox
                value={currencyFormatterOnly(
                  parseFloat(+subTotal + +gst).toFixed(2)
                )}
              />
            </StyledTotalBox>
          </Box>
        </Box>
      </Box>

      {partner?.status?.name === statusKeys.Pending && (
        <>
          <Divider
            sx={{
              borderColor: "#B7BEC7",
              width: "100%",
              marginBottom: "14px",
            }}
          />
          <Box
            display={"flex"}
            gap={"10px"}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
            justifyContent={"flex-end"}
          >
            <StyledButton
              variant="softError"
              sx={{
                width: {
                  xs: "100%",
                  md: 140,
                },
              }}
              onClick={() => {
                if (isMobile) {
                  showDrawer({
                    component: (
                      <RegretForm
                        title={warehouse_labels.regretInquiryTitleText}
                        subText={warehouse_labels.regretInquirySubText}
                        btn1Text={warehouse_labels.yesRegretButtonText}
                        btn2Text={warehouse_labels.cancelButtonText}
                        btn1Callback={(data) =>
                          handleRegret(data, showDrawer, hideDrawer)
                        }
                        btn2Callback={hideDrawer}
                        reasons={regretFormData.reasons}
                        isBtn1Loading={isRegretInquiryLoading}
                      />
                    ),
                    closeIcon: false,
                  });
                } else {
                  showDialog({
                    component: (
                      <RegretForm
                        title={warehouse_labels.regretInquiryTitleText}
                        subText={warehouse_labels.regretInquirySubText}
                        btn1Text={warehouse_labels.yesRegretButtonText}
                        btn2Text={warehouse_labels.cancelButtonText}
                        btn1Callback={(data) =>
                          handleRegret(data, showDialog, hideDialog)
                        }
                        btn2Callback={hideDialog}
                        reasons={regretFormData.reasons}
                        isBtn1Loading={isRegretInquiryLoading}
                      />
                    ),
                    backdropOff: true,
                  });
                }
              }}
            >
              {warehouse_labels.regretButtonText}
            </StyledButton>
            <StyledButton
              variant="soft"
              sx={{
                width: {
                  xs: "100%",
                  md: 140,
                },
              }}
              onClick={() => {
                reset();
                appendQuoteForm(quote);
              }}
            >
              {warehouse_labels.resetChangesButtonText}
            </StyledButton>
            <StyledButton
              component={LoadingButton}
              variant="contained"
              sx={{
                width: {
                  xs: "100%",
                  md: 182,
                },
              }}
              onClick={handleSubmit(handleQuote)}
              loading={isLoading}
            >
              {warehouse_labels.sendQuoteButtonText}
            </StyledButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ActiveInquiry;

import React, { useState } from "react";
import { home_labels } from "../../constants";
import { Box, FormHelperText, Typography } from "@mui/material";
import TextInput from "../../shared/formElements/TextInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import { ForgotPasswordKeys } from "../../constants/formKeys";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
// import { Auth } from "aws-amplify";
import { LoadingButton } from "@mui/lab";

const StyledBox = styled((props) => (
  <Box
    {...props}
    sx={{
      padding: {
        xs: "25px 20px 16px 20px",
        md: 5
      },
      boxShadow: {
        xs: "0px 8px 24px rgba(21, 21, 22, 0.1)",
        md: "0px 8px 24px rgba(21, 21, 22, 0.04)"
      },
      background: "#FFFFFF",
      maxWidth: {
        xs: "100%",
        md: 460
      },
      height: {
        xs: 400,
        md: 400
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}
  />
))(() => ({}));

const StyledButton = styled((props) => (
  <LoadingButton
    {...props}
    sx={{
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.3px",
      height: { xs: "40px", md: "64px" }
    }}
    variant="contained"
    type="submit"
  />
))(() => ({}));

const FormHeader = ({ title }) => (
  <Box
    sx={{
      marginBottom: {
        xs: "20px",
        md: 3
      }
    }}
  >
    <Typography
      sx={{
        fontSize: {
          xs: 16,
          md: 18
        },
        lineHeight: {
          xs: "19px",
          md: "24px"
        }
      }}
      component={"span"}
      fontWeight={400}
      letterSpacing={"0.002em"}
    >
      {title}
    </Typography>
  </Box>
);

const EmailSection = ({ error, setActiveStep, setError, setUsername }) => {
  const emailSchema = yup.object({
    [ForgotPasswordKeys.emailId]: yup
      .string()
      .email("Enter a valid email")
      .required("Please enter email")
  });
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(emailSchema)
  });

  const checkUser = async (data) => {
    setActiveStep(2);
    console.log("email");
    // setLoading(true);
    // try {
    //   // Check if the user exists
    //   await Auth.signIn(data[ForgotPasswordKeys.emailId]);

    //   // If the user exists, initiate the forgot password process
    //   await Auth.forgotPassword(data[ForgotPasswordKeys.emailId]);
    //   console.log("Forgot password request sent successfully");
    //   setUsername(data[ForgotPasswordKeys.emailId]);
    //   setActiveStep(2);
    //   setLoading(false);
    // } catch (error) {
    //   setError(error);
    //   setLoading(false);
    // }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={8}
      component={"form"}
      onSubmit={handleSubmit(checkUser)}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <FormHeader title={home_labels.provideEmailTitle} />
        <TextInput
          control={control}
          name={ForgotPasswordKeys.emailId}
          placeholder={"Enter Registered emailId"}
        />
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "18px",
            color: "text.title",
            textAlign: "center"
          }}
        >
          {home_labels.confirmationCode}
        </Typography>
        {error && (
          <FormHelperText error sx={{ textAlign: "center" }}>
            {error.message}
          </FormHelperText>
        )}
      </Box>
      <StyledButton onClick={handleSubmit(checkUser)} loading={loading}>
        {"Send Code"}
      </StyledButton>
    </Box>
  );
};

const CodeSection = ({
  error,
  setActiveStep,
  setError,
  setConfirmationCode,
  username
}) => {
  const resetCodeSchema = yup.object({
    [ForgotPasswordKeys.resetCode]: yup
      .string()
      .min(6, "Please enter a valid 6 digit Reset Code")
      .required("Enter Reset Code Sent to Mail")
  });

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(resetCodeSchema)
  });

  const checkResetCode = async (data) => {
    setActiveStep(3);
    console.log("code");
    // setLoading(true);
    // try {
    //   await Auth.forgotPasswordSubmit(
    //     username,
    //     data[ForgotPasswordKeys.resetCode]
    //   );
    //   console.log("Confirmation code is correct");
    //   setConfirmationCode(data[ForgotPasswordKeys.resetCode]);
    //   setActiveStep(3);
    //   setLoading(false);
    //   // Add any additional logic you need after confirming the code
    // } catch (error) {
    //   setError(error);
    //   setLoading(false);
    // }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={8}
      component={"form"}
      onSubmit={handleSubmit(checkResetCode)}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <FormHeader title={home_labels.confirmCodeTitle} />
        <TextInput
          control={control}
          name={ForgotPasswordKeys.resetCode}
          placeholder={"Confirmation Code"}
        />
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "18px",
            color: "text.title",
            textAlign: "center"
          }}
        >
          {home_labels.validationOfCode}
        </Typography>
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </Box>
      <StyledButton onClick={handleSubmit(checkResetCode)} loading={loading}>
        {"Confirm Code"}
      </StyledButton>
    </Box>
  );
};

const NewPasswordSection = ({ setActiveStep, username, confirmationCode }) => {
  const newPasswordSchema = yup.object({
    [ForgotPasswordKeys.newPassword]: yup
      .string()
      .required("Enter New Password")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
    [ForgotPasswordKeys.confirmPassword]: yup
      .string()
      .oneOf(
        [yup.ref(ForgotPasswordKeys.newPassword), null],
        "Passwords must match"
      )
      .required("Please Confirm New Password")
  });

  const [loading, setLoading] = useState(false);

  const { control, setValue, watch, handleSubmit } = useForm({
    resolver: yupResolver(newPasswordSchema)
  });

  const togglePassword = watch("showPassword");

  const changePassword = (data) => {
    setActiveStep(0);
    console.log("Password");
    // setLoading(true);
    // try {
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    // }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1} component={"form"}>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <FormHeader title={home_labels.setNewPassword} />
        <TextInput
          control={control}
          name={ForgotPasswordKeys.newPassword}
          label={"New Password"}
          type={!togglePassword?.includes("showPassword") ? "password" : "text"}
        />
        <TextInput
          control={control}
          name={ForgotPasswordKeys.confirmPassword}
          label={"Confirm New Password"}
          type={!togglePassword?.includes("showPassword") ? "password" : "text"}
        />
        <CheckBoxGroup
          control={control}
          name={"showPassword"}
          options={[{ name: "showPassword", displayName: "Show password" }]}
          setValue={setValue}
        />
      </Box>
      <StyledButton onClick={handleSubmit(changePassword)} loading={loading}>
        {"Submit"}
      </StyledButton>
    </Box>
  );
};

const ForgotPasswordForm = ({ activeStep, setActiveStep }) => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  return (
    <StyledBox>
      {activeStep === 1 && (
        <EmailSection
          error={error}
          setError={setError}
          setUsername={setUsername}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 2 && (
        <CodeSection
          error={error}
          setError={setError}
          username={username}
          setConfirmationCode={setConfirmationCode}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 3 && (
        <NewPasswordSection
          setActiveStep={setActiveStep}
          username={username}
          confirmationCode={confirmationCode}
        />
      )}
    </StyledBox>
  );
};

export default ForgotPasswordForm;

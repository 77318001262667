import { uploadMrr } from "../../warehouse/actions";
import { useMutation } from "@tanstack/react-query";

export const useUploadMrr = () => {
  const uploadMrrQuery = useMutation(({ orderId, data }) =>
    uploadMrr(orderId, data)
  );

  return uploadMrrQuery;
};

import { Box, ImageList, ImageListItem, Typography } from "@mui/material";

const ImageGrid = ({ imageList }) => {
  const remainingImages = imageList?.length - 5;
  const singleImageString = remainingImages === 1 ? "image" : "images";
  return (
    <ImageList variant="quilted" cols={4} rowHeight={179} gap={16}>
      {imageList?.map(
        (item, index) =>
          index < 5 && (
            <ImageListItem
              key={item.img + index}
              cols={item.cols || 1}
              rows={item.rows || 1}
              sx={{
                borderRadius: 2,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                src={item.img}
                alt={item.title}
                loading="lazy"
                style={{
                  borderRadius: 8,
                  filter: index === 4 && remainingImages > 0 ? "blur(7px" : "",
                }}
              />
              {index === 4 && remainingImages > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={16}
                    lineHeight={"19.36px"}
                    letterSpacing={"0.005em"}
                    color={"white"}
                  >
                    {`+${remainingImages} ${singleImageString}`}
                  </Typography>
                </Box>
              )}
            </ImageListItem>
          )
      )}
    </ImageList>
  );
};

export default ImageGrid;

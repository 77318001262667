import { QueryKeys } from "../../constants/query-keys";
import { fetchWarehouseOrderDetails } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseOrderDetailsQuery = (orderId) => {
  const warehouseOrdersDetails = useQuery(
    [QueryKeys.queryWarehouseOrderDetails, orderId],
    () => fetchWarehouseOrderDetails(orderId)
  );
  return warehouseOrdersDetails;
};

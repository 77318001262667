import { assignDriver } from "../../transport/actions";
import { useMutation } from "@tanstack/react-query";

export const useAssignDriverMutateQuery = () => {
  const assignDriverMutate = useMutation(({ orderId, formData }) =>
    assignDriver(orderId, formData)
  );

  return assignDriverMutate;
};

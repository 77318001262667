import { addWarehouse } from "../../warehouse/actions";
import { useMutation } from "@tanstack/react-query";

export const useAddWarehouseMutateQuery = () => {
  const warehouseMutate = useMutation(({ partnerId, formData }) =>
    addWarehouse(partnerId, formData)
  );

  return warehouseMutate;
};

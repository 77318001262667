import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Box, Button, Typography } from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";

const PdfAndImageUpload = ({ control, name, defaultValue = "" }) => {
  const [filePreview, setFilePreview] = useState(null);

  const handleFileChange = (file) => {
    if (file) {
      if (file.type.startsWith("image/") || file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setFilePreview(null);
      }
    } else {
      setFilePreview(null);
    }
  };

  const onDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    handleFileChange(file);
  };

  return (
    <>
      {filePreview ? (
        <Box
          sx={{
            padding: { xs: "10px 10px", md: 0 },
            background: "#D4E3FF80",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Box overflow={"auto"}>
            {filePreview.startsWith("data:image") ? (
              <img
                src={filePreview}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            ) : (
              <embed
                src={filePreview}
                type="application/pdf"
                style={{ width: "100%", height: "500px" }}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "68px 40px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            width: "100%"
          }}
          onDrop={onDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <Box maxWidth={560}>
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              rules={{ required: "File is required" }}
              render={({ field: { value, onChange } }) => (
                <>
                  <input
                    value={value?.fileName}
                    id="fileInput"
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      onChange(e.target.files[0]);
                      handleFileChange(e.target.files[0]);
                    }}
                  />
                  <CloudUploadOutlined />
                  <Typography fontSize={14} lineHeight={"20px"}>
                    Drag & drop an Image or Pdf file here or,
                  </Typography>
                  <label htmlFor="fileInput">
                    <Button
                      variant="link"
                      disableRipple
                      sx={{ fontWeight: 600 }}
                      component={"span"}
                    >
                      Browse file
                    </Button>
                  </label>
                </>
              )}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PdfAndImageUpload;

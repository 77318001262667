import { QueryKeys } from "../../constants/query-keys";
import { getTransportInquiryQuoteFormData } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportInquiryQuoteFormData = (inquiryId) => {
  const transportInquiryQuoteFormQuery = useQuery(
    [QueryKeys.queryTransportInquiryQuoteForm, inquiryId],
    () => getTransportInquiryQuoteFormData(inquiryId),
    {
      enabled: Boolean(inquiryId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return transportInquiryQuoteFormQuery;
};

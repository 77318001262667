import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import {
  StyledBoxContainer,
  StyledFormTitle,
} from "../../warehouse/pages/SearchForm";
import { regimeRadioGroup, transport_labels } from "../../constants";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import AutoCompleteMapField from "../../shared/formElements/AutoCompleteMapField";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import DateInput from "../../shared/formElements/DateInput";
import { LoadingButton } from "@mui/lab";
import RadioButtonGroup from "../../shared/formElements/RadioButtonGroup";
import { StyledButton } from "../../shared/formElements/StyledButton";
import TextArea from "../../shared/formElements/TextArea";
import TextInput from "../../shared/formElements/TextInput";
import TruckDetailCard from "../components/TruckDetailCard";
import WarehouseSearchFormBg from "../../assets/search-form.png";
import { isMobileDevice } from "../../App";
import { searchFormSchema } from "./SearchFormValidation";
import { transportSearchFormKeys } from "../../constants/formKeys";
import { useAddTransportMutateQuery } from "../../query-hooks/TransportDashboard/useAddTransportMutateQuery";
import { useFetchProfileQueryHook } from "../../query-hooks/useFetchProfileQueryHook";
import { useFetchTransportFormDataQueryHook } from "../../query-hooks/TransportDashboard/useFetchTransportFormDataQueryHook";
import { yupResolver } from "@hookform/resolvers/yup";
import AddVehicleForm from "../components/AddVehicleForm";

export const SearchForm = () => {
  const [truckDetails, setTruckDetails] = useState([]);
  const [showVehicleForm, setShowVehicleForm] = useState(true);
  const [editVehicleData, setEditVehicleData] = useState(null);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [transportSearchFormKeys.additionalServices]: [],
      [transportSearchFormKeys.additionalServicesFields]: [],
    },
    resolver: yupResolver(searchFormSchema),
  });

  const {
    fields: additionalServicesFields,
    append: appendAdditionalService,
    remove: removeAdditionalService,
  } = useFieldArray({
    control,
    name: transportSearchFormKeys.additionalServicesFields,
  });

  const { data: profileData } = useFetchProfileQueryHook();

  const { data: formData } = useFetchTransportFormDataQueryHook(
    profileData?.user?.partner
  );

  const { mutate, isError, error, isLoading } = useAddTransportMutateQuery();

  const additionalServicesWatch = watch(
    transportSearchFormKeys.additionalServices
  );

  const isUseSameBillingAddress = watch(
    transportSearchFormKeys.useSameBillingAddress
  )?.[0];

  const transportLocation = watch(transportSearchFormKeys.transportLocation);

  useEffect(() => {
    if (isUseSameBillingAddress) {
      setValue(transportSearchFormKeys.billingAddress, transportLocation);
    } else {
      setValue(transportSearchFormKeys.billingAddress, "");
    }
  }, [isUseSameBillingAddress, setValue, transportLocation]);

  useEffect(() => {
    additionalServicesWatch?.forEach((item) => {
      let storageFormData = formData?.additionalServices.find(
        (service) => service.name === item
      );
      let existingField = additionalServicesFields.find(
        (field) => field.name === item
      );
      if (!existingField) {
        appendAdditionalService({
          [item]: "",
          name: storageFormData.name,
          displayName: storageFormData.displayName,
          rateUnit: storageFormData.rateUnit,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalServicesWatch]);

  const handleTruckRemove = (index) => {
    const updatedTrucks = truckDetails.filter(
      (item, itemIndex) => itemIndex !== index
    );
    setTruckDetails(updatedTrucks);
  };

  const handleEditClick = (item, index) => {
    setShowVehicleForm(true);
    setEditVehicleData(item);
    handleTruckRemove(index);
  };

  const addAnotherTruckClick = () => {
    setEditVehicleData(null);
    setShowVehicleForm(true);
  };

  const onSubmit = (data) => {
    const transportFormData = new FormData();

    transportFormData.append(
      "name",
      data[transportSearchFormKeys.transportName]
    );
    transportFormData.append(
      "establishedYear",
      new Date(data[transportSearchFormKeys.establishedYear]).getFullYear()
    );
    transportFormData.append(
      "gstNo",
      data[transportSearchFormKeys.gstInNumber]
    );
    transportFormData.append(
      "gstRegime",
      data[transportSearchFormKeys.gstRegime]
    );
    transportFormData.append(
      "description",
      data[transportSearchFormKeys.transportDescription]
    );
    transportFormData.append(
      "address[area]",
      data[transportSearchFormKeys.transportLocation]
    );
    transportFormData.append(
      "address[state]",
      data[transportSearchFormKeys.transportState]
    );
    transportFormData.append(
      "address[city]",
      data[transportSearchFormKeys.transportCity]
    );
    transportFormData.append(
      "address[pincode]",
      data[transportSearchFormKeys.transportZipCode]
    );
    transportFormData.append(
      "address[location][coordinates][]",
      data[transportSearchFormKeys.lat]
    );
    transportFormData.append(
      "address[location][coordinates][]",
      data[transportSearchFormKeys.lng]
    );
    transportFormData.append(
      "billingAddress[sameAsAddress]",
      data[transportSearchFormKeys.useSameBillingAddress][0] === "yes"
    );

    if (data[transportSearchFormKeys.useSameBillingAddress][0] !== "yes") {
      transportFormData.append(
        "billingAddress[area]",
        data[transportSearchFormKeys.billingAddress]
      );
      transportFormData.append(
        "billingAddress[city]",
        data[transportSearchFormKeys.transportCity]
      );
      transportFormData.append(
        "billingAddress[state]",
        data[transportSearchFormKeys.transportState]
      );
      transportFormData.append(
        "billingAddress[pincode]",
        +data[transportSearchFormKeys.transportZipCode]
      );
    }

    truckDetails.forEach((truck, index) => {
      transportFormData.append(`vehicles[${index}][regNo]`, truck.truckNumber);
      transportFormData.append(`vehicles[${index}][model]`, truck.truckModel);
      transportFormData.append(`vehicles[${index}][rate]`, truck.pricePerKm);
    });

    for (let i = 0; i < truckDetails.length; i++) {
      transportFormData.append(`files`, truckDetails[i].truckImage);
    }
    data[transportSearchFormKeys.additionalServicesFields]?.forEach(
      (item, index) => {
        transportFormData.append(
          `additionalServices[${index}][name]`,
          item.name
        );
        transportFormData.append(
          `additionalServices[${index}][rate]`,
          item[item.name]
        );
      }
    );

    mutate(
      {
        partnerId: profileData?.user?.partner,
        formData: transportFormData,
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.data.errors, error?.response.data.message, isError]);

  useEffect(() => {
    if (truckDetails.length === 0 && editVehicleData !== null) {
      setEditVehicleData(null);
      setShowVehicleForm(true);
    }
    else 
    if (truckDetails.length === 0) {
      setEditVehicleData(null);
      setShowVehicleForm(true);
    }
  }, [truckDetails.length,editVehicleData]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      sx={{
        padding: {
          xs: "40px 16px",
          sm: "initial",
        },
        paddingBottom: {
          sm: 2,
        },
        mt: {
          md: 7,
        },
        "& > form": {
          width: "100%",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <img
              src={WarehouseSearchFormBg}
              alt="warehouse"
              style={{
                position: "sticky",
                top: 152,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledFormTitle>
                  {transport_labels.transportDetailsTitleText}
                </StyledFormTitle>
              </Grid>
              <Grid item xs={12} marginBottom={"4px"}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  name={transportSearchFormKeys.transportName}
                  label={transport_labels.transportNameLabelText}
                  placeholder={transport_labels.transportNamePlaceholderText}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: 0,
                  },
                }}
              >
                <DateInput
                  control={control}
                  name={transportSearchFormKeys.establishedYear}
                  label={transport_labels.establishedYearLabelText}
                  placeholder={transport_labels.establishedYearPlaceholderText}
                  minDate={"1900-01-01"}
                  showYearPicker={true}
                  format="yyyy"
                />
              </Grid>
              <Grid item xs={12} marginTop={1}>
                <AutoCompleteMapField
                  control={control}
                  name={transportSearchFormKeys.transportLocation}
                  label={transport_labels.mapLocationLabelText}
                  placeholder={transport_labels.mapLocationPlaceholderText}
                  reset={(address, location) => {
                    setValue(
                      transportSearchFormKeys.transportLocation,
                      address
                    );
                    setValue(transportSearchFormKeys.lat, location.lat);
                    setValue(transportSearchFormKeys.lng, location.lng);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInput
                  control={control}
                  name={transportSearchFormKeys.transportCity}
                  placeholder={transport_labels.cityPlaceholderText}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInput
                  control={control}
                  name={transportSearchFormKeys.transportState}
                  placeholder={transport_labels.statePlaceholderText}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInput
                  control={control}
                  name={transportSearchFormKeys.transportZipCode}
                  placeholder={transport_labels.zipCodePlaceholderText}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    "& label > span > span, & label > span > span::before": {
                      height: {
                        md: 16,
                      },
                      width: { md: 16 },
                    },
                    "& label > span:last-child": {
                      fontSize: { md: 12 },
                      lineHeight: "16px",
                    },
                  }}
                >
                  <CheckBoxGroup
                    control={control}
                    name={transportSearchFormKeys.useSameBillingAddress}
                    options={[
                      {
                        name: "yes",
                        displayName:
                          transport_labels.sameBillingAddressLabelText,
                      },
                    ]}
                    setValue={setValue}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: 0,
                  },
                }}
              >
                <TextInput
                  control={control}
                  name={transportSearchFormKeys.billingAddress}
                  label={transport_labels.billingAddressLabelText}
                  placeholder={transport_labels.billingAddressPlaceholderText}
                  disabled={isUseSameBillingAddress}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5.5}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: "12px",
                  },
                }}
              >
                <TextInput
                  control={control}
                  name={transportSearchFormKeys.gstInNumber}
                  label={transport_labels.gstinLabelText}
                  placeholder={transport_labels.gstinPlaceholderText}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6.5}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: "12px",
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    paddingLeft: {
                      xs: 0,
                      md: 1,
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Typography fontSize={14} lineHeight={"20px"}>
                      {transport_labels.gstRegimeLabelText}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      "& .MuiFormControlLabel-root": { marginLeft: "auto" },
                    }}
                  >
                    <RadioButtonGroup
                      control={control}
                      name={transportSearchFormKeys.gstRegime}
                      options={regimeRadioGroup}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: "12px",
                  },
                }}
              >
                <TextArea
                  control={control}
                  name={transportSearchFormKeys.transportDescription}
                  label={transport_labels.transportDescriptionLabelText}
                  placeholder={
                    transport_labels.transportDescriptionPlaceholderText
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: 3,
                  },
                }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <StyledFormTitle>
                  {transport_labels.addTruckDetailLabelText}
                </StyledFormTitle>
                <Button
                  variant="text"
                  onClick={addAnotherTruckClick}
                  disabled={truckDetails.length === 0 || showVehicleForm}
                >
                  {transport_labels.addAnotherTruckButtonText}
                </Button>
              </Grid>
              <Grid item xs={12} marginBottom={1}>
                <Divider />
              </Grid>

              {showVehicleForm && (
                <Grid item xs={12} paddingLeft={2}>
                  <AddVehicleForm
                    formData={formData}
                    setTruckDetails={setTruckDetails}
                    setShowVehicleForm={setShowVehicleForm}
                    showRemove={truckDetails.length > 0}
                    editVehicleData={editVehicleData}
                    showDefaultValues={Boolean(editVehicleData)}
                  />
                </Grid>
              )}

              {truckDetails.map((item, index) => (
                <Grid item xs={12} key={item.truckModel}>
                  <TruckDetailCard
                    imageSrc={item.truckImage}
                    pricePerKm={item.pricePerKm}
                    truckMake={item.truckMake}
                    truckModel={item.truckModel}
                    truckNumber={item.truckNumber}
                    onEdit={() => {
                      handleEditClick(item, index);
                    }}
                    onDelete={() => {
                      handleTruckRemove(index);
                    }}
                  />
                </Grid>
              ))}
              {truckDetails.length < 1 && (
                <Grid item xs={12}>
                  <FormHelperText error>
                    Please add at least 1 truck
                  </FormHelperText>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  mt: {
                    xs: 1,
                    md: 3,
                  },
                }}
              >
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: {
                    xs: 1,
                    md: 3,
                  },
                }}
              >
                <CheckBoxGroup
                  label={transport_labels.additionalServicesTitleText}
                  control={control}
                  name={transportSearchFormKeys.additionalServices}
                  errors={errors}
                  options={formData?.additionalServices}
                  setValue={setValue}
                  onRemove={(value, index) => removeAdditionalService(index)}
                />
              </Grid>
              {additionalServicesFields.length > 0 && (
                <Grid item xs={12}>
                  <StyledBoxContainer>
                    <Grid container spacing={"12px"}>
                      {additionalServicesFields.map((item, index) => (
                        <Grid item xs={12} md={4} key={item}>
                          <TextInput
                            control={control}
                            name={`${transportSearchFormKeys.additionalServicesFields}.${index}.${item.name}`}
                            label={`${item.displayName} (per ${item.rateUnit})`}
                            placeholder={`Enter in per ${item.rateUnit}`}
                            type="number"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </StyledBoxContainer>
                </Grid>
              )}
              <Grid item xs marginTop={4}>
                <StyledButton
                  fullWidth={isMobileDevice ? true : false}
                  component={LoadingButton}
                  loading={isLoading}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  sx={{ textTransform: "none" }}
                  disabled={truckDetails.length < 1}
                >
                  {transport_labels.submitPreviewButtonText}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

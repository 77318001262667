import { updateStorageTypes } from "../../warehouse/actions";
import { useMutation } from "@tanstack/react-query";

export const useUpdateStorageTypesMutateQuery = () => {
  const storageTypesMutate = useMutation(({ partnerId, data }) =>
    updateStorageTypes(partnerId, data)
  );

  return storageTypesMutate;
};

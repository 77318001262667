import { Box, Button, Grid } from "@mui/material";

import { Link } from "react-router-dom";
import { StyledBox } from "./DashboardListItem";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { warehouse_labels } from "../../constants";

const ListItemWarehouseCard = ({
  imageSrc,
  name,
  city,
  state,
  country,
  listingDate,
  id,
  status,
  partnerId,
  heroImage,
}) => {
  return (
    <StyledBox>
      <Grid container spacing={"20px"}>
        <Grid item xs={12} md={6} display={"flex"}>
          <img
            src={heroImage.thumbUrl}
            alt={name}
            height={isMobile ? 148 : 210}
            width={"100%"}
            style={{
              borderRadius: "8px",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Box width={"100%"}>
            <StyledResponsiveText
              desktop={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "19px",
                letterSpacing: "0.005em",
                mb: "4px",
              }}
              mobile={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "19px",
                letterSpacing: "0.08px",
                mb: "4px",
              }}
            >
              {name}
            </StyledResponsiveText>
            <StyledResponsiveText
              desktop={{
                fontSize: 14,
                lineHeight: "20px",
                letterSpacing: "0.005em",
                mb: "28px",
              }}
              mobile={{
                fontSize: 13,
                lineHeight: "18px",
                mb: "28px",
              }}
              color="text.secondary"
            >
              {`${city}, ${state}, ${country}`}
            </StyledResponsiveText>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              width={"100%"}
            >
              {status.name === "Active" ? (
                <Box
                  display={"flex"}
                  gap={"4px"}
                  sx={{
                    flexDirection: {
                      xs: "column-reverse",
                      md: "row",
                    },
                    alignItems: {
                      xs: "flex-start",
                      md: "center",
                    },
                  }}
                >
                  <StyledResponsiveText
                    desktop={{
                      fontSize: 14,
                      lineHeight: "18px",
                      letterSpacing: "0.16 px",
                    }}
                    mobile={{
                      fontSize: 13,
                      lineHeight: "18px",
                    }}
                    color="text.secondary"
                  >
                    {warehouse_labels.listedOnText}
                  </StyledResponsiveText>
                  <StyledResponsiveText
                    desktop={{
                      fontSize: 14,
                      lineHeight: "18px",
                      letterSpacing: "0.16 px",
                      fontWeight: 600,
                    }}
                    mobile={{
                      fontSize: 14,
                      lineHeight: "18px",
                      letterSpacing: "0.16 px",
                      fontWeight: 600,
                    }}
                    color="#161616"
                  >
                    {moment(listingDate).format("MMMM DD, YYYY")}
                  </StyledResponsiveText>
                </Box>
              ) : (
                <StyledChip label={status.displayName} />
              )}

              <Button
                sx={{
                  height: "auto",
                  fontSize: 13,
                  fontWeight: 600,
                  letterSpacing: "0.03em",
                  display: {
                    xs: "flex",
                    md: "none",
                  },
                }}
                component={Link}
                to={`/dashboard/warehouse/${partnerId}`}
                target="_blank"
              >
                {warehouse_labels.viewDetailsButtonText}
              </Button>
            </Box>
          </Box>
          <Button
            sx={{
              height: "auto",
              fontSize: 13,
              fontWeight: 600,
              letterSpacing: "0.03em",
              display: {
                xs: "none",
                md: "flex",
              },
            }}
            component={Link}
            to={`/dashboard/warehouse/${id}`}
            target="_blank"
          >
            {warehouse_labels.viewDetailsButtonText}
          </Button>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default ListItemWarehouseCard;

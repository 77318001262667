import { QueryKeys } from "../../constants/query-keys";
import { fetchTransportFormData } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportFormDataQueryHook = (partnerId) => {
  const transportFormDataQuery = useQuery(
    [QueryKeys.queryGetTransportFormData],
    () => fetchTransportFormData(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId),
    }
  );
  return transportFormDataQuery;
};

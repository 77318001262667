import { updateTruck } from "../../transport/actions";
import { useMutation } from "@tanstack/react-query";

export const useUpdateTruckMutateQuery = () => {
  const truckMutate = useMutation(({ partnerId, data, vehicleId }) =>
    updateTruck(partnerId, data, vehicleId)
  );

  return truckMutate;
};

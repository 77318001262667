import { QueryKeys } from "../../constants/query-keys";
import { fetchWarehouseFormData } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseFormDataQueryHook = (partnerId) => {
  const warehouseFormDataQuery = useQuery(
    [QueryKeys.queryGetWarehouseFormData],
    () => fetchWarehouseFormData(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId),
    }
  );
  return warehouseFormDataQuery;
};

import { updateStorageArea } from "../../warehouse/actions";
import { useMutation } from "@tanstack/react-query";

export const useUpdateStorageAreaMutateQuery = () => {
  const storageAreaMutate = useMutation(({ partnerId, data }) =>
    updateStorageArea(partnerId, data)
  );

  return storageAreaMutate;
};

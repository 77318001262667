import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { quotation_labels } from "../pages/constant";
import {
  Add,
  CheckCircleRounded,
  ExpandMore,
  Remove
} from "@mui/icons-material";
import { Fragment, useCallback, useEffect } from "react";
import WarexLogo from "../../assets/Warex-Menu-logo.png";
import moment from "moment";
import { labels, warehouse_labels } from "../../constants";
import { currencyFormatter } from "../../shared/utils";
import { ToWords } from "to-words";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutateCreateWarehouseInvoice } from "../../query-hooks/warehouseInvoice/useMutateCreateWarehouseInvoice";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { AddressSection } from "../../transport/components/InvoiceDetailTab";
import TextInput from "../../shared/formElements/TextInput";
import DropDown from "../../shared/formElements/DropDown";

const invoiceFormSchema = yup
  .object({
    invoices: yup.array().of(
      yup.object().shape({
        rate: yup.string().required("Rate is required"),
        quantityValue: yup.string().required("Quantity is required")
      })
    )
  })
  .required();

const InvoiceDetailTab = ({
  customerBillingDetails,
  orderId,
  invoiceFormData,
  isFormFetched,
  manualInvoiceData,
  setIsManualInvoice
}) => {
  const { control, handleSubmit, watch, setValue, getValues, reset } = useForm({
    defaultValues: {
      invoices: [],
      subTotal: "",
      total: "",
      gst: ""
    },
    resolver: yupResolver(invoiceFormSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "invoices"
  });

  const toWords = new ToWords();
  const navigate = useNavigate();

  const { mutate, isLoading, isError, error } =
    useMutateCreateWarehouseInvoice();

  const invoice = invoiceFormData?.order?.recentOrderItem?.warehouseCharges;

  const warehouseDetails = invoiceFormData?.warehouse;

  const addressDetails = [
    {
      title: "Customer / Buyer",
      name: `${customerBillingDetails?.firstName} ${customerBillingDetails?.lastName}`,
      addressLine1: `${customerBillingDetails?.firmName}`,
      addressLine2: `${customerBillingDetails?.address?.area}, ${customerBillingDetails?.address?.city}, ${customerBillingDetails?.address?.state} ${customerBillingDetails?.address?.pincode}`,
      addressLine3: `GSTIN : ${customerBillingDetails?.gstNo}`
    },
    {
      title: "Service provider",
      name: warehouseDetails?.code,
      addressLine1: `${warehouseDetails?.address?.area}, ${warehouseDetails?.address?.pincode}`,
      addressLine2: `GSTIN : ${warehouseDetails?.gstNo}`
    }
  ];

  const invoicesWatch = watch("invoices");

  const handleInvoice = (data) => {
    const fileData = new FormData();
    if (manualInvoiceData) {
      fileData.append("orderId", orderId);
      fileData.append("services[0][name]", data.invoices[0].uom);
      fileData.append("services[0][quantity]", +data.invoices[0].quantityValue);
      data.invoices
        .filter((item, index) => index > 0 && item.name !== "Other")
        .forEach((item, index) => {
          fileData.append(`additionalServices[${index}][name]`, item.name);
          fileData.append(
            `additionalServices[${index}][quantity]`,
            +item.quantityValue
          );
        });
      data.invoices
        .filter((item) => item.name === "Other")
        .forEach((item) => {
          fileData.append(`otherServices[0][name]`, item.name);
          fileData.append(
            `otherServices[0][rate]`,
            +item.rate * +item.quantityValue
          );
        });
      fileData.append("invoiceCode", manualInvoiceData.invoiceId);
      fileData.append(
        "invoiceDate",
        moment(manualInvoiceData.invoiceDate).format("YYYY-MM-DD")
      );
      fileData.append("file", manualInvoiceData.manualInvoice);
    }
    mutate(
      {
        ...{
          ...(manualInvoiceData
            ? { formData: fileData }
            : {
                formData: {
                  orderId: orderId,
                  services: [
                    {
                      name: data.invoices[0].uom,
                      quantity: +data.invoices[0].quantityValue
                    }
                  ],
                  additionalServices: data.invoices
                    .filter((item, index) => index > 0 && item.name !== "Other")
                    .map((item) => {
                      return {
                        name: item.name,
                        quantity: +item.quantityValue
                      };
                    }),

                  otherServices: data.invoices
                    .filter((item) => item.name === "Other")
                    .map((item) => {
                      return {
                        name: item.name,
                        rate: +item.rate * +item.quantityValue
                      };
                    })
                }
              })
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Invoice Created Successfully", {
            variant: "success"
          });
          navigate("/orders/invoices-docs");
        }
      }
    );
  };

  useEffect(() => {
    if (invoicesWatch) {
      invoicesWatch.forEach((item, index) => {
        const rate = item.rate;
        const quantity = item.quantityValue;
        index === 0
          ? setValue(
              `invoices.${index}.total`,
              parseFloat((+item.durationValue * +rate * +quantity)).toFixed(2)
            )
          : index > 0 && item.name !== "Other"
          ? setValue(
              `invoices.${index}.total`,
              parseFloat((+rate * +quantity) / 100).toFixed(0)
            )
          : setValue(
              `invoices.${index}.total`,
              parseFloat(+rate * +quantity).toFixed(0)
            );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(invoicesWatch)]);

  const appendInvoiceForm = useCallback(
    (invoice) => {
      append({
        label: "Storage Cost",
        uom: invoice.services[0].name,
        duration: `${parseFloat(invoice.services[0].duration / 30).toFixed(
          2
        )} Months`,
        durationValue: invoice.services[0].duration / 30,
        quantity: `${
          invoice.services[0].quantity.$numberDecimal
        } ${invoice.services[0].rateUnit.toLowerCase()}`,
        quantityValue: invoice.services[0].quantity.$numberDecimal,
        rate: invoice.services[0].rate.$numberDecimal,
        total: invoice.services[0].totalAmount.$numberDecimal,
        options: [
          {
            displayName: invoice.services[0].displayName,
            name: invoice.services[0].name
          }
        ]
      });

      invoice.additionalServices.forEach((item) => {
        append({
          label: item.displayName,
          uom: `Prices / ${item.rateUnit}`,
          name: item.name,
          duration: `-`,
          quantity: `${item.quantity.$numberDecimal} Kgs`,
          quantityValue: item.quantity.$numberDecimal,
          rate: item.rate.$numberDecimal,
          total: item.totalAmount.$numberDecimal,
          options: [
            {
              displayName: `Prices / ${item.rateUnit}`,
              name: `Prices / ${item.rateUnit}`
            }
          ]
        });
      });

      if (invoice.otherServices.length > 0)
        append({
          label: invoice.otherServices[0].displayName,
          uom: invoice.otherServices[0].rateUnit,
          name: invoice.otherServices[0].name,
          duration: `-`,
          quantity: invoice.otherServices[0].quantity.$numberDecimal,
          quantityValue: invoice.otherServices[0].quantity.$numberDecimal,
          rate: invoice.otherServices[0].rate.$numberDecimal,
          total: invoice.otherServices[0].totalAmount.$numberDecimal,
          options: [
            {
              displayName: invoice.otherServices[0].rateUnit,
              name: invoice.otherServices[0].rateUnit
            }
          ]
        });
    },
    [append]
  );

  const handleResetButton = () => {
    reset();
    appendInvoiceForm(invoice);
  };

  const addOtherinvoiceFormData = () => {
    const otherServicesCost = invoiceFormData?.otherServices[0];
    append({
      label: otherServicesCost.displayName,
      uom: otherServicesCost.rateUnit,
      name: otherServicesCost.name,
      duration: `-`,
      quantity: "",
      quantityValue: "",
      rate: "",
      total: "",
      options: [
        {
          displayName: otherServicesCost.rateUnit,
          name: otherServicesCost.rateUnit
        }
      ]
    });
  };

  const subTotal = parseFloat(
    getValues("invoices")?.reduce(function (previousTotal, currentTotal) {
      return previousTotal + +currentTotal.total;
    }, 0)
  ).toFixed(2);

  const gst = parseFloat(
    (subTotal * invoice?.taxes[0]?.rate?.$numberDecimal) / 100
  ).toFixed(2);

  const totalAmount = parseFloat(+subTotal + +gst).toFixed(2);

  useEffect(() => {
    if (isFormFetched) {
      if (invoice) {
        appendInvoiceForm(invoice);
      }
    }
  }, [appendInvoiceForm, isFormFetched, invoice]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
    }
  }, [error?.response.data.message, isError]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={5}>
      <Box
        padding={5}
        sx={{
          borderRadius: "8px",
          border: "0.4px solid #95A1B0",
          gap: { xs: 1, md: 5 }
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        {/* mobile view */}
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Box display={"flex"}>
              <img src={WarexLogo} alt="warex" />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
                {`Invoice no. ${manualInvoiceData?.invoiceId ?? "-"}`}
              </Typography>
              <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
                {`Invoice date : ${
                  manualInvoiceData
                    ? moment(manualInvoiceData?.invoiceDate).format(
                        "DD-MM-yyyy"
                      )
                    : "-"
                }`}
              </Typography>
              <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
                {`PickUp date: ${moment(
                  invoiceFormData?.order?.recentOrderItem?.inboundDate
                ).format("DD-MM-yyyy")}`}
              </Typography>
            </Box>
          </Box>
          <Box width="100%">
            {addressDetails.map((item, index) => (
              <Box width="100%" key={index}>
                <Divider sx={{ height: "0.4px", background: "#B7BEC7" }} />
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMore color="primary" />}
                    sx={{ padding: 0 }}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize="14px"
                      color={"primary.dark"}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                    <AddressSection
                      name={item.name}
                      addressLine1={item.addressLine1}
                      addressLine2={item.addressLine2}
                      addressLine3={item.addressLine3}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        </Box>

        {/* desktop View */}
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <AddressSection
            title={"Customer / Buyer"}
            name={`${customerBillingDetails?.firstName} ${customerBillingDetails?.lastName}`}
            addressLine1={`${customerBillingDetails?.firmName}`}
            addressLine2={`${customerBillingDetails?.address?.area}, ${customerBillingDetails?.address?.city}, ${customerBillingDetails?.address?.state} ${customerBillingDetails?.address?.pincode}`}
            addressLine3={`GSTIN : ${customerBillingDetails?.gstNo}`}
          />
          <Box display={"flex"}>
            <img src={WarexLogo} alt="warex" />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            textAlign={"right"}
            alignSelf={"flex-start"}
          >
            <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
              {`Invoice no. ${manualInvoiceData?.invoiceId ?? "-"}`}
            </Typography>
            <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
              {`Invoice date : ${
                manualInvoiceData
                  ? moment(manualInvoiceData?.invoiceDate).format("DD-MM-yyyy")
                  : "-"
              }`}
            </Typography>
            <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
              {`PickUp date: ${moment(
                invoiceFormData?.order?.recentOrderItem?.inboundDate
              ).format("DD-MM-yyyy")}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box></Box>
          <Box textAlign={"right"}>
            <AddressSection
              title={"Service provider"}
              name={warehouseDetails?.name}
              addressLine1={`${warehouseDetails?.address?.area}, ${warehouseDetails?.address?.pincode}`}
              addressLine2={`GSTIN : ${warehouseDetails?.gstNo}`}
            />
          </Box>
        </Box>

        {/* Invoice Input from here*/}
        <Box display={"flex"} flexDirection={"column"} gap={3} marginBottom={3}>
          <Divider sx={{ height: "0.4px", background: "#B7BEC7" }} />
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0.005em"
            }}
          >
            {warehouse_labels.reviseQuotationTitleText}
          </Typography>
          <Box sx={{ overflowX: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} container spacing={2} flexWrap={"nowrap"}>
                <Grid
                  item
                  xs={2}
                  minWidth={140}
                  sx={{
                    background: {
                      xs: "#F0F2F5",
                      md: "transparent"
                    }
                  }}
                />
                {quotation_labels.map((item, index) => (
                  <Fragment key={index}>
                    <Grid
                      item
                      xs={2}
                      display={"flex"}
                      alignItems={"flex-end"}
                      minWidth={140}
                    >
                      <Typography fontSize={14} fontWeight={500}>
                        {item}
                      </Typography>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
              {fields.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  key={item.id}
                  flexWrap={"nowrap"}
                >
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    alignItems={"center"}
                    minWidth={140}
                    sx={{
                      background: {
                        xs: "#F0F2F5",
                        md: "transparent"
                      }
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      sx={{
                        paddingLeft: {
                          xs: "15px",
                          md: 0
                        }
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} minWidth={140}>
                    <DropDown
                      control={control}
                      name={`invoices.${index}.uom`}
                      options={item.options}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2} minWidth={140}>
                    <TextInput
                      control={control}
                      name={`invoices.${index}.duration`}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2} minWidth={140}>
                    <TextInput
                      control={control}
                      name={`invoices.${index}.quantityValue`}
                      // type={item.quantity === "" ? "number" : "text"}
                      // disabled={
                      //   partner?.status?.name === statusKeys.Pending &&
                      //   index === quote.additionalServices.length + 1
                      //     ? false
                      //     : true
                      // }
                    />
                  </Grid>
                  <Grid item xs={2} minWidth={140}>
                    <TextInput
                      startIcon={
                        <Box padding={"0 0 0 16px"} fontSize={14}>
                          ₹
                        </Box>
                      }
                      control={control}
                      name={`invoices.${index}.rate`}
                      type="number"
                      disabled={
                        index === invoice.additionalServices.length + 1
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  <Grid item xs={2} minWidth={140}>
                    <TextInput
                      startIcon={<Box padding={"0 0 0 16px"}>₹</Box>}
                      control={control}
                      name={`invoices.${index}.total`}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider
            sx={{
              borderColor: "#B7BEC7",
              display: {
                xs: "none",
                md: "block"
              }
            }}
          />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row"
              }
            }}
            gap={3}
          >
            {invoice?.otherServices.length < 1 && (
              <Box>
                {fields.length === invoice.additionalServices.length + 1 ? (
                  <Button
                    variant="text"
                    startIcon={<Add />}
                    onClick={addOtherinvoiceFormData}
                  >
                    {warehouse_labels.addMoreItemsButtonText}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    startIcon={<Remove />}
                    color="error"
                    onClick={() => remove(fields.length - 1)}
                  >
                    {`Remove ${invoiceFormData?.otherServices?.[0].displayName}`}
                  </Button>
                )}
              </Box>
            )}
            <Divider
              sx={{
                borderColor: "#B7BEC7",
                width: "100%",
                display: {
                  xs: "block",
                  md: "none"
                }
              }}
            />
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "auto"
                }
              }}
              display={"flex"}
              flexDirection={"column"}
              gap={"12px"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"47px"}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                >
                  Subtotal
                </Typography>
                <Typography
                  fontSize={14}
                  whiteSpace={"nowrap"}
                  textAlign={"left"}
                >
                  {currencyFormatter(subTotal)}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"47px"}
                justifyContent={"space-between"}
              >
                <Typography
                  fontSize={14}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                >
                  {`${invoice?.taxes[0]?.name} @ ${invoice?.taxes[0]?.rate?.$numberDecimal} %`}
                </Typography>
                <Typography
                  fontSize={14}
                  whiteSpace={"nowrap"}
                  textAlign={"left"}
                >
                  {currencyFormatter(gst)}
                </Typography>
              </Box>
              <Box
                alignItems={"center"}
                gap={"47px"}
                justifyContent={"space-between"}
                sx={{
                  display: {
                    xs: "flex",
                    md: "none"
                  }
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  whiteSpace={"nowrap"}
                  textAlign={"right"}
                >
                  {labels.totalAmountText}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  whiteSpace={"nowrap"}
                  textAlign={"left"}
                >
                  {currencyFormatter(parseFloat(+subTotal + +gst).toFixed(2))}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex"
            }
          }}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} gap={"4px"}>
            <Typography textAlign="start" sx={{ fontWeight: 700 }}>
              {`${labels.inWord} : `}
            </Typography>
            <Typography textAlign="start">
              {totalAmount > 0 &&
                toWords.convert(+totalAmount, { currency: true })}
            </Typography>
          </Box>
          <Box display={"flex"} gap={"14px"}>
            <Typography
              fontSize={14}
              fontWeight={700}
              whiteSpace={"nowrap"}
              textAlign={"right"}
            >
              {labels.totalAmountText}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={600}
              whiteSpace={"nowrap"}
              textAlign={"left"}
            >
              {currencyFormatter(parseFloat(+subTotal + +gst).toFixed(2))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        gap={"10px"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row"
          }
        }}
        justifyContent={"flex-end"}
      >
        {manualInvoiceData ? (
          <Button
            fullWidth
            variant="link"
            disableRipple
            sx={{
              height: 42,
              width: {
                xs: "100%",
                md: 164
              }
            }}
            startIcon={<CheckCircleRounded />}
          >
            Invoice Uploaded
          </Button>
        ) : (
          <Button
            fullWidth
            variant="link"
            disableRipple
            sx={{
              height: 42,
              width: {
                xs: "100%",
                md: 164
              },
              color: "#F05454"
            }}
            startIcon={<Add />}
            onClick={() => {
              setIsManualInvoice(true);
            }}
          >
            Upload manually
          </Button>
        )}
        <Button
          fullWidth
          variant="soft"
          sx={{
            height: 42,
            width: {
              xs: "100%",
              md: 164
            }
          }}
          onClick={handleResetButton}
        >
          Reset changes
        </Button>
        <Button
          fullWidth
          variant="contained"
          component={LoadingButton}
          sx={{
            height: 42,
            width: {
              xs: "100%",
              md: 164
            }
          }}
          onClick={handleSubmit(handleInvoice)}
          loading={isLoading}
        >
          Send Invoice
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceDetailTab;

export const StorageKeys = {
  ACCESS_TOKEN: "accessToken",
  ID_TOKEN: "idToken",
  LOCAL_CRUMBS: "local-crumbs",
  ACTIVE_TAB: "activeTab",
  WAREHOUSE_FORM_DATA: "warehouse-form-data",
  TRANSPORT_FORM_DATA: "transport-form-data",
  serviceLocalStorage: "serviceLocalStorage",
  dashboardUser: "dashboardUser",
  PARTNER_ID: "partnerId",
  PROFILE_DATA: "profileData"
};

export const UserRoles = {
  warehouseAdmin: "WarehouseAdmin",
  transportAdmin: "TransportAdmin"
};

export const StorageServiceObject = {
  warehouse: false,
  transport: false
};

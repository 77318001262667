import { QueryKeys } from "../../constants/query-keys";
import { getWarehouseRegretFormData } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseRegretForm = (inquiryId) => {
  const warehouseRegretFormQuery = useQuery(
    [QueryKeys.queryWarehouseRegretFormData, inquiryId],
    () => getWarehouseRegretFormData(inquiryId),
    {
      enabled: Boolean(inquiryId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return warehouseRegretFormQuery;
};

/* eslint-disable no-unused-vars */

import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import OrderMobileCard from "../components/OrderMobileCard";
import OrderStatus from "../components/OrderStatus";
import { CustomTabs as OrderTabs } from "../../shared/customTab/CustomTabs";
import OrdersTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { isMobileDevice } from "../../App";
import { stringCapitalization } from "../../shared/utils";
import testData from "../actions/testData.json";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchCompletedOrdersQuery } from "../../query-hooks/warehouseOrders/useFetchCompletedOrdersQuery";
import { useFetchIncomingOrdersQuery } from "../../query-hooks/warehouseOrders/useFetchIncomingOrdersQuery";
import { useFetchStoredOrdersQuery } from "../../query-hooks/warehouseOrders/useFetchStoredOrdersQuery";
import { useEffect, useState } from "react";
import moment from "moment";
import { useInView } from "react-intersection-observer";

const COLUMNS = [
  {
    Header: "Order ID",
    accessor: "code",
    Cell: ({ value }) => {
      return (
        <Typography sx={{ fontSize: 14, fontWeight: 600, color: "#0F2744" }}>
          {value}
        </Typography>
      );
    }
  },
  {
    Header: "Order date",
    accessor: "createdAt"
  },
  {
    Header: "Inbound",
    accessor: "inboundDate"
  },
  {
    Header: "Outbound",
    accessor: "outboundDate"
  },
  {
    Header: "Material",
    accessor: "material"
  }
];

const INCOMING_ORDERS_COLUMNS = [
  ...COLUMNS,
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <StyledChip label={value} />;
    }
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableSortBy: true,
    Cell: ({ value, cell }) => {
      const orderId = cell.row.original.orderId;
      return !value ? (
        <Box display={"flex"} gap={4}>
          {cell.row.original.status === "ReceivedAtFacility" ? (
            <Button
              variant="link"
              disableRipple
              sx={{ fontWeight: 600 }}
              component={Link}
              to={`/orders/material-received-report/${orderId}`}
            >
              Add Storage detail
            </Button>
          ) : (
            <Button
              variant="link"
              disableRipple
              sx={{ fontWeight: 600 }}
              component={Link}
              to={`/orders/material-received-report/${orderId}`}
            >
              Create MRR
            </Button>
          )}
        </Box>
      ) : (
        <Typography fontSize={12}>{value}</Typography>
      );
    }
  }
];

const MyOrders = () => {
  const [activeInquiryTab, setActiveInquiryTab] = useState(0);
  const { showDrawer, hideDrawer } = useDrawer();
  const navigate = useNavigate();

  const handleInquiryTabChange = (index) => {
    setActiveInquiryTab(index);
  };

  const { ref: incomingRef, inView: incomingView } = useInView({
    threshold: 0
  });
  const { ref: storedRef, inView: storedView } = useInView({
    threshold: 0
  });
  const { ref: completedRef, inView: completedView } = useInView({
    threshold: 0
  });

  const {
    data: incomingOrders,
    isFetched: isIncomingOrderFetched,
    hasNextPage: hasNextIncomingOrderPage,
    fetchNextPage: fetchNextIncomingOrderPage
  } = useFetchIncomingOrdersQuery();

  const {
    data: storedOrders,
    isFetched: isStoredOrderFetched,
    hasNextPage: hasNextStoredOrderPage,
    fetchNextPage: fetchNextStoredOrderPage
  } = useFetchStoredOrdersQuery();

  const {
    data: completedOrders,
    isFetched: isCompletedOrderFetched,
    hasNextPage: hasNextCompletedOrderPage,
    fetchNextPage: fetchNextCompletedOrderPage
  } = useFetchCompletedOrdersQuery();

  const STORED_ORDERS_COLUMNS = [
    ...COLUMNS,
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, cell }) => {
        const row = cell.row.original.orderId;
        return (
          <StyledChip
            label={stringCapitalization(value)}
            onClick={() => {
              showDrawer({
                component: <OrderStatus close={hideDrawer} orderId={row} />,
                closeIcon: false
              });
            }}
          />
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true
    }
  ];

  const getIncomingOrders = (rows = []) => {
    const disableActionStatus = ["InTransit", "ReceivedAtFacility"];
    return rows.map((item, index) => {
      return {
        orderId: item._id,
        code: item.warehouseOrder.code,
        createdAt: moment(item.createdAt).format("DD MMMM YYYY"),
        inboundDate: moment(item.recentOrderItem.inboundDate).format(
          "DD MMMM YYYY"
        ),
        outboundDate: moment(item.recentOrderItem.outboundDate).format(
          "DD MMMM YYYY"
        ),
        material: item.searchForm?.materialCategory?.displayName || "--",
        status: item.warehouseOrder.warehouseStatus.name,
        ...{
          ...(!disableActionStatus?.includes(
            item.warehouseOrder.warehouseStatus.name
          )
            ? {
                actions: (
                  <>
                    {"/ Scheduled for"}{" "}
                    <b>
                      {moment(item.recentOrderItem.inboundDate).format(
                        "DD MMM"
                      )}
                    </b>
                  </>
                )
              }
            : undefined)
        }
      };
    });
  };

  const getStoredOrders = (rows = []) => {
    const disableActionStatus = ["Stored"];
    return rows.map((item, index) => {
      const outboundDate = moment(item.recentOrderItem.outboundDate);
      return {
        orderId: item._id,
        code: item.warehouseOrder.code,
        createdAt: moment(item.createdAt).format("DD MMMM YYYY"),
        inboundDate: moment(item.recentOrderItem.inboundDate).format(
          "DD MMMM YYYY"
        ),
        outboundDate: moment(item.recentOrderItem.outboundDate).format(
          "DD MMMM YYYY"
        ),
        material: item.searchForm?.materialCategory?.displayName || "--",
        status: item.warehouseOrder.warehouseStatus.name,
        ...{
          ...(!disableActionStatus?.includes(
            item.warehouseOrder.warehouseStatus.name
          )
            ? {
                actions: (
                  <>
                    <Typography
                      display={"inline"}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "16px",
                        color: "text.primary"
                      }}
                    >
                      {"/ Outbound in "}
                    </Typography>
                    <Typography
                      display={"inline"}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "16px",
                        color: "warning.dark"
                      }}
                    >
                      {`${outboundDate.diff(new Date(), "days")} days`}
                    </Typography>
                  </>
                )
              }
            : {
                actions: (
                  <Box display={"flex"} gap={4}>
                    <Button
                      variant="link"
                      disableRipple
                      sx={{ fontWeight: 600 }}
                      onClick={() =>
                        // navigate(`/orders/${item._id}/create-gate-pass`)
                        console.log("remove the button")
                      }
                    >
                      Create Gatepass
                    </Button>
                  </Box>
                )
              })
        }
      };
    });
  };

  useEffect(() => {
    if (incomingView && hasNextIncomingOrderPage) {
      fetchNextIncomingOrderPage();
    }
    if (storedView && hasNextStoredOrderPage) {
      fetchNextStoredOrderPage();
    }
    if (completedView && hasNextCompletedOrderPage) {
      fetchNextCompletedOrderPage();
    }
  }, [
    fetchNextIncomingOrderPage,
    fetchNextStoredOrderPage,
    fetchNextCompletedOrderPage,
    hasNextIncomingOrderPage,
    hasNextStoredOrderPage,
    hasNextCompletedOrderPage,
    incomingView,
    storedView,
    completedView
  ]);

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <OrderTabs
        tabs={[
          {
            label: "Incoming Orders",
            Component: (
              <OrdersTable
                searchPlaceholderText={"Search by order ID partner name, etc"}
                columns={INCOMING_ORDERS_COLUMNS}
                data={
                  isIncomingOrderFetched
                    ? incomingOrders?.pages.flatMap((page) =>
                        getIncomingOrders(page?.orders)
                      )
                    : []
                }
                // data={testData.incomingOrders}
                mobileComponent={OrderMobileCard}
                // isFetching={isIncomingOrderFetching}
                isLoading={!isIncomingOrderFetched}
              />
            )
          },
          {
            label: "Stored Orders",
            Component: (
              <OrdersTable
                searchPlaceholderText={"Search by order ID partner name, etc"}
                columns={STORED_ORDERS_COLUMNS}
                data={
                  isStoredOrderFetched
                    ? storedOrders?.pages.flatMap((page) =>
                        getStoredOrders(page?.orders)
                      )
                    : []
                }
                // data={testData.storedOrders}
                mobileComponent={OrderMobileCard}
                // isFetching={isStoredOrderFetching}
                isLoading={!isStoredOrderFetched}
              />
            )
          },
          {
            label: "Completed Orders",
            Component: (
              <OrdersTable
                searchPlaceholderText={"Search by order ID partner name, etc"}
                columns={INCOMING_ORDERS_COLUMNS}
                data={completedOrders?.orders || []}
                // data={testData.incomingOrders}
                mobileComponent={OrderMobileCard}
                // isFetching={isCompletedOrderFetching}
                isLoading={!isCompletedOrderFetched}
              />
            )
          }
        ]}
        activeTab={activeInquiryTab}
        onChange={handleInquiryTabChange}
        nested={isMobileDevice ? true : false}
      />
      {hasNextIncomingOrderPage && (
        <Grid
          item
          xs={12}
          ref={incomingRef}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
      {hasNextStoredOrderPage && (
        <Grid
          item
          xs={12}
          ref={storedRef}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
      {hasNextCompletedOrderPage && (
        <Grid
          item
          xs={12}
          ref={completedRef}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default MyOrders;

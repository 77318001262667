/* eslint-disable no-unused-vars */

import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import AssignDriverForm from "../components/AssignDriverForm";
import OrderMobileCard from "../components/OrderMobileCard";
import OrderStatus from "../components/OrderStatus";
import { CustomTabs as OrderTabs } from "../../shared/customTab/CustomTabs";
import OrdersTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { isMobileDevice } from "../../App";
import { stringCapitalization } from "../../shared/utils";
import testData from "../../warehouse/actions/testData.json";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchActiveOrdersQuery } from "../../query-hooks/TransportOrders/useFetchActiveOrdersQuery";
import { useFetchCompletedTransportOrdersQuery } from "../../query-hooks/TransportOrders/useFetchCompletedTransportOrdersQuery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { useInView } from "react-intersection-observer";

const COLUMNS = [
  {
    Header: "Order ID",
    accessor: "code",
    Cell: ({ value }) => {
      return (
        <Typography sx={{ fontSize: 14, fontWeight: 600, color: "#0F2744" }}>
          {value}
        </Typography>
      );
    },
  },
  {
    Header: "Order date",
    accessor: "createdAt",
  },
  {
    Header: "Pickup date",
    accessor: "pickupDate",
  },
  {
    Header: "Inbound date",
    accessor: "inboundDate",
  },
  {
    Header: "Material",
    accessor: "material",
  },
];

const MyOrders = () => {
  const [activeInquiryTab, setActiveInquiryTab] = useState(0);
  const { showDrawer, hideDrawer } = useDrawer();

  const { ref: activeRef, inView: inActiveView } = useInView({ threshold: 0 });
  const { ref: completedRef, inView: inCompletedView } = useInView({
    threshold: 0,
  });

  const handleInquiryTabChange = (index) => {
    setActiveInquiryTab(index);
  };
  const { showDialog, hideDialog } = useCustomDialog();
  const navigate = useNavigate();

  const {
    data: activeOrders,
    isFetched: isActiveOrderFetched,
    refetch: activeOrderRefetch,
    hasNextPage: hasNextActiveOrderPage,
    fetchNextPage: fetchNextActiveOrderPage,
  } = useFetchActiveOrdersQuery();

  const {
    data: completedOrders,
    isFetched: isCompletedOrderFetched,
    refetch: completedOrderRefetch,
    hasNextPage: hasNextCompletedOrderPage,
    fetchNextPage: fetchNextCompletedOrderPage,
  } = useFetchCompletedTransportOrdersQuery();

  const table_columns = [
    ...COLUMNS,
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, cell }) => {
        const row = cell.row.original.orderId;
        return (
          <StyledChip
            label={stringCapitalization(value)}
            onClick={() => {
              showDrawer({
                component: <OrderStatus close={hideDrawer} orderId={row} />,
                closeIcon: false,
              });
            }}
          />
        );
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ value, cell }) => {
        const row = cell.row.original.orderId;
        return !value ? (
          <Box display={"flex"} gap={4}>
            <Button
              variant="link"
              disableRipple
              sx={{ fontWeight: 600 }}
              onClick={() => {
                showDialog({
                  component: (
                    <AssignDriverForm
                      orderId={row}
                      close={hideDialog}
                      createInvoiceHandler={() => {
                        hideDialog();
                        navigate(`/orders/my-orders/${row}/summary`);
                      }}
                      refetchOrders={() => {
                        activeOrderRefetch();
                        completedOrderRefetch();
                      }}
                    />
                  ),
                });
              }}
            >
              Assign Driver
            </Button>
          </Box>
        ) : (
          <Typography fontSize={12}>{value}</Typography>
        );
      },
    },
  ];

  const getActiveOrders = (rows = []) => {
    const disableActionStatus = ["VehicleAssigned", "InTransit", "Completed"];
    return rows.map((item, index) => {
      return {
        orderId: item._id,
        code: item.transportOrder.code,
        createdAt: moment(item.createdAt).format("DD MMMM YYYY"),
        pickupDate: moment(item.recentOrderItem.inboundDate).format(
          "DD MMMM YYYY"
        ),
        inboundDate: moment(item.recentOrderItem.inboundDate).format(
          "DD MMMM YYYY"
        ),
        outboundDate: moment(item.recentOrderItem.outboundDate).format(
          "DD MMMM YYYY"
        ),
        material: item.searchForm.materialCategory?.displayName || "--",
        status: item.transportOrder.transportStatus.name,
        ...{
          ...(!item.transportOrder.transportStatus.name?.includes(
            disableActionStatus
          )
            ? item.transportOrder.transportStatus.name ===
              disableActionStatus[0]
              ? {
                  actions: `/ Driver assigned`,
                }
              : item.transportOrder.transportStatus.name ===
                disableActionStatus[1]
              ? {
                  actions: `/ Pick on ${moment(
                    item.recentOrderItem.inboundDate
                  ).format("DD MMM")}`,
                }
              : item.transportOrder.transportStatus.name ===
                disableActionStatus[2]
              ? {
                  actions: (
                    <>
                      / Shipped on
                      <b>{` ${moment(item.recentOrderItem.inboundDate).format(
                        "DD MMM"
                      )}`}</b>
                    </>
                  ),
                }
              : undefined
            : undefined),
        },
      };
    });
  };

  useEffect(() => {
    if (inActiveView && hasNextActiveOrderPage) {
      fetchNextActiveOrderPage();
    }
    if (inCompletedView && hasNextCompletedOrderPage) {
      fetchNextCompletedOrderPage();
    }
  }, [
    fetchNextActiveOrderPage,
    fetchNextCompletedOrderPage,
    hasNextActiveOrderPage,
    hasNextCompletedOrderPage,
    inActiveView,
    inCompletedView,
  ]);

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0,
        },
      }}
    >
      <OrderTabs
        tabs={[
          {
            label: "Active Orders",
            Component: (
              <OrdersTable
                searchPlaceholderText={"Search by order ID partner name, etc"}
                columns={table_columns}
                data={
                  isActiveOrderFetched
                    ? activeOrders?.pages.flatMap((page) =>
                        getActiveOrders(page?.orders)
                      )
                    : []
                }
                mobileComponent={OrderMobileCard}
                isLoading={!isActiveOrderFetched}
              />
            ),
          },
          {
            label: "Completed Orders",
            Component: (
              <OrdersTable
                searchPlaceholderText={"Search by order ID partner name, etc"}
                columns={table_columns}
                data={
                  isCompletedOrderFetched
                    ? completedOrders?.pages.flatMap((page) =>
                        getActiveOrders(page?.orders)
                      )
                    : []
                }
                mobileComponent={OrderMobileCard}
                isLoading={!isCompletedOrderFetched}
              />
            ),
          },
        ]}
        activeTab={activeInquiryTab}
        onChange={handleInquiryTabChange}
        nested={isMobileDevice ? true : false}
      />
      {hasNextActiveOrderPage && (
        <Grid
          item
          xs={12}
          ref={activeRef}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
      {hasNextCompletedOrderPage && (
        <Grid
          item
          xs={12}
          ref={completedRef}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default MyOrders;

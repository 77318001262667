import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

import { AddressSection } from "../../transport/components/InvoiceDetailTab";
import TextInput from "../../shared/formElements/TextInput";
import WarexLogo from "../../assets/Warex-Menu-logo.png";
import { gatePassKeys } from "../../constants/formKeys";
import { gatePass_labels } from "../../constants";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

const columns = [
  "Item No.",
  "Package Name",
  "Packing Material",
  "Package Size",
  "Package Condition"
];

export const packageConditionColor = {
  Healthy: "success.dark",
  Damaged: "error.main",
  CriticallyDamaged: "error.dark"
};

const GatePass = ({
  control,
  handleSubmit,
  onSendGatePass,
  hideDialog,
  isLoading,
  fields,
  orderCode,
  outboundDate,
  customerBillingDetails,
  warehouseBillingDetails
}) => {
  return (
    <Box padding={3} display={"flex"} flexDirection={"column"} gap={"26px"}>
      <Box display={"flex"} justifyContent={"space-between"} gap={"147px"}>
        <Box display={"flex"} flexDirection={"column"} gap={"9px"}>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography fontSize={14} fontWeight={600}>
              {`Order no. ${orderCode}`}
            </Typography>
            <Typography fontSize={14} fontWeight={600}>
              {`Material Out Date : ${moment(outboundDate).format(
                "DD-MM-YYYY"
              )}`}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <TextInput
              control={control}
              name={gatePassKeys.transporter}
              label={gatePass_labels.transporter}
              placeholder={"Enter name"}
            />
            <TextInput
              control={control}
              name={gatePassKeys.truckRegNo}
              label={gatePass_labels.truckRegNo}
              placeholder={"Enter number"}
            />
            <TextInput
              control={control}
              name={gatePassKeys.gatepassPreparedBy}
              label={gatePass_labels.gatepassPreparedBy}
              placeholder={"Enter name"}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"28px"}
          alignSelf={"center"}
          alignItems={"center"}
        >
          <Typography fontSize={24} color={"text.secondary"} fontWeight={500}>
            Outbound Gate Pass
          </Typography>
          <img
            src={WarexLogo}
            height={47}
            width={107}
            alt="warex"
            style={{
              objectFit: "contain"
            }}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={5}
          textAlign={"right"}
        >
          <AddressSection
            title={"Customer / Buyer"}
            name={`${customerBillingDetails?.firstName} ${customerBillingDetails?.lastName}`}
            addressLine1={`${customerBillingDetails?.firmName}`}
            addressLine2={`${customerBillingDetails?.address?.area}, ${customerBillingDetails?.address?.city}, ${customerBillingDetails?.address?.state} ${customerBillingDetails?.address?.pincode}`}
            addressLine3={`GSTIN : ${customerBillingDetails?.gstNo}`}
          />
          <AddressSection
            title={"Storage Facility"}
            name={warehouseBillingDetails?.name}
            addressLine1={`${warehouseBillingDetails?.address?.area}, ${warehouseBillingDetails?.address?.pincode}`}
            addressLine2={`GSTIN : ${warehouseBillingDetails?.gstNo}`}
          />
        </Box>
      </Box>
      <Box
        sx={{
          border: "0.7px solid #B7BEC7",
          borderRadius: 2,
          overflow: "hidden",
          display: {
            xs: "none",
            md: "flex"
          }
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ background: "#D4E3FF80", padding: "0px 24px" }}>
              <Grid container spacing={3}>
                {columns.map((item) => (
                  <Grid item xs={12 / columns.length} key={item}>
                    <Box paddingTop={"12px"} paddingBottom={"12px"}>
                      <Typography
                        whiteSpace={"nowrap"}
                        fontSize={14}
                        fontWeight={600}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ padding: 3 }}>
              <Grid container spacing={3}>
                {fields.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={2.4}>
                      <Box>
                        <Typography whiteSpace={"nowrap"} fontSize={14}>
                          {`${index + 1}.`}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box>
                        <Typography whiteSpace={"nowrap"} fontSize={14}>
                          {item.packageName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box>
                        <Typography whiteSpace={"nowrap"} fontSize={14}>
                          {item.packagingMaterial}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box>
                        <Typography whiteSpace={"nowrap"} fontSize={14}>
                          {item.packageSize}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box>
                        <Typography
                          whiteSpace={"nowrap"}
                          fontSize={14}
                          color={packageConditionColor[item.packageCondition]}
                        >
                          {item.packageCondition}
                        </Typography>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display={"flex"} gap={0.5}>
        <Typography fontSize={14}>Number of Packages :</Typography>
        <Typography fontSize={14} fontWeight={700}>
          {String(fields.length).padStart(2, "0")}
        </Typography>
      </Box>
      <Box display={"flex"} gap={0.5} marginTop={"6px"}>
        <Typography fontSize={14}>Prepared time :</Typography>
        <Typography fontSize={14} fontWeight={700}>
          {moment(new Date()).format("HH.mm")}
        </Typography>
      </Box>
      <Box display={"flex"} gap={"10px"} justifyContent={"flex-end"}>
        <Button
          variant="soft"
          sx={{ height: 64, width: 206 }}
          onClick={hideDialog}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          component={LoadingButton}
          loading={isLoading}
          sx={{ height: 64, width: 206 }}
          onClick={handleSubmit(onSendGatePass)}
        >
          Send Gatepass
        </Button>
      </Box>
    </Box>
  );
};

export default GatePass;

import { client } from "../../shared/api/client";

export const fetchTransportFormData = async (partnerId) => {
  return await client
    .get(`/transports/${partnerId}/complete/form-data`)
    .then((res) => res.data.data);
};

export const addTransporter = async (partnerId, formData) => {
  return await client.put(`/transports/${partnerId}/complete`, formData);
};

export const fetchTransportDetails = async (partnerId) => {
  return await client
    .get(`/transports/${partnerId}`)
    .then((res) => res.data.data);
};

export const updateAdditionalServices = async (partnerId, data) => {
  return await client.put(`/transports/${partnerId}/additional-services`, data);
};

export const updateTruck = async (partnerId, data, vehicleId) => {
  return await client.put(
    `/transports/${partnerId}/vehicles/${vehicleId}/rate`,
    data
  );
};

export const getActiveTransportInquiry = async (page = 1) => {
  return await client
    .get(`/transports/inquiries/active?page=${page}`)
    .then((res) => res.data.data);
};

export const getClosedTransportInquiry = async (page = 1) => {
  return await client
    .get(`/transports/inquiries/closed?page=${page}`)
    .then((res) => res.data.data);
};

export const getActiveOrders = async (page = 1) => {
  return await client
    .get(`/transports/orders/active?page=${page}`)
    .then((res) => res.data.data);
};

export const getCompletedOrders = async (page = 1) => {
  return await client
    .get(`/transports/orders/completed?page=${page}`)
    .then((res) => res.data.data);
};

export const getTransportInquiryData = async (inquiryId) => {
  return await client
    .get(`/transports/inquiries/${inquiryId}`)
    .then((res) => res.data.data);
};

export const getTransportInquiryQuoteFormData = async (inquiryId) => {
  return await client
    .get(`/transports/inquiries/${inquiryId}/quote/form-data`)
    .then((res) => res.data.data);
};

export const sendTransportQuote = async (inquiryId, data) => {
  return await client.put(`/transports/inquiries/${inquiryId}/quote`, data);
};

export const getTransportRegretFormData = async (inquiryId) => {
  return await client
    .get(`/transports/inquiries/${inquiryId}/regret/form-data`)
    .then((res) => res.data.data);
};

export const regretTransport = async (inquiryId, data) => {
  return await client.put(`/transports/inquiries/${inquiryId}/regret`, data);
};

export const fetchTransportOrderDetails = async (orderId) => {
  return await client
    .get(`/transports/orders/${orderId}`)
    .then((res) => res.data.data);
};

export const fetchVehicles = async (orderId) => {
  return await client
    .get(`/transports/orders/${orderId}/drivers/form-data`)
    .then((res) => res.data.data);
};

export const assignDriver = async (orderId, data) => {
  return await client.put(`/transports/orders/${orderId}/drivers`, data);
};

export const getTransportInvoices = async (page = 1) => {
  return await client
    .get(`/transports/invoices?page=${page}`)
    .then((res) => res.data.data);
};

export const getTransportInvoicesFormData = async (orderId) => {
  return await client
    .get(`/transports/invoices/form-data?orderId=${orderId}`)
    .then((res) => res.data.data);
};

export const createTransportInvoice = async (formData) => {
  return await client.post(`/transports/invoices`, formData);
};

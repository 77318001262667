import * as yup from "yup";

import { Add, Remove } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import {
  SuccessAlert,
  SuccessRegret
} from "../../warehouse/pages/ActiveInquiry";
import { labels, statusKeys, warehouse_labels } from "../../constants";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import DropDown from "../../shared/formElements/DropDown";
import InquiryDetails from "../components/InquiryDetails.js";
import { LoadingButton } from "@mui/lab";
import RegretForm from "../../shared/UiElements/ReasonForm";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { StyledChip } from "../../shared/UiElements/StatusChip.js";
import TextInput from "../../shared/formElements/TextInput";
import { currencyFormatter } from "../../shared/utils";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { quotation_transport_labels } from "./constant";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchTransportInquiry } from "../../query-hooks/TransportInquiry/useFetchTransportInquiry.js";
import { useFetchTransportInquiryQuoteFormData } from "../../query-hooks/TransportInquiry/useFetchTransportInquiryQuoteFormData";
import { useFetchTransportRegretForm } from "../../query-hooks/TransportInquiry/useFetchTransportRegretForm.js";
import { useRegretTransportMutateHook } from "../../query-hooks/TransportInquiry/useRegretTransportMutateHook.js";
import { useSendTransportQuoteMutateHook } from "../../query-hooks/TransportInquiry/useSendTransportQuoteMutateHook.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";

const quoteFormSchema = yup
  .object({
    quotes: yup.array().of(
      yup.object().shape({
        rate: yup.string().required("Rate is required"),
        quantity: yup.string().required("Quantity is required")
      })
    )
  })
  .required();

const StyledButton = styled((props) => (
  <Button
    {...props}
    sx={{
      height: 48,
      whiteSpace: "nowrap",
      fontSize: 15,
      fontWeight: 600
    }}
  />
))``;

const ActiveInquiry = () => {
  const { inquiryId } = useParams();
  const navigate = useNavigate();

  const partnerId = Storage.getItem(StorageKeys.PARTNER_ID);

  const { control, handleSubmit, watch, setValue, reset, getValues } = useForm({
    defaultValues: {
      quotes: [],
      subTotal: "",
      total: "",
      gst: ""
    },
    resolver: yupResolver(quoteFormSchema)
  });

  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quotes"
  });

  const quotesWatch = watch("quotes");

  const { data, isFetching, isFetched } = useFetchTransportInquiry(inquiryId);
  const { data: otherData } = useFetchTransportInquiryQuoteFormData(inquiryId);
  const { data: regretFormData } = useFetchTransportRegretForm(inquiryId);
  const { mutate, isLoading, isError, error } =
    useSendTransportQuoteMutateHook();

  const {
    mutate: regretInquiry,
    isLoading: isRegretInquiryLoading,
    isError: isRegretInquiryError,
    error: regretInquiryError,
    reset: resetRegretInquiry
  } = useRegretTransportMutateHook();

  const partner = data?.inquiry?.transportRfq.partners?.find(
    (item) => item.partner === partnerId
  );

  const quote = partner?.quote;

  const appendQuoteForm = useCallback(
    (quote) => {
      append({
        label: "Transport Services",
        truckType: `${quote.services[0].vehicle.model.displayName} ${quote.services[0].vehicle.weightCapacity} Kg`,
        uom: `Price / ${quote.services[0].rateUnit.toUpperCase()}`,
        name: "Transport",
        uomValue: quote.services[0].rateUnit,
        quantity: `${parseFloat(
          quote.services[0].quantity.$numberDecimal
        ).toFixed(2)} ${quote.services[0].rateUnit}s`,
        quantityValue: quote.services[0].quantity.$numberDecimal,
        rate: quote.services[0].rate.$numberDecimal,
        total: parseFloat(quote.services[0].totalAmount.$numberDecimal).toFixed(
          2
        ),
        options: [
          {
            displayName: `${quote.services[0].vehicle.model.displayName} ${quote.services[0].vehicle.weightCapacity} Kg`,
            name: `${quote.services[0].vehicle.model.displayName} ${quote.services[0].vehicle.weightCapacity} Kg`
          }
        ]
      });

      quote.additionalServices.forEach((item) => {
        append({
          label: item.displayName,
          uom: `Price / ${item.rateUnit}`,
          name: item.name,
          quantity: `${item.quantity.$numberDecimal} Kg`,
          quantityValue: item.quantity.$numberDecimal,
          rate: item.rate.$numberDecimal,
          total: parseFloat(item.totalAmount.$numberDecimal).toFixed(2),
          options: [
            {
              displayName: `Price / ${item.rateUnit}`,
              name: `Price / ${item.rateUnit}`
            }
          ]
        });
      });

      if (quote.otherServices.length > 0)
        append({
          label: quote.otherServices[0].displayName,
          uom: quote.otherServices[0].rateUnit,
          name: quote.otherServices[0].name,
          quantity: quote.otherServices[0].quantity.$numberDecimal,
          rate: quote.otherServices[0].rate.$numberDecimal,
          total: quote.otherServices[0].totalAmount.$numberDecimal,
          options: [
            {
              displayName: quote.otherServices[0].rateUnit,
              name: quote.otherServices[0].rateUnit
            }
          ]
        });
    },
    [append]
  );

  useEffect(() => {
    if (isFetched) {
      if (quote) {
        appendQuoteForm(quote);
      }
    }
  }, [appendQuoteForm, isFetched, quote]);

  useEffect(() => {
    if (quotesWatch) {
      quotesWatch.forEach((item, index) => {
        const rate = item.rate;
        const quantity =
          item.name === "Other" ? item.quantity : item.quantityValue;
        index > 0 && item.name !== "Other"
          ? setValue(
              `quotes.${index}.total`,
              parseFloat((+rate * +quantity) / 100).toFixed(2)
            )
          : setValue(
              `quotes.${index}.total`,
              parseFloat(+rate * +quantity).toFixed(2)
            );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(quotesWatch)]);

  const addOtherData = () => {
    const otherQuote = otherData.otherServices[0];
    append({
      label: otherQuote.displayName,
      uom: otherQuote.rateUnit,
      name: otherQuote.name,
      quantity: "",
      rate: "",
      total: "",
      options: [
        {
          displayName: otherQuote.rateUnit,
          name: otherQuote.rateUnit
        }
      ]
    });
  };

  const handleQuote = (data) => {
    mutate(
      {
        inquiryId,
        data: {
          quote: {
            services: [
              {
                name: data.quotes[0].name,
                rate: data.quotes[0].rate
              }
            ],
            additionalServices: data.quotes
              .filter((item, index) => index > 0 && item.name !== "Other")
              .map((item) => {
                return {
                  name: item.name,
                  rate: item.rate
                };
              }),

            otherServices: data.quotes
              .filter((item) => item.name === "Other")
              .map((item) => {
                return {
                  name: item.name,
                  rate: item.rate,
                  quantity: item.quantity
                };
              })
          }
        }
      },
      {
        onSuccess: () => {
          if (isMobile) {
            showDrawer({
              component: (
                <SuccessAlert
                  goBack={() => {
                    hideDrawer();
                    navigate("/inquiry");
                  }}
                />
              ),
              closeIcon: false
            });
          } else {
            showDialog({
              component: (
                <SuccessAlert
                  hideDialog={hideDialog}
                  goBack={() => {
                    hideDialog();
                    navigate("/inquiry");
                  }}
                />
              ),
              backdropOff: true
            });
          }
        }
      }
    );
  };

  const handleRegret = (data, openDialog, closeDialog) => {
    regretInquiry(
      {
        inquiryId,
        data: {
          reason: data.regretReason,
          ...{
            ...(data.otherReason ? { otherReason: data.otherReason } : {})
          }
        }
      },
      {
        onSuccess: () => {
          openDialog({
            component: (
              <SuccessRegret
                btn2Callback={() => {
                  closeDialog();
                  navigate("/inquiry");
                }}
              />
            )
          });
        }
      }
    );
  };

  const subTotal = parseFloat(
    getValues("quotes")?.reduce(function (previousTotal, currentTotal) {
      return previousTotal + +currentTotal.total;
    }, 0)
  ).toFixed(2);

  const gst = parseFloat(
    (subTotal * partner?.quote?.taxes[0]?.rate?.$numberDecimal) / 100
  ).toFixed(2);

  useEffect(() => {
    if (isError || isRegretInquiryError) {
      enqueueSnackbar(
        error?.response.data.message ||
          regretInquiryError?.response.data.message,
        { variant: "error" }
      );
      if (isRegretInquiryError) {
        resetRegretInquiry();
      }
    }
  }, [
    error?.response.data.message,
    regretInquiryError?.response.data.message,
    isError,
    isRegretInquiryError,
    resetRegretInquiry
  ]);

  return isFetching ? (
    <Backdrop
      sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      sx={{
        padding: {
          xs: "24px 16px",
          sm: 0
        }
      }}
      marginBottom={2}
    >
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        sx={{
          marginBottom: {
            xs: 3,
            md: "15px"
          }
        }}
      >
        <Box
          display={"flex"}
          gap={"19px"}
          alignItems={"flex-start"}
          flexGrow={1}
          sx={{
            justifyContent: {
              xs: "space-between",
              md: "flex-start"
            }
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: 14,
                  md: 20
                },
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.002em"
              }}
              gutterBottom
            >
              {`${warehouse_labels.inquiryIdTitleText} :  ${data.inquiry.code}`}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px"
              }}
              color={"text.secondary"}
            >
              {`${warehouse_labels.inquiryDateTitleText} : ${moment(
                data.inquiry.createdAt
              ).format("DD MMMM YYYY")}`}
            </Typography>
          </Box>
          <StyledChip label={partner.status.name} />
        </Box>
      </Box>
      <Box marginBottom={5}>
        <InquiryDetails
          searchForm={data.inquiry.searchForm}
          transportRfq={data.inquiry.transportRfq}
          status={partner.status.name}
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={3} marginBottom={3}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0.005em"
          }}
        >
          {warehouse_labels.reviseQuotationTitleText}
        </Typography>
        <Box sx={{ overflowX: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2} flexWrap={"nowrap"}>
              <Grid
                item
                xs={2}
                minWidth={140}
                sx={{
                  background: {
                    xs: "#F0F2F5",
                    md: "transparent"
                  }
                }}
              />
              {quotation_transport_labels.map((item, index) => (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    alignItems={"flex-end"}
                    minWidth={140}
                  >
                    <Typography fontSize={14} fontWeight={500}>
                      {item}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
            {fields.map((item, index) => (
              <Grid
                item
                xs={12}
                container
                spacing={2}
                key={item.id}
                flexWrap={"nowrap"}
              >
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  alignItems={"center"}
                  minWidth={140}
                  sx={{
                    background: {
                      xs: "#F0F2F5",
                      md: "transparent"
                    }
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    sx={{
                      paddingLeft: {
                        xs: "15px",
                        md: 0
                      }
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  {item.truckType && (
                    <DropDown
                      control={control}
                      name={`quotes.${index}.truckType`}
                      options={item.options}
                      disabled={true}
                    />
                  )}
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  {index === 0 ? (
                    <TextInput
                      control={control}
                      name={`quotes.${index}.uom`}
                      disabled={true}
                    />
                  ) : (
                    <DropDown
                      control={control}
                      name={`quotes.${index}.uom`}
                      options={item.options}
                      disabled={true}
                    />
                  )}
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    control={control}
                    name={`quotes.${index}.quantity`}
                    disabled={
                      partner?.status?.name === statusKeys.Pending &&
                      index === quote.additionalServices.length + 1
                        ? false
                        : true
                    }
                    type={item.quantity === "" ? "number" : "text"}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    startIcon={<Box padding={"0 0 0 16px"}>₹</Box>}
                    control={control}
                    name={`quotes.${index}.rate`}
                    type="number"
                    disabled={partner.status.name !== statusKeys.Pending}
                  />
                </Grid>
                <Grid item xs={2} minWidth={140}>
                  <TextInput
                    startIcon={<Box padding={"0 0 0 16px"}>₹</Box>}
                    control={control}
                    name={`quotes.${index}.total`}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider
          sx={{
            borderColor: "#B7BEC7",
            display: {
              xs: "none",
              md: "block"
            }
          }}
        />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row"
            }
          }}
          gap={3}
        >
          {partner.status.name === statusKeys.Pending &&
            quote.otherServices.length < 1 && (
              <Box>
                {fields.length === quote.additionalServices.length + 1 ? (
                  <Button
                    variant="text"
                    startIcon={<Add />}
                    onClick={addOtherData}
                  >
                    {warehouse_labels.addMoreItemsButtonText}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    startIcon={<Remove />}
                    color="error"
                    onClick={() => remove(fields.length - 1)}
                  >
                    {`Remove ${otherData?.otherServices?.[0].displayName}`}
                  </Button>
                )}
              </Box>
            )}
          <Divider
            sx={{
              borderColor: "#B7BEC7",
              width: "100%",
              display: {
                xs: "block",
                md: "none"
              }
            }}
          />
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "auto"
              }
            }}
            display={"flex"}
            flexDirection={"column"}
            gap={"12px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"47px"}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                whiteSpace={"nowrap"}
                textAlign={"right"}
              >
                Subtotal
              </Typography>
              <Typography
                fontSize={14}
                whiteSpace={"nowrap"}
                textAlign={"left"}
              >
                {currencyFormatter(subTotal)}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"47px"}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                whiteSpace={"nowrap"}
                textAlign={"right"}
              >
                {`${partner?.quote?.taxes[0]?.name} @ ${partner?.quote?.taxes[0]?.rate?.$numberDecimal} %`}
              </Typography>
              <Typography
                fontSize={14}
                whiteSpace={"nowrap"}
                textAlign={"left"}
              >
                {currencyFormatter(gst)}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"47px"}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={14}
                fontWeight={700}
                whiteSpace={"nowrap"}
                textAlign={"right"}
              >
                {labels.totalAmountText}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={600}
                whiteSpace={"nowrap"}
                textAlign={"left"}
              >
                {currencyFormatter(parseFloat(+subTotal + +gst).toFixed(2))}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          borderColor: "#B7BEC7",
          width: "100%",
          marginBottom: "14px"
        }}
      />
      {partner.status.name === statusKeys.Pending && (
        <Box
          display={"flex"}
          gap={"10px"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row"
            }
          }}
          justifyContent={"flex-end"}
        >
          <StyledButton
            variant="softError"
            sx={{
              width: {
                xs: "100%",
                md: 140
              }
            }}
            onClick={() => {
              if (isMobile) {
                showDrawer({
                  component: (
                    <RegretForm
                      title={warehouse_labels.regretInquiryTitleText}
                      subText={warehouse_labels.regretInquirySubText}
                      btn1Text={warehouse_labels.yesRegretButtonText}
                      btn2Text={warehouse_labels.cancelButtonText}
                      btn1Callback={(data) =>
                        handleRegret(data, showDrawer, hideDrawer)
                      }
                      btn2Callback={hideDrawer}
                      reasons={regretFormData.reasons}
                      isBtn1Loading={isRegretInquiryLoading}
                    />
                  ),
                  closeIcon: false
                });
              } else {
                showDialog({
                  component: (
                    <RegretForm
                      title={warehouse_labels.regretInquiryTitleText}
                      subText={warehouse_labels.regretInquirySubText}
                      btn1Text={warehouse_labels.yesRegretButtonText}
                      btn2Text={warehouse_labels.cancelButtonText}
                      btn1Callback={(data) =>
                        handleRegret(data, showDialog, hideDialog)
                      }
                      btn2Callback={hideDialog}
                      reasons={regretFormData.reasons}
                      isBtn1Loading={isRegretInquiryLoading}
                    />
                  ),
                  backdropOff: true
                });
              }
            }}
          >
            {warehouse_labels.regretButtonText}
          </StyledButton>
          <StyledButton
            variant="soft"
            sx={{
              width: {
                xs: "100%",
                md: 140
              }
            }}
            onClick={() => {
              reset();
              appendQuoteForm(quote);
            }}
          >
            {warehouse_labels.resetChangesButtonText}
          </StyledButton>
          <StyledButton
            component={LoadingButton}
            variant="contained"
            sx={{
              width: {
                xs: "100%",
                md: 182
              }
            }}
            onClick={handleSubmit(handleQuote)}
            loading={isLoading}
          >
            {warehouse_labels.sendQuoteButtonText}
          </StyledButton>
        </Box>
      )}
    </Box>
  );
};

export default ActiveInquiry;

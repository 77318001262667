export const SearchFormKeys = {
  IsTransportServiceNeeded: "isTransportServiceNeeded",
  PickupLocation: "pickupLocation",
  Destination: "destination",
  InboundDate: "inboundDate",
  OutboundDate: "outboundDate",
  MaterialDetails: "materialDetails",
  PackagingType: "packagingType",
  ItemDescription: "itemDescription",
  Dimension: "dimension",
  Units: "units",
  StorageType: "storageType",
  AdditionalFeature: "additionalFeature",
  ConfirmPickupLocation: "confirmPickupLocation",
  loginMobileNumber: "loginMobileNumber",
  otp: "otp",
  pallets: "pallets",
  totalWeight: "totalWeight",
  PickUpDate: "pickUpDate"
};

export const contactUsFormKeys = {
  name: "name",
  number: "number",
  email: "email",
  subject: "subject",
  description: "description"
};

export const warehouseSearchFormKeys = {
  warehouseName: "warehouseName",
  establishedYear: "establishedYear",
  warehouseAddress: "warehouseAddress",
  warehouseLocation: "warehouseLocation",
  warehouseCity: "warehouseCity",
  warehouseState: "warehouseState",
  warehouseZipCode: "warehouseZipCode",
  billingAddress: "billingAddress",
  gstInNumber: "gstInNumber",
  warehouseDescription: "warehouseDescription",
  totalStorageSpace: "totalStorageSpace",
  availableSpace: "availableSpace",
  storageTypeAllowed: "storageTypeAllowed",
  storageTypes: "storageTypes",
  materialDetails: "materialDetails",
  additionalServices: "additionalServices",
  additionalServicesFields: "additionalServicesFields",
  features: "features",
  useSameBillingAddress: "useSameBillingAddress",
  warehouseImages: "warehouseImages",
  lat: "lat",
  lng: "lng"
};

export const transportSearchFormKeys = {
  transportName: "transportName",
  establishedYear: "establishedYear",
  transportAddress: "transportAddress",
  transportLocation: "transportLocation",
  transportCity: "transportCity",
  transportState: "transportState",
  transportZipCode: "transportZipCode",
  billingAddress: "billingAddress",
  gstInNumber: "gstInNumber",
  gstRegime: "gstRegime",
  transportDescription: "transportDescription",
  additionalServices: "additionalServices",
  useSameBillingAddress: "useSameBillingAddress",
  truckNumber: "truckNumber",
  truckMake: "truckMake",
  truckModel: "truckModel",
  pricePerKm: "pricePerKm",
  truckImage: "truckImage",
  additionalServicesFields: "additionalServicesFields",
  lat: "lat",
  lng: "lng",
  trucks: "trucks"
};

export const StorageFormKeys = {
  available: "available",
  total: "total",
  types: "types"
};

export const regretFormKeys = {
  regretReason: "regretReason",
  otherReason: "otherReason"
};

export const TableFieldKeys = {
  search: "search"
};

export const AdditionalPricesFormKeys = {
  additionalServices: "additionalServices"
};

export const TruckFormKeys = {
  truckNumber: "truckNumber",
  truckMake: "truckMake",
  truckModel: "truckModel",
  pricePerKm: "pricePerKm"
};

export const ProfileFormKeys = {
  FirstName: "firstName",
  LastName: "lastName",
  Phone: "phone",
  Email: "email",
  FirmsName: "firmsName",
  GSTIN: "gstin",
  NatureOfBusiness: "natureOfBusiness",
  Products: "products",
  Brands: "brands",
  Street: "street",
  City: "city",
  State: "state",
  Country: "country",
  ZipCode: "zipCode",
  OldPassword: "Old Password",
  NewPassword: "New Password",
  ConfirmPassword: "Confirm Password"
};

export const AssignDriverKeys = {
  trucks: "trucks",
  driverName: "driverName",
  driverNumber: "driverNumber",
  pickupDate: "pickupDate",
  pickupTime: "pickupTime"
};

export const ForgotPasswordKeys = {
  emailId: "emailId",
  resetCode: "resetCode",
  newPassword: "newPassword",
  confirmPassword: "confirmPassword"
};

export const InvoiceUploadKeys = {
  invoiceId: "invoiceId",
  invoiceDate: "invoiceDate",
  manualInvoice: "manualInvoice"
};

export const gatePassKeys = {
  transporter: "Transporter",
  truckRegNo: "truckRegNo",
  gatepassPreparedBy: "gatepassPreparedBy"
};

export const MrrUploadKeys = {
  manualMrr: "manualMrr",
  mrrId: "mrrId",
  receivedDate: "receivedDate",
  receivedTime: "receivedTime"
};

import { QueryKeys } from "../../constants/query-keys";
import { fetchTransportDetails } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportDetailsQuery = (partnerId, isEnabled = true) => {
  const transportDetailQuery = useQuery(
    [QueryKeys.queryTransportDetail, partnerId],
    () => fetchTransportDetails(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(partnerId) && isEnabled
    }
  );
  return transportDetailQuery;
};

import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import { transport_labels } from "../../constants";

const TruckDetailCard = ({
  imageSrc,
  truckNumber,
  truckModel,
  truckMake,
  pricePerKm,
  onEdit,
  onDelete,
}) => {
  return (
    <Box
      sx={{
        padding: "12px 16px 14px 12px",
        border: "0.6px solid #B7BEC7",
        borderRadius: "8px",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        height: "fit-content",
      }}
    >
      {imageSrc && (
        <Box
          overflow={"hidden"}
          borderRadius={2}
          width={"100%"}
          display={"flex"}
          sx={{
            marginRight: {
              xs: 0,
              md: "12px",
            },
            maxWidth: {
              xs: "100%",
              md: 180,
            },
            marginBottom: {
              xs: "12px",
              md: 0,
            },
          }}
        >
          <img
            src={
              typeof imageSrc === "string"
                ? imageSrc
                : URL.createObjectURL(imageSrc)
            }
            alt={truckNumber}
            height={120}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </Box>
      )}
      <Box width={"100%"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography fontSize={14} fontWeight={700} lineHeight={"17px"}>
            {truckNumber}
          </Typography>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            {onEdit && (
              <IconButton size="small" onClick={onEdit}>
                <Edit color="primary" fontSize="small" />
              </IconButton>
            )}
            {onDelete && (
              <IconButton size="small" onClick={onDelete}>
                <Delete color="error" fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box marginTop={"6px"}>
          <Typography
            fontSize={14}
            fontWeight={400}
            lineHeight={"20px"}
            color={"text.secondary"}
          >
            {truckModel}
          </Typography>
        </Box>
        <Divider sx={{ mt: "22px", mb: 2 }} />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            fontSize={14}
            fontWeight={400}
            lineHeight={"20px"}
            color={"text.secondary"}
          >
            {truckMake}
          </Typography>
          <Box display={"flex"} alignItems={"baseline"} gap={"2px"}>
            <Typography
              fontSize={14}
              fontWeight={400}
              lineHeight={"19px"}
              color={"text.secondary"}
              letterSpacing={"0.005em"}
              textAlign={"right"}
            >
              {transport_labels.truckCardPriceText}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight={"19px"}
              color={"text.primary"}
              letterSpacing={"0.005em"}
              textAlign={"right"}
            >
              {pricePerKm}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TruckDetailCard;

import { QueryKeys } from "../../constants/query-keys";
import { getCompletedOrders } from "../../transport/actions";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchCompletedTransportOrdersQuery = () => {
  const completedOrders = useInfiniteQuery(
    [QueryKeys.queryCompletedOrders],
    ({ pageParam = 1 }) => getCompletedOrders(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.orders.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );
  return completedOrders;
};

import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography
} from "@mui/material";
import DateInput from "../../shared/formElements/DateInput";
import TextInput from "../../shared/formElements/TextInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PdfAndImageUpload from "../../shared/formElements/PdfAndImageUpload";
import { useForm } from "react-hook-form";
import { MrrUploadKeys } from "../../constants/formKeys";
import { MrrUpload_labels, warehouse_labels } from "../../constants";
import moment from "moment";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { isMobile } from "react-device-detect";
import { Close } from "@mui/icons-material";
import { useUploadMrr } from "../../query-hooks/warehousePackagingNote/useUploadMrr";

export const UploadSchema = yup.object({
  [MrrUploadKeys.mrrId]: yup
    .string()
    .required(`MRR ID ${warehouse_labels.isRequired}`),
  [MrrUploadKeys.receivedDate]: yup
    .string()
    .required(`Received Date ${warehouse_labels.isRequired}`),
  [MrrUploadKeys.receivedTime]: yup
    .string()
    .required(`Received Time ${warehouse_labels.isRequired}`),
  [MrrUploadKeys.manualMrr]: yup
    .mixed()
    .required("PDF file is required")
    .test(
      "fileType",
      "Kindly Upload images (jpg, jpeg, png) or PDF ",
      (value) => {
        const supportedFileTypes = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf"
        ];
        return supportedFileTypes.includes(value.type);
      }
    )
});

const UploadMrr = ({ orderId }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(UploadSchema)
  });

  const { showDialog, hideDialog } = useCustomDialog();
  const { hideDrawer } = useDrawer();
  const navigate = useNavigate();

  const {
    mutate: UploadMrr,
    isLoading: isMrrUploading,
    isError: isMrrError,
    error: mrrError
  } = useUploadMrr();

  const onMrrSubmit = (data) => {
    const mrrData = new FormData();
    mrrData.append("mrrId", data.mrrId);
    mrrData.append(
      "receivedDate",
      `${moment(data.receivedDate).format("YYYY-MM-DD")} ${moment(
        data.receivedTime
      ).format("HH:mm:ss")}`
    );
    mrrData.append("file", data.manualMrr);
    UploadMrr(
      { orderId: orderId, data: mrrData },
      {
        onSuccess: () => {
          if (isMobile) {
            enqueueSnackbar("Mrr Uploaded SuccessFully", {
              variant: "success"
            });
            hideDrawer();
          } else {
            hideDialog();
            showDialog({
              component: (
                <SuccessfulDialog
                  text={"MRR submitted successfully!"}
                  subText={"Order is in Stored Status now."}
                  btn2Text={"Go to Stored Orders"}
                  btn2Callback={() => {
                    hideDialog();
                    navigate("/orders/my-orders");
                  }}
                />
              )
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    if (isMrrError) {
      enqueueSnackbar(mrrError?.response?.data?.message, { variant: "error" });
    }
  }, [mrrError?.response?.data?.message, isMrrError]);

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onMrrSubmit)}
      display={"flex"}
      flexDirection={"column"}
      gap={"17px"}
      sx={{ padding: { xs: "20px" } }}
    >
      <Box display={"flex"} justifyContent={"space-between"} padding={"10px"}>
        <Typography fontSize={16} fontWeight={600} letterSpacing={"0.08px"}>
          Upload MRR
        </Typography>
        {isMobile && (
          <IconButton onClick={() => hideDrawer()}>
            <Close />
          </IconButton>
        )}
      </Box>
      <Box display={"flex"} gap={3}>
        {!isMobile && (
          <Box
            sx={{
              background: "#F3F5F9",
              width: 360,
              height: "auto",
              padding: "25px 16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2
            }}
          >
            <PdfAndImageUpload
              control={control}
              name={MrrUploadKeys.manualMrr}
            />
            <FormHelperText error>{errors.manualMrr?.message}</FormHelperText>
          </Box>
        )}
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          minWidth={356}
        >
          <TextInput
            control={control}
            name={MrrUploadKeys.mrrId}
            label={MrrUpload_labels.mrrId}
            placeholder={"Enter ID"}
          />
          <DateInput
            control={control}
            name={MrrUploadKeys.receivedDate}
            label={MrrUpload_labels.receivedDate}
            placeholder={"Date Picker"}
            minDate={"1900-01-01"}
          />
          <DateInput
            control={control}
            name={MrrUploadKeys.receivedTime}
            label={MrrUpload_labels.receivedTime}
            placeholder={"Time Picker"}
            showTimeSelectOnly={true}
            showTimeSelect={true}
            timeIntervals={15}
            format="h:mm aa"
          />
          {isMobile && (
            <Box
              sx={{
                background: "#F3F5F9",
                width: "100%",
                height: "auto",
                padding: "25px 16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2
              }}
            >
              <PdfAndImageUpload
                control={control}
                name={MrrUploadKeys.manualMrr}
              />
              <FormHelperText error>{errors.manualMrr?.message}</FormHelperText>
            </Box>
          )}
          <Box display={"flex"} gap={"12px"} marginTop={5}>
            <Button
              fullWidth
              variant="soft"
              sx={{ height: 42 }}
              onClick={() => reset()}
            >
              Reset
            </Button>
            <Button
              fullWidth
              variant="contained"
              component={LoadingButton}
              loading={isMrrUploading}
              sx={{ height: 42 }}
              onClick={handleSubmit(onMrrSubmit)}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadMrr;

import {
  Box,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";

import { Edit } from "@mui/icons-material";
import { StyledBox } from "./DashboardListItem";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import { warehouse_labels } from "../../constants";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: "100px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.divider,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: value === "100" ? "100px" : "100px 200px 0 100px",
    backgroundColor: theme.palette.error.main,
  },
}));

const AvailableSpaceCard = ({
  totalStorageSpace,
  availableSpace,
  usedUpSpace,
  handleAreaEdit,
}) => {
  return (
    <StyledBox>
      <Box
        sx={{
          marginBottom: {
            xs: 3,
            md: 4,
          },
        }}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          sx={{
            flexDirection: {
              xs: "column-reverse",
              md: "column",
            },
            gap: {
              xs: 0.5,
              md: 1,
            },
          }}
        >
          <StyledResponsiveText
            color="text.secondary"
            desktop={{
              fontSize: 14,
              lineHeight: "20px",
            }}
            mobile={{
              fontSize: 13,
              lineHeight: "18px",
            }}
          >
            {warehouse_labels.storageSpaceLabelText}
          </StyledResponsiveText>
          <StyledResponsiveText
            color="text.primary"
            desktop={{
              fontSize: 16,
              lineHeight: "19px",
              fontWeight: 600,
              letterSpacing: "0.005em",
            }}
            mobile={{
              fontSize: 14,
              lineHeight: "normal",
              fontWeight: 600,
            }}
          >
            {`${totalStorageSpace} ${warehouse_labels.squareFeetLabelText}`}
          </StyledResponsiveText>
        </Box>
        <IconButton size="small" onClick={handleAreaEdit}>
          <Edit color="primary" fontSize="20px" />
        </IconButton>
      </Box>
      <Box
        display={"flex"}
        alignItems={"flex-start"}
        sx={{
          flexDirection: {
            xs: "column-reverse",
            md: "column",
          },
          gap: {
            xs: 0.5,
            md: 1,
          },
          mb: {
            xs: 3,
            md: "34px",
          },
        }}
      >
        <StyledResponsiveText
          color="text.secondary"
          desktop={{
            fontSize: 14,
            lineHeight: "20px",
          }}
          mobile={{
            fontSize: 13,
            lineHeight: "18px",
          }}
        >
          {warehouse_labels.availableSpaceLabelText}
        </StyledResponsiveText>
        <StyledResponsiveText
          color="success.dark"
          desktop={{
            fontSize: 16,
            lineHeight: "19px",
            fontWeight: 600,
            letterSpacing: "0.005em",
          }}
          mobile={{
            fontSize: 14,
            lineHeight: "20px",
            fontWeight: 600,
          }}
        >
          {`${availableSpace} ${warehouse_labels.squareFeetLabelText}`}
        </StyledResponsiveText>
      </Box>

      <BorderLinearProgress
        variant="determinate"
        value={usedUpSpace}
        sx={{
          mb: {
            xs: "11px",
            md: "14px",
          },
        }}
      />
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={"4px"}
      >
        <StyledResponsiveText
          color="text.secondary"
          desktop={{
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 500,
          }}
          mobile={{
            fontSize: 13,
            lineHeight: "18px",
            fontWeight: 400,
          }}
        >
          {warehouse_labels.usedUpSpaceText}
        </StyledResponsiveText>
        <StyledResponsiveText
          color="error.main"
          desktop={{
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 600,
          }}
          mobile={{
            fontSize: 13,
            lineHeight: "18px",
            fontWeight: 700,
          }}
        >
          {`${usedUpSpace}%`}
        </StyledResponsiveText>
      </Box>
    </StyledBox>
  );
};

export default AvailableSpaceCard;

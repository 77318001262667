import * as yup from "yup";

import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ProfileFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { profile_labels } from "../../constants";
import { useFetchProfileQueryHook } from "../../query-hooks/useFetchProfileQueryHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchWarehouseDetailsQuery } from "../../query-hooks/warehouseDashboard/useFetchWarehouseDetailsQuery";
import CheckBoxGroup from "../../shared/formElements/CheckBoxGroup";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

const passwordSchema = yup.object({
  [ProfileFormKeys.OldPassword]: yup
    .string()
    .required(`${profile_labels.OldPassword} ${profile_labels.isRequired}`),
  [ProfileFormKeys.NewPassword]: yup
    .string()
    .required(`${profile_labels.NewPassword} ${profile_labels.isRequired}`)
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
    ),
  [ProfileFormKeys.ConfirmPassword]: yup
    .string()
    .oneOf([yup.ref(ProfileFormKeys.NewPassword), null], "Passwords must match")
    .required(`${profile_labels.ConfirmPassword} ${profile_labels.isRequired}`),
});

const profileSchema = yup.object({
  [ProfileFormKeys.FirstName]: yup
    .string()
    .required(
      `${profile_labels.firstNameLabelText} ${profile_labels.isRequired}`
    ),
  [ProfileFormKeys.LastName]: yup
    .string()
    .required(
      `${profile_labels.lastNameLabelText} ${profile_labels.isRequired}`
    ),
});

const VerifiedPhone = () => {
  return (
    <Box display={"flex"} alignItems={"center"} px={2}>
      <CheckCircleOutlineIcon
        fontSize="16"
        sx={{ mr: "4px", cursor: "pointer", color: "#0F8B6C" }}
      />
      <Typography
        color={"#0F8B6C"}
        fontWeight={400}
        fontSize={12}
        lineHeight={"16px"}
        whiteSpace={"nowrap"}
      >
        {profile_labels.verified}
      </Typography>
    </Box>
  );
};

const Header = () => (
  <Box display={"flex"} flexDirection={"column"} gap={2}>
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 500,
          letterSpacing: "0.04px",
        }}
      >
        {"Personal / Warehouse details"}
      </Typography>
    </Box>
    <Divider sx={{ borderColor: "#B7BEC7" }} />
  </Box>
);

const onClickChangeProfile = (data) => {
  console.log(data);
};

const ProfileForm = ({ control, setValue, isEdit, onEdit, handleSubmit }) => {
  return (
    <Box
      maxWidth={573}
      display={"flex"}
      flexDirection={"column"}
      gap={"60px"}
      component={"form"}
      onSubmit={handleSubmit(onClickChangeProfile)}
    >
      <Grid container spacing={"20px"}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.FirstName}
              label={profile_labels.firstNameLabelText}
              placeholder={profile_labels.firstNamePlaceholderText}
              disabled={!isEdit}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.LastName}
              label={profile_labels.lastNameLabelText}
              placeholder={profile_labels.lastNamePlaceholderText}
              disabled={!isEdit}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.Phone}
              label={profile_labels.phoneLabelText}
              placeholder={profile_labels.phonePlaceholderText}
              startIcon={<Box padding={"0 0 0 16px"}>+91</Box>}
              endIcon={VerifiedPhone}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.Email}
              label={profile_labels.emailLabelText}
              placeholder={profile_labels.emailPlaceholderText}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.FirmsName}
              label={profile_labels.firmsNameLabelText}
              placeholder={profile_labels.firmsNamePlaceholderText}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              control={control}
              name={ProfileFormKeys.GSTIN}
              label={profile_labels.gstinLabelText}
              placeholder={profile_labels.gstinPlaceholderText}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Box display={"flex"} gap={1}>
        {isEdit ? (
          <>
            <Button
              variant="soft"
              sx={{ height: 48, width: 140 }}
              onClick={onEdit}
            >
              {profile_labels.cancel}
            </Button>
            <Button
              variant="contained"
              sx={{ height: 48, width: 140 }}
              onClick={handleSubmit(onClickChangeProfile)}
            >
              {profile_labels.saveChanges}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            sx={{ height: 48, width: 140 }}
            onClick={onEdit}
          >
            {profile_labels.edit}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const ChangePassword = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control: passwordControl,
    handleSubmit,
    reset,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const togglePassword = watch("showPassword");

  const showPassword = !togglePassword?.includes("showPassword") ? "password" : "text";

  const onClickChangePassword = async (data) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        data[ProfileFormKeys.OldPassword],
        data[ProfileFormKeys.ConfirmPassword]
      );
      setSuccess("Password Successfully Changed");
      reset();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error)
      enqueueSnackbar(
          error?.message,
        { variant: "error" }
      );
  }, [error?.message, error]);

  useEffect(() => {
    if (success)
      enqueueSnackbar(
          success,
        { variant: "success" }
      );
  }, [success]);

  return (
    <Box
      maxWidth={573}
      display={"flex"}
      flexDirection={"column"}
      gap={"60px"}
      component={"form"}
      onSubmit={handleSubmit(onClickChangePassword)}
    >
      <Box display={"flex"} flexDirection={"column"} gap={5}>
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              letterSpacing: "0.04px",
            }}
          >
            {profile_labels.changePassword}
          </Typography>
          <Divider sx={{ borderColor: "#B7BEC7" }} />
          <Box
            sx={{ maxWidth: { xs: "100%", md: "50%" } }}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <TextInput
              control={passwordControl}
              type={showPassword}
              name={ProfileFormKeys.OldPassword}
              label={profile_labels.OldPassword}
            />
            <TextInput
              control={passwordControl}
              type={showPassword}
              name={ProfileFormKeys.NewPassword}
              label={profile_labels.NewPassword}
            />
            <TextInput
              control={passwordControl}
              type={showPassword}
              name={ProfileFormKeys.ConfirmPassword}
              label={profile_labels.ConfirmPassword}
            />
            <CheckBoxGroup
              control={passwordControl}
              name={"showPassword"}
              options={[{ name: "showPassword", displayName: "Show password" }]}
              setValue={setValue}
            />
          </Box>
        </Box>
      </Box>
      <Box display={"flex"}>
        <LoadingButton
          variant="contained"
          sx={{ height: 55, width: 200 }}
          onClick={handleSubmit(onClickChangePassword)}
          loading={loading}
        >
          {profile_labels.changePassword}
        </LoadingButton>
      </Box>
    </Box>
  );
};

const WarehouseProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { control, setValue, reset, handleSubmit } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const { data: profileData, isFetching } = useFetchProfileQueryHook();

  const { data: warehouseDetail, isFetched } = useFetchWarehouseDetailsQuery(
    profileData?.user?.partner
  );

  useEffect(() => {
    if (!isFetching) {
      reset({
        [ProfileFormKeys.Phone]: profileData.user.mobile,
        [ProfileFormKeys.Email]: profileData.user.email,
      });
    }
  }, [isFetching, profileData?.user?.email, profileData?.user?.mobile, reset]);

  const handleFormEdit = () => {
    setIsEdit((prevEdit) => !prevEdit);
  };

  return (
    <Box
      maxWidth={875}
      display={"flex"}
      flexDirection={"column"}
      gap={5}
      sx={{
        padding: {
          xs: 2,
          md: "24px 0 24px 0",
        },
        margin: "auto",
      }}
    >
      <Header />
      <ProfileForm
        control={control}
        setValue={setValue}
        isEdit={isEdit}
        onEdit={handleFormEdit}
        handleSubmit={handleSubmit}
      />
      <ChangePassword />
    </Box>
  );
};

export default WarehouseProfile;

import { getWarehouseInvoices } from "../../warehouse/actions/index";
import { QueryKeys } from "../../constants/query-keys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchWarehouseInvoices = () => {
  const warehouseInvoice = useInfiniteQuery(
    [QueryKeys.queryFetchWarehouseInvoices],
    ({ pageParam = 1 }) => getWarehouseInvoices(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.invoices.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    }
  );

  return warehouseInvoice;
};

import { QueryKeys } from "../../constants/query-keys";
import { getTransportRegretFormData } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportRegretForm = (inquiryId) => {
  const transportRegretFormQuery = useQuery(
    [QueryKeys.queryTransportRegretFormData, inquiryId],
    () => getTransportRegretFormData(inquiryId),
    {
      enabled: Boolean(inquiryId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return transportRegretFormQuery;
};

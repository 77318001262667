import { createGatePass } from "../../warehouse/actions";
import { useMutation } from "@tanstack/react-query";

export const useMutateCreateGatePass = () => {
  const createGatePassMutate = useMutation(({ retrieveId, data }) =>
    createGatePass(retrieveId, data)
  );

  return createGatePassMutate;
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import { Fragment } from "react";
import { labels } from "../../constants";
import { currencyFormatter } from "../../shared/utils";
import { primaryBackground } from "../../shared/theme/globalTheme";
import WarexLogo from "../../assets/Warex-Menu-logo.png";
import { ToWords } from "to-words";
import styled from "@emotion/styled";
import { ExpandMore } from "@mui/icons-material";
import { AddressSection } from "../../transport/components/InvoiceDetailTab";
import moment from "moment";

const TableHeaderTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.primary,
  padding: 12,
  fontSize: 7,
  fontWeight: 600,
  lineHeight: "9px",
  whiteSpace: "nowrap",
  [theme.breakpoints.up("lg")]: { fontSize: 14, lineHeight: "18px" },
  "&:first-of-type": {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
}));

const TableDataTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.primary,
  padding: 12,
  fontSize: 7,
  fontWeight: 400,
  lineHeight: "11px",
  whiteSpace: "initial",
  [theme.breakpoints.up("lg")]: { fontSize: 14, lineHeight: "20px" },
  "&:first-of-type": {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
}));

const HeaderGrid = styled((props) => <Grid {...props} />)(({ theme }) => ({
  background: primaryBackground,
}));

const DetailsTable = ({
  detailsList = [],
  totalAmount,
  gstAmount,
  gstRegime,
}) => {
  const toWords = new ToWords();

  return (
    <Box>
      <HeaderGrid container>
        <Grid item xs={1.1}>
          <TableHeaderTypography>Item no.</TableHeaderTypography>
        </Grid>
        <Grid item xs={3}>
          <TableHeaderTypography>Item description</TableHeaderTypography>
        </Grid>
        <Grid item xs={1}>
          <TableHeaderTypography>UOM</TableHeaderTypography>
        </Grid>
        <Grid item xs={1.5}>
          <TableHeaderTypography>
            {detailsList[0]?.distance ? "Distance" : "Duration"}
          </TableHeaderTypography>
        </Grid>
        <Grid item xs={1}>
          {!detailsList[0]?.distance && (
            <TableHeaderTypography>Qty</TableHeaderTypography>
          )}
        </Grid>
        <Grid item xs={2.2}>
          <TableHeaderTypography>Rate</TableHeaderTypography>
        </Grid>
        <Grid item xs={2.2}>
          <TableHeaderTypography textAlign="end">Amount</TableHeaderTypography>
        </Grid>
      </HeaderGrid>
      <Grid container>
        {detailsList.map((item, index) => {
          return (
            <Fragment key={index}>
              <Grid item xs={1.1}>
                <TableDataTypography>{item.itemNo}</TableDataTypography>
              </Grid>
              <Grid item xs={3}>
                <TableDataTypography>{item.itemDesc}</TableDataTypography>
              </Grid>
              <Grid item xs={1}>
                <TableDataTypography>{item.uom}</TableDataTypography>
              </Grid>
              <Grid item xs={1.5}>
                <TableDataTypography>
                  {item.duration ? item.duration : item.distance}
                </TableDataTypography>
              </Grid>
              <Grid item xs={1}>
                <TableDataTypography>{item.quantity}</TableDataTypography>
              </Grid>
              <Grid item xs={2.2}>
                <TableDataTypography>
                  {item.rate &&
                    currencyFormatter(parseFloat(item.rate).toFixed(2))}
                </TableDataTypography>
              </Grid>
              <Grid item xs={2.2}>
                <TableDataTypography textAlign="end">
                  {currencyFormatter(parseFloat(item.totalAmount).toFixed(2))}
                </TableDataTypography>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <Grid container>
        <Grid item xs={9.8}>
          <TableDataTypography textAlign="end">
            {`GST @ ${gstRegime}%`}
          </TableDataTypography>
        </Grid>
        <Grid item xs={2.2}>
          <TableDataTypography textAlign="end">
            {currencyFormatter(parseFloat(gstAmount).toFixed(2))}
          </TableDataTypography>
        </Grid>
        <Grid item xs={12} textAlign={"-webkit-center"}>
          <Divider
            sx={{
              width: "95%",
              borderBottomWidth: "0.4px",
              borderRadius: "0.5px",
              my: 1,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TableDataTypography textAlign="start">
            {`${labels.inWord} : `}
          </TableDataTypography>
        </Grid>
        <Grid item xs={6}>
          <TableDataTypography textAlign="start">
            {toWords.convert(totalAmount, { currency: true })}
          </TableDataTypography>
        </Grid>
        <Grid item xs={4}>
          <TableHeaderTypography textAlign="end">
            {labels.total + " :  "}
            {currencyFormatter(parseFloat(totalAmount).toFixed(2))}
          </TableHeaderTypography>
        </Grid>
      </Grid>
    </Box>
  );
};

const OrderDetailTab = ({
  customerBillingDetails,
  inboundDate,
  outboundDate = "",
  partnerBillingDetails,
  orderCode,
  orderDate,
  orderDetail,
}) => {
  const addressDetails = [
    {
      title: "Customer / Buyer",
      name: `${customerBillingDetails?.firstName} ${customerBillingDetails?.lastName}`,
      addressLine1: `${customerBillingDetails?.firmName}`,
      addressLine2: `${customerBillingDetails?.address?.area}, ${customerBillingDetails?.address?.city}, ${customerBillingDetails?.address?.state} ${customerBillingDetails?.address?.pincode}`,
      addressLine3: `GSTIN : ${customerBillingDetails?.gstNo}`,
    },
    {
      title: "Service provider",
      name: partnerBillingDetails?.code,
      addressLine1: `${partnerBillingDetails?.address?.area}, ${partnerBillingDetails?.address?.pincode}`,
      addressLine2: `GSTIN : ${partnerBillingDetails?.gstNo}`,
    },
  ];

  return (
    <Box
      padding={5}
      sx={{
        borderRadius: "8px",
        border: "0.4px solid #95A1B0",
        gap: { xs: 1, md: 5 },
      }}
      display={"flex"}
      flexDirection={"column"}
    >
      {/* mobile view */}
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={3}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={4}
        >
          <Box display={"flex"}>
            <img src={WarexLogo} alt="warex" />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
              {`Order no.${orderCode}`}
            </Typography>
            <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
              {`Order date : ${moment(orderDate).format("DD-MM-yyyy")}`}
            </Typography>
            {outboundDate ? (
              <>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={"16.94px"}
                >
                  {`Inbound date: ${moment(inboundDate).format("DD-MM-yyyy")}`}
                </Typography>{" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={"16.94px"}
                >
                  {`Outbound date: ${moment(outboundDate).format(
                    "DD-MM-yyyy"
                  )}`}
                </Typography>
              </>
            ) : (
              <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
                {`PickUp date: ${moment(inboundDate).format("DD-MM-yyyy")}`}
              </Typography>
            )}
          </Box>
        </Box>
        <Box width="100%">
          {addressDetails.map((item, index) => (
            <Box width="100%" key={index}>
              <Divider sx={{ height: "0.4px", background: "#B7BEC7" }} />
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMore color="primary" />}
                  sx={{ padding: 0 }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize="14px"
                    color={"primary.dark"}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                  <AddressSection
                    name={item.name}
                    addressLine1={item.addressLine1}
                    addressLine2={item.addressLine2}
                    addressLine3={item.addressLine3}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Box>
      </Box>
      {/* desktop View */}
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <AddressSection
          title={"Customer / Buyer"}
          name={`${customerBillingDetails?.firstName} ${customerBillingDetails?.lastName}`}
          addressLine1={`${customerBillingDetails?.firmName}`}
          addressLine2={`${customerBillingDetails?.address?.area}, ${customerBillingDetails?.address?.city}, ${customerBillingDetails?.address?.state} ${customerBillingDetails?.address?.pincode}`}
          addressLine3={`GSTIN : ${customerBillingDetails?.gstNo}`}
        />
        <Box display={"flex"}>
          <img src={WarexLogo} alt="warex" />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          textAlign={"right"}
          alignSelf={"flex-start"}
        >
          <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
            {`Order no.${orderCode}`}
          </Typography>
          <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
            {`Order date : ${moment(orderDate).format("DD-MM-yyyy")}`}
          </Typography>
          {outboundDate ? (
            <>
              <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
                {`Inbound date: ${moment(inboundDate).format("DD-MM-yyyy")}`}
              </Typography>{" "}
              <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
                {`Outbound date: ${moment(outboundDate).format("DD-MM-yyyy")}`}
              </Typography>
            </>
          ) : (
            <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
              {`PickUp date: ${moment(inboundDate).format("DD-MM-yyyy")}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box></Box>
        <Box textAlign={"right"}>
          <AddressSection
            title={"Service provider"}
            name={partnerBillingDetails?.name}
            addressLine1={`${partnerBillingDetails?.address?.area}, ${partnerBillingDetails?.address?.pincode}`}
            addressLine2={`GSTIN : ${partnerBillingDetails?.gstNo}`}
          />
        </Box>
      </Box>
      <DetailsTable
        detailsList={orderDetail?.detailsList}
        gstRegime={orderDetail?.gstRegime}
        gstAmount={orderDetail?.gstAmount}
        totalAmount={orderDetail?.totalAmount ?? 0}
      />
    </Box>
  );
};

export default OrderDetailTab;

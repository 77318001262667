import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Close, Info } from "@mui/icons-material";
import { useContext, useState } from "react";

import { AuthContext } from "../../shared/context/auth-context";
import OrderStatusStepper from "./OrderStatusStepper";
import { useNavigate } from "react-router-dom";
import { warehouse_labels } from "../../constants";
import { useFetchWarehouseOrderDetailsQuery } from "../../query-hooks/warehouseOrders/useFetchWarehouseOrderDetailsQuery";
import moment from "moment";
import { currencyFormatter } from "../../shared/utils";

export const orderStatus = [
  {
    status: "Picked",
    date: "On 17 March 2023",
    vehicle: "BH12018A1",
    driver: "Ganesh N",
    isCompleted: true,
  },
  {
    status: "In Transit",
    date: "On 17 March 2023",
    vehicle: "BH12018A1",
    driver: "Ganesh N",
    isCompleted: false,
  },
  {
    status: "Delivered",
    date: "On 17 March 2023",
    vehicle: "BH12018A1",
    driver: "Ganesh N",
    isCompleted: false,
  },
];

const OrderStatus = ({ close, orderId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleStepper = (index) => {
    setActiveStep(index);
  };

  const { data, isFetching } = useFetchWarehouseOrderDetailsQuery(orderId);
  return isFetching ? (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          padding: "25px 20px",
          background: "#1B51B3",
        }}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography fontSize={14} fontWeight={600} color={"white"}>
            {`Order ID : ${data?.order?.warehouseOrder?.code}`}
          </Typography>
          <Typography color={"white"} fontSize={14}>
            {`Dated : ${moment(data?.order?.createdAt).format(
              "DD MMMM, YYYY"
            )}`}
          </Typography>
        </Box>
        <Box height={"fit-content"} onClick={close}>
          <Close
            sx={{
              color: {
                xs: "#fff",
                md: "#607088",
              },
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: {
            xs: "none",
            md: "0px 8px 24px 0px #1515161A",
          },
          padding: {
            xs: "15px 20px",
            md: "60px 40px",
          },
          background: "#fff",
        }}
        gap={"28px"}
      >
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography fontSize={20} fontWeight={500} letterSpacing={"0.04px"}>
              {`Order ID : ${data?.order?.warehouseOrder?.code}`}
            </Typography>
            <Typography color={"text.secondary"} fontSize={14}>
              {`Dated : ${moment(data?.order?.createdAt).format(
                "DD MMMM, YYYY"
              )}`}
            </Typography>
          </Box>
          <Box height={"fit-content"} onClick={close}>
            <Close
              sx={{
                color: "#607088",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
        {/**
         * if requested for gatepass show this box with create button
         * */}
        <Box
          sx={{
            background: "#EFF2F6",
            padding: 2,
            display: "flex",
            justifyContent: "space-between",
            gap: {
              xs: "10px",
              md: "29px",
            },
          }}
        >
          <Box display={"flex"} gap={"12px"}>
            <Info color="primary" />
            <Typography
              sx={{
                fontSize: {
                  xs: 13,
                  md: 14,
                },
                minWidth: {
                  xs: 169,
                  md: 245,
                },
              }}
            >
              Customer has requested for the Gatepass for 10/25 packages.
            </Typography>
          </Box>
          <Button fullWidth variant="contained" sx={{ height: 40 }}>
            Create
          </Button>
        </Box>
        static above^
        <OrderStatusStepper
          activeStep={activeStep}
          handleStepper={handleStepper}
          steps={orderStatus}
        />
        <Divider
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          boxShadow: {
            xs: "none",
            md: "0px 8px 24px 0px #1515161A",
          },
          padding: {
            xs: "0 20px",
            md: "28px 40px 40px 40px",
          },
          background: "#fff",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 16,
              },
            }}
            fontWeight={500}
            letterSpacing={"0.08px"}
          >
            {warehouse_labels.customerDetailsLabelText}
          </Typography>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: {
                xs: 13,
                md: 14,
              },
              textDecoration: {
                xs: "underline",
                md: "none",
              },
            }}
            onClick={() => {
              navigate(`/orders/my-orders/${orderId}/summary`);
              auth.setCrumbStack(
                `/orders/my-orders/${orderId}/summary`,
                "Order Summary"
              );
            }}
          >
            {warehouse_labels.viewOrderFormButtonText}
          </Button>
        </Box>
        <Box marginTop={3} width={"100%"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.customerNameText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                >
                  {`${data?.order?.customerBillingDetails?.firstName} ${data?.order?.customerBillingDetails?.lastName}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  color={"text.secondary"}
                >
                  {warehouse_labels.transactionIdText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                >
                  {`--`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  color={"text.secondary"}
                >
                  {warehouse_labels.addressText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                  maxWidth={225}
                >
                  {`${data?.order?.customerBillingDetails?.address?.area}, ${data?.order?.customerBillingDetails?.address?.city}, ${data?.order?.customerBillingDetails?.address?.state}, ${data?.order?.customerBillingDetails?.address?.pincode}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            margin: "24px 0",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          background: "#fff",
          padding: {
            xs: "0 20px",
            md: "28px 40px 40px 40px",
          },
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 16,
              },
            }}
            fontWeight={500}
            letterSpacing={"0.08px"}
          >
            {warehouse_labels.paymentDetailsLabelText}
          </Typography>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: {
                xs: 13,
                md: 14,
              },
              textDecoration: {
                xs: "underline",
                md: "none",
              },
            }}
            onClick={() => {
              navigate(`/orders/my-orders/${orderId}/summary`, {
                state: {
                  isCreateInvoice: true,
                },
              });
              auth.setCrumbStack(
                `/orders/my-orders/${orderId}/summary`,
                "Order Summary"
              );
            }}
          >
            {warehouse_labels.createInvoiceButtonText}
          </Button>
        </Box>
        <Box marginTop={3} width={"100%"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  color={"text.secondary"}
                >
                  {warehouse_labels.amountPaidText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                >
                  {currencyFormatter(
                    data?.order?.warehousePendingInvoice?.charges?.totalAmount
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  color={"text.secondary"}
                >
                  {warehouse_labels.paymentDateText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                >
                  {moment(data?.order?.createdAt).format("DD MMMM YYYY")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  fontSize={14}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.paymentMethodText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                >
                  {`--`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={"62px"}>
              <Grid item xs={5} md={4}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  color={"text.secondary"}
                  whiteSpace={"nowrap"}
                >
                  {warehouse_labels.accountDetailsText}
                </Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 13,
                      md: 14,
                    },
                  }}
                  fontWeight={500}
                  maxWidth={150}
                >
                  {`--`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderStatus;

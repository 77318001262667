import { Box, Button, Grid, Typography } from "@mui/material";
import { transport_labels, warehouse_labels } from "../../constants";
import { useFieldArray, useForm } from "react-hook-form";

import { AdditionalPricesFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { useCustomDialog } from "../../shared/customDialog";
import { useEffect } from "react";
import { useUpdateAdditionalServicesMutateQuery } from "../../query-hooks/TransportDashboard/useUpdateAdditionalServicesMutateQuery";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const UpdatePricesForm = (props) => {
  const { hideDialog } = useCustomDialog();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      [AdditionalPricesFormKeys.additionalServices]: [],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: AdditionalPricesFormKeys.additionalServices,
  });

  const { mutate, isLoading, isError, error } =
    useUpdateAdditionalServicesMutateQuery();

  useEffect(() => {
    reset({ [AdditionalPricesFormKeys.additionalServices]: [] });
    props.additionalServices.map((item) =>
      append({ [item.name]: item.rate, ...item })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    mutate(
      {
        partnerId: props.partnerId,
        data: {
          additionalServices: data[
            AdditionalPricesFormKeys.additionalServices
          ].map((item) => {
            return {
              name: item.name,
              rate: item[item.name],
            };
          }),
        },
      },
      {
        onSuccess: () => {
          hideDialog();
          enqueueSnackbar("Additional Prices Updated", {
            variant: "success",
          });
          props.refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.data.errors, error?.response.data.message, isError]);

  return (
    <Box
      sx={{
        padding: "18px 16px",
      }}
    >
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        marginBottom={4}
      >
        {transport_labels.additionalServicesTitleText}
      </Typography>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={6} key={field.id}>
              <TextInput
                control={control}
                name={`${AdditionalPricesFormKeys.additionalServices}.${index}.${field.name}`}
                label={field.displayName}
                placeholder={`Enter in per ${field.rateUnit}`}
                type="number"
              />
            </Grid>
          ))}

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="soft"
                sx={{
                  height: 64,
                  mt: "14px",
                }}
                onClick={hideDialog}
              >
                {warehouse_labels.cancelButtonText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  height: 64,
                  mt: "14px",
                }}
                component={LoadingButton}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {transport_labels.updatePriceButtonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdatePricesForm;

import { Box, Divider, Grid, Typography } from "@mui/material";
import { labels, warehouse_labels } from "../../constants";

import InvoiceStatus from "./InvoiceStatus";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { useDrawer } from "../../shared/customSideDrawer";

const InvoiceMobileCard = ({
  orderId,
  invoiceDate,
  totalAmount,
  amountPaid,
  balance,
  paymentStatus
}) => {
  const { showDrawer, hideDrawer } = useDrawer();
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >{`${orderId}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
            textAlign={"end"}
          >
            {invoiceDate}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box display={"flex"} gap={2} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Typography fontSize={13} fontWeight={500}>
              {totalAmount}
            </Typography>
            <Typography fontSize={12} color={"text.secondary"}>
              {labels.totalAmountText}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Typography fontSize={13} fontWeight={500}>
              {amountPaid}
            </Typography>
            <Typography fontSize={12} color={"text.secondary"}>
              {warehouse_labels.amountPaidText}
            </Typography>
          </Box>
          <Divider orientation="vertical" sx={{ height: "auto" }} />
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Typography fontSize={13} fontWeight={500}>
              {balance}
            </Typography>
            <Typography fontSize={12} color={"text.secondary"}>
              {warehouse_labels.balanceText}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography fontSize={12} color={"text.secondary"}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
          <Typography fontSize={12} lineHeight={"16px"}>
            <StyledChip
              label={paymentStatus}
              variant="filled"
              onClick={() => {
                showDrawer({
                  component: (
                    <InvoiceStatus close={hideDrawer} status={paymentStatus} />
                  ),
                  closeIcon: false
                });
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoiceMobileCard;

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { otherControlsHover, otherControlsResting } from "../theme/globalTheme";

import { Controller } from "react-hook-form";
import { isMobileDevice } from "../../App";

const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24,
  height: 24,
  backgroundColor: otherControlsResting,
  "input:hover ~ &": {
    backgroundColor: otherControlsHover,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#EEF1F4",
  },
}));

const CustomIconChecked = styled(CustomIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const RadioButtonGroup = ({
  label,
  helperText,
  name,
  control,
  options,
  disabled = false,
  display = "flex",
  flexDirection = "row",
}) => {
  const generateRadioOptions = () => {
    return options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        label={option.label}
        control={
          <Radio checkedIcon={<CustomIconChecked />} icon={<CustomIcon />} />
        }
      />
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          sx={{
            display: display,
            flexDirection: isMobileDevice || error ? "column" : "row",
            alignItems: isMobileDevice || error ? "flex-start" : "center",
            "& > div": {
              gap: flexDirection === "row" ? 0 : "14px",
              flexDirection: flexDirection,
              "& > label": {
                marginLeft: 0,
              },
            },
          }}
          disabled={disabled}
        >
          {label && (
            <FormLabel
              id={name}
              sx={{
                marginRight: "42px",
                fontWeight: field.value && 500,
                marginBottom: isMobileDevice ? 1 : "initial",
              }}
            >
              {label}
            </FormLabel>
          )}
          <RadioGroup {...field} aria-labelledby={name} name={name} row>
            {generateRadioOptions()}
          </RadioGroup>
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && (
            <FormHelperText
              error
              sx={{
                marginLeft: 0,
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default RadioButtonGroup;

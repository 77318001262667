import { Box, Button, Divider, Grid, Typography, styled } from "@mui/material";
import {
  CalendarMonthOutlined,
  PlaceOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import { labels, transport_labels } from "../../constants";

import DetailsGrid from "../../warehouse/components/DetailsGrid.js";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import TruckDetailCard from "../components/TruckDetailCard.js";
import { useFetchTransportDetailsQuery } from "../../query-hooks/TransportDashboard/useFetchTransportDetailsQuery.js";
import { useParams } from "react-router-dom";

const StyledSectionHeader = styled((props) => (
  <StyledResponsiveText
    {...props}
    desktop={{
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24.2px",
      letterSpacing: "0.002em",
    }}
    mobile={{
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "19.36px",
      letterSpacing: "0.005em",
    }}
  >
    {props.children}
  </StyledResponsiveText>
))(({ theme }) => ({}));

export const StyledDivider = styled((props) => (
  <Divider
    {...props}
    sx={{
      borderBottomWidth: "0.4px",
      borderBottomColor: "#B7BEC7",
      borderRadius: "0.5px"
    }}
  />
))(({ theme }) => ({}));

export const StyledBookNowButton = styled((props) => (
  <Button
    {...props}
    sx={{
      width: 200,
      height: 48,
      fontSize: 15,
      fontWeight: 600,
      lineHeight: "20.43px",
      letterSpacing: "0.02em",
    }}
  />
))(({ theme }) => ({}));

const TransportDetail = () => {
  const { pid } = useParams();

  const { data } = useFetchTransportDetailsQuery(pid);

  return (
    <Box paddingBottom={2}>
      <Box
        sx={{
          padding: {
            sm: "initial",
            xs: "0 16px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid
              item
              xs={12}
              lg={7}
              container
              spacing={2}
              height="fit-content"
            >
              <Grid item xs={12}>
                <StyledResponsiveText
                  color={"text.primary"}
                  desktop={{
                    fontSize: 28,
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    letterSpacing: "-0.002em",
                  }}
                  mobile={{
                    fontSize: 20,
                    fontWeight: 500,
                    lineHeight: "24.2px",
                    letterSpacing: "0.002em",
                  }}
                >
                  {data?.partner.name}
                </StyledResponsiveText>
                <Box
                  component="div"
                  marginTop={"12px"}
                  display="flex"
                  alignItems="center"
                >
                  <PlaceOutlined fontSize="small" sx={{ marginRight: 1 }} />
                  <Typography
                    component="p"
                    color={"text.primary"}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${data?.partner.address.cluster}, `}
                    <Typography
                      component="span"
                      color={"text.secondary"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "16.94px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${data?.partner.address.city}, ${data?.partner.address.state}`}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={14} lineHeight={"20px"} sx={{ mr: 3 }}>
                  {data?.partner.description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: {
                    xs: 1,
                    md: 3,
                  },
                }}
              >
                <DetailsGrid
                  gridData={[
                    {
                      icon: (
                        <CalendarMonthOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.yearEstablishedLabelText,
                      value: data?.partner?.establishedYear,
                    },
                  ]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: { xs: 1, md: 3 }, mb: { xs: 1, md: 3 } }}
              >
                <StyledDivider />
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {transport_labels.truckDetailsLabelText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={"20px"}>
                  {data?.partner.vehicles?.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <TruckDetailCard
                        truckNumber={item.regNo}
                        truckMake={item.make.displayName}
                        truckModel={`${item.model.displayName} | ${item.weightCapacity}`}
                        pricePerKm={item.rate}
                        imageSrc={item.heroImage.fileUrl}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: { xs: 1, md: 3 }, mb: { xs: 1, md: 3 } }}
              >
                <StyledDivider />
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {labels.additionalServicesLabelText}
                </StyledSectionHeader>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: {
                    xs: 1,
                    md: 3,
                  },
                }}
              >
                <DetailsGrid
                  gridData={data?.partner.additionalServices?.map((item) => {
                    return {
                      icon: (
                        <WidgetsOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: item.displayName,
                      value: `₹${item.rate} / unit`,
                    };
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TransportDetail;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography
} from "@mui/material";
import React, { Fragment } from "react";

import { AddressSection } from "../../transport/components/InvoiceDetailTab";
import TextInput from "../../shared/formElements/TextInput";
import WarexLogo from "../../assets/Warex-Menu-logo.png";
import { ExpandMore } from "@mui/icons-material";
import styled from "@emotion/styled";
import { packageConditionColor } from "./GatePass";
import moment from "moment";
import { gatePassKeys } from "../../constants/formKeys";
import { gatePass_labels } from "../../constants";
import { LoadingButton } from "@mui/lab";

const Puller = styled(Box)(() => ({
  width: 50,
  height: 5,
  backgroundColor: "#B7BEC7",
  borderRadius: 3
}));

const GatePassMobile = ({
  control,
  handleSubmit,
  onSendGatePass,
  close,
  isLoading,
  fields,
  orderCode,
  outboundDate,
  customerBillingDetails,
  warehouseBillingDetails
}) => {
  const addressDetails = [
    {
      title: "Customer details",
      name: `${customerBillingDetails?.firstName} ${customerBillingDetails?.lastName}`,
      addressLine1: `${customerBillingDetails?.firmName}`,
      addressLine2: `${customerBillingDetails?.address?.area}, ${customerBillingDetails?.address?.city}, ${customerBillingDetails?.address?.state} ${customerBillingDetails?.address?.pincode}`,
      addressLine3: `GSTIN : ${customerBillingDetails?.gstNo}`
    },
    {
      title: "Storage Facility",
      name: warehouseBillingDetails?.name,
      addressLine1: `${warehouseBillingDetails?.address?.area}, ${warehouseBillingDetails?.address?.pincode}`,
      addressLine2: `GSTIN : ${warehouseBillingDetails?.gstNo}`
    }
  ];

  return (
    <>
      <Box
        onClick={close}
        paddingTop={1}
        display={"flex"}
        width={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        position={"fixed"}
        top={0}
        zIndex={1}
        sx={{ background: "#fff" }}
      >
        <Puller />
      </Box>
      <Box
        padding={"39px 20px 10px 20px !important"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"21px"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"9px"}
          alignSelf={"center"}
          alignItems={"center"}
        >
          <img src={WarexLogo} alt="warex" />
          <Typography fontSize={16} color={"text.secondary"} fontWeight={500}>
            Outbound Gate Pass
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography color={"text.primary"} fontSize={14} fontWeight={400}>
            Order no :
          </Typography>
          <Typography color={"text.primary"} fontSize={14} fontWeight={400}>
            {orderCode}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography color={"text.primary"} fontSize={14} fontWeight={400}>
            Material Out Date :
          </Typography>
          <Typography color={"text.primary"} fontSize={14} fontWeight={400}>
            {moment(outboundDate).format("DD-MM-YYYY")}
          </Typography>
        </Box>
        <Box width="100%">
          {addressDetails.map((item, index) => (
            <Box width="100%" key={index}>
              <Divider sx={{ height: "0.4px", background: "#B7BEC7" }} />
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMore color="primary" />}
                  sx={{ padding: 0 }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize="14px"
                    color={"primary.dark"}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                  <AddressSection
                    name={item.name}
                    addressLine1={item.addressLine1}
                    addressLine2={item.addressLine2}
                    addressLine3={item.addressLine3}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
          <Divider sx={{ height: "0.4px", background: "#B7BEC7" }} />
        </Box>
        <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
          <TextInput
            control={control}
            name={gatePassKeys.transporter}
            label={gatePass_labels.transporter}
            placeholder={"Enter name"}
          />
          <TextInput
            control={control}
            name={gatePassKeys.truckRegNo}
            label={gatePass_labels.truckRegNo}
            placeholder={"Enter number"}
          />
          <TextInput
            control={control}
            name={gatePassKeys.gatepassPreparedBy}
            label={gatePass_labels.gatepassPreparedBy}
            placeholder={"Enter name"}
          />
        </Box>
        <Box
          sx={{
            border: "0.4px solid #B7BEC7",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 8px 24px 0px rgba(21, 21, 22, 0.10)",
            padding: "20px 16px",
            gap: "20px"
          }}
          width={"100%"}
        >
          {fields.map((item, index) => (
            <Fragment key={index}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography fontSize={13} fontWeight={500}>
                    {item.packageName}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    color={"text.secondary"}
                  >
                    {item.packagingMaterial}
                  </Typography>
                </Box>
                <Box display={"flex"} alignSelf={"flex-end"}>
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    color={"text.secondary"}
                  >
                    {item.packageSize}
                  </Typography>
                </Box>
                <Box display={"flex"}>
                  <Typography
                    fontSize={13}
                    fontWeight={500}
                    color={packageConditionColor[item.packageCondition]}
                  >
                    {item.packageCondition}
                  </Typography>
                </Box>
              </Box>
              {index % 2 === 0 && <Divider />}
            </Fragment>
          ))}
        </Box>
        <Box display={"flex"} width={"100%"} gap={0.5}>
          <Typography fontSize={14}>Number of Packages :</Typography>
          <Typography fontSize={14} fontWeight={700}>
            {String(fields.length).padStart(2, "0")}
          </Typography>
        </Box>
        <Box display={"flex"} width={"100%"} gap={0.5} marginTop={"6px"}>
          <Typography fontSize={14}>Prepared time :</Typography>
          <Typography fontSize={14} fontWeight={700}>
            {moment(new Date()).format("HH.mm")}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"10px"}
          width={"100%"}
          borderTop={"2px solid #B7BEC7"}
        >
          <Divider sx={{ height: "0.4px", background: "#B7BEC7" }} />
          <Button
            variant="contained"
            component={LoadingButton}
            loading={isLoading}
            sx={{ height: "48px", width: "100%" }}
            onClick={handleSubmit(onSendGatePass)}
          >
            Send Gatepass
          </Button>
          <Button
            variant="soft"
            sx={{ height: "48px", width: "100%" }}
            onClick={close}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GatePassMobile;

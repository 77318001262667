import { Box, Button, Divider, IconButton, Typography } from "@mui/material";

import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { StyledBox } from "../../warehouse/components/DashboardListItem";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import { warehouse_labels } from "../../constants";

const PricesBox = ({ title, value }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
      <Typography fontSize={14} color="text.secondary" lineHeight={"20px"}>
        {title}
      </Typography>
      <Box display={"flex"} alignItems={"baseline"} gap={"2px"}>
        <Typography fontSize={10} color="text.secondary" lineHeight={"16px"}>
          ₹
        </Typography>
        <Typography
          fontSize={14}
          color="text.primary"
          lineHeight={"16px"}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const TransportDetailCard = ({
  name,
  city,
  state,
  country,
  listingDate,
  additionalServices,
  id,
  handleEdit,
  data,
}) => {
  return (
    <StyledBox
      sx={{
        padding: 3,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <StyledResponsiveText
          desktop={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "19px",
            letterSpacing: "0.005em",
          }}
        >
          {name}
        </StyledResponsiveText>
        <Button
          sx={{
            height: "auto",
            fontSize: 13,
            fontWeight: 600,
            letterSpacing: "0.03em",
          }}
          component={Link}
          to={`/dashboard/transport/${data.partnerId}`}
          state={data}
          target="_blank"
        >
          {warehouse_labels.viewDetailsButtonText}
        </Button>
      </Box>
      <StyledResponsiveText
        desktop={{
          fontSize: 14,
          lineHeight: "20px",
          mb: "28px",
        }}
        color="text.secondary"
      >
        {`${city}, ${state}, ${country}`}
      </StyledResponsiveText>
      {data.status.name === "Active" ? (
        <Box display={"flex"} alignItems={"center"} gap={"4px"}>
          <StyledResponsiveText
            desktop={{
              fontSize: 14,
              lineHeight: "18px",
              letterSpacing: "0.16 px",
            }}
            color="text.secondary"
          >
            {warehouse_labels.listedOnText}
          </StyledResponsiveText>
          <StyledResponsiveText
            desktop={{
              fontSize: 14,
              lineHeight: "18px",
              letterSpacing: "0.16 px",
              fontWeight: 600,
            }}
            color="#161616"
          >
            {listingDate}
          </StyledResponsiveText>
        </Box>
      ) : (
        <StyledChip label={data.status.displayName} />
      )}
      <Divider sx={{ mt: "30px", mb: 3 }} />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={"24px"}
      >
        <StyledResponsiveText
          desktop={{
            fontSize: 14,
            lineHeight: "20px",
          }}
          color="text.secondary"
        >
          {warehouse_labels.additionalPricesText}
        </StyledResponsiveText>
        {data.status.name === "Active" && (
          <IconButton size="small" onClick={handleEdit}>
            <Edit color="primary" fontSize="20px" />
          </IconButton>
        )}
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={"40px"}>
        {additionalServices?.map((item, index) => (
          <PricesBox
            key={index}
            title={item.displayName}
            value={`${item.rate} ${warehouse_labels.perKgText}`}
          />
        ))}
      </Box>
    </StyledBox>
  );
};

export default TransportDetailCard;

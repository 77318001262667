import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { statusKeys, warehouse_labels } from "../../constants";
import { useEffect, useState } from "react";

import InquiryMobileCard from "../components/InquiryMobileCard";
import InquiryTable from "../../shared/customTable";
import { CustomTabs as InquiryTabs } from "../../shared/customTab/CustomTabs";
import RegretForm from "../../shared/UiElements/ReasonForm";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { SuccessRegret } from "./ActiveInquiry";
import { isMobileDevice } from "../../App";
import moment from "moment";
import { stringCapitalization } from "../../shared/utils";
import { useCustomDialog } from "../../shared/customDialog";
import { useFetchActiveInquiryQuery } from "../../query-hooks/useFetchActiveInquiryQuery";
import { useFetchClosedInquiryQuery } from "../../query-hooks/useFetchClosedInquiryQuery";
import { useFetchWarehouseRegretForm } from "../../query-hooks/warehouseInquiry/useFetchWarehouseRegretForm";
import { useNavigate } from "react-router-dom";
import { useRegretWarehouseMutateHook } from "../../query-hooks/warehouseInquiry/useRegretWarehouseMutateHook";
import { useInView } from "react-intersection-observer";
import { enqueueSnackbar } from "notistack";

const commonColumns = [
  {
    Header: "Inquiry ID",
    accessor: "code"
  },
  {
    Header: "Inquired On",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return moment(value).format("DD MMMM YYYY");
    }
  },
  {
    Header: "Storage type",
    accessor: "storageType",
    Cell: ({ value, cell }) =>
      cell.row.original.searchForm?.storageType?.displayName
  },
  {
    Header: "Product",
    accessor: "materialCategory",
    Cell: ({ value, cell }) =>
      cell.row.original?.searchForm?.materialCategory?.displayName || "--"
  }
];

const Inquiry = () => {
  const [inquiryId, setInquiryId] = useState();
  const [activeInquiryTab, setActiveInquiryTab] = useState(0);
  const { ref: activeRef, inView: inActiveView } = useInView({ threshold: 0 });
  const { ref: pastRef, inView: inPastView } = useInView({ threshold: 0 });
  const navigate = useNavigate();

  const { showDialog, hideDialog } = useCustomDialog();

  const handleInquiryTabChange = (index) => {
    setActiveInquiryTab(index);
  };

  const {
    data: activeData,
    isFetching: isActiveFetching,
    refetch: isActiveRefetch,
    hasNextPage: hasActiveNextPage,
    fetchNextPage: fetchActiveNextPage
  } = useFetchActiveInquiryQuery();

  const {
    data: closedData,
    isFetching: isClosedFetching,
    refetch: isClosedRefetch,
    hasNextPage: hasClosedNextPage,
    fetchNextPage: fetchClosedNextPage
  } = useFetchClosedInquiryQuery();

  const { data: regretFormData, isFetched: isRegretFormFetched } =
    useFetchWarehouseRegretForm(inquiryId);

  const {
    mutate: regretInquiry,
    isLoading: isRegretInquiryLoading,
    isError: isRegretInquiryError,
    error: regretInquiryError,
    reset: resetRegretInquiry
  } = useRegretWarehouseMutateHook();

  const handleRegret = (data, openDialog, closeDialog) => {
    regretInquiry(
      {
        inquiryId,
        data: {
          reason: data.regretReason,
          ...{
            ...(data.otherReason ? { otherReason: data.otherReason } : {})
          }
        }
      },
      {
        onSuccess: () => {
          isActiveRefetch();
          isClosedRefetch();
          openDialog({
            component: (
              <SuccessRegret
                btn2Callback={() => {
                  closeDialog();
                  navigate("/inquiry");
                }}
              />
            )
          });
        }
      }
    );
  };

  useEffect(() => {
    if (isRegretFormFetched) {
      showDialog({
        component: (
          <RegretForm
            title={warehouse_labels.regretInquiryTitleText}
            subText={warehouse_labels.regretInquirySubText}
            btn1Text={warehouse_labels.yesRegretButtonText}
            btn2Text={warehouse_labels.cancelButtonText}
            btn1Callback={(data) => handleRegret(data, showDialog, hideDialog)}
            btn2Callback={() => {
              setInquiryId(false);
              hideDialog();
            }}
            reasons={regretFormData.reasons}
            isBtn1Loading={isRegretInquiryLoading}
          />
        ),
        backdropOff: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegretFormFetched]);

  useEffect(() => {
    if (inActiveView && hasActiveNextPage) {
      fetchActiveNextPage();
    }
    if (inPastView && hasClosedNextPage) {
      fetchClosedNextPage();
    }
  }, [
    fetchActiveNextPage,
    fetchClosedNextPage,
    hasActiveNextPage,
    hasClosedNextPage,
    inActiveView,
    inPastView
  ]);

  useEffect(
    () => {
      if (isRegretInquiryError) {
        enqueueSnackbar(regretInquiryError?.response.data.message, {
          variant: "error"
        });
        resetRegretInquiry();
      }
    }, // eslint-disable-next-line
    [regretInquiryError?.response.data.message, isRegretInquiryError]
  );

  let activeInquiries = activeData?.pages.flatMap((item) => item.inquiries);
  let pastInquiries = closedData?.pages.flatMap((item) => item.inquiries);

  return (
    <Box>
      <Typography
        sx={{
          padding: "37px 21px 18px 21px",
          background: "#EEF1F4",
          display: {
            xs: "block",
            sm: "none"
          }
        }}
      >
        Manage Inquiry
      </Typography>
      <InquiryTabs
        tabs={[
          {
            label: `Active Inquiry (${
              activeData?.pages[0]?.pagination?.rows ?? 0
            })`,
            Component: (
              <Box
                sx={{
                  padding: {
                    xs: "10px 16px",
                    sm: 0
                  }
                }}
              >
                <InquiryTable
                  searchPlaceholderText={"Search by Inquiry ID, date, etc"}
                  columns={[
                    ...commonColumns,
                    {
                      Header: "Status",
                      accessor: "status",
                      Cell: ({ value, cell }) => {
                        return (
                          <StyledChip
                            label={stringCapitalization(
                              cell.row.original.warehouseRfq.partners[0].status
                                .name
                            )}
                            onClick={() => {
                              navigate(
                                `/inquiry/active-inquiry/${cell.row.original._id}`
                              );
                            }}
                          />
                        );
                      }
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      disableSortBy: true,
                      Cell: ({ value, cell }) => {
                        return cell.row.original.warehouseRfq.partners[0].status
                          .name === statusKeys.Pending ? (
                          <Box display={"flex"} gap={4}>
                            <Button
                              variant="link"
                              disableRipple
                              sx={{ fontWeight: 600 }}
                              onClick={() => {
                                navigate(
                                  `/inquiry/active-inquiry/${cell.row.original._id}`
                                );
                              }}
                            >
                              Send quote
                            </Button>
                            <Button
                              variant="link"
                              disableRipple
                              sx={{ fontWeight: 600 }}
                              onClick={() =>
                                setInquiryId(cell.row.original._id)
                              }
                            >
                              Regret
                            </Button>
                          </Box>
                        ) : cell.row.original.warehouseRfq.partners[0].status
                            .name === statusKeys.QuoteSent ? (
                          <Typography fontSize={12}>
                            {`/ sent on`}{" "}
                            <b style={{ fontWeight: 500 }}>{`${moment(
                              cell.row.original.warehouseRfq.partners[0].quote
                                .date
                            ).format("DD MMM")}`}</b>
                          </Typography>
                        ) : (
                          <Typography fontSize={12}>
                            {`/ Regret on`}{" "}
                            <b style={{ fontWeight: 500 }}>{`${moment(
                              cell.row.original.warehouseRfq.partners[0].quote
                                .date
                            ).format("DD MMM")}`}</b>
                          </Typography>
                        );
                      }
                    }
                  ]}
                  data={activeInquiries || []}
                  mobileComponent={InquiryMobileCard}
                  isLoading={isActiveFetching}
                />
              </Box>
            )
          },
          {
            label: `Past Inquiry (${
              closedData?.pages[0]?.pagination?.rows ?? 0
            } )`,
            Component: (
              <Box
                sx={{
                  padding: {
                    xs: "10px 16px",
                    sm: 0
                  }
                }}
              >
                <InquiryTable
                  searchPlaceholderText={"Search by Inquiry ID, date, etc"}
                  columns={[
                    ...commonColumns,
                    {
                      Header: "Status",
                      accessor: "status",
                      Cell: ({ value, cell }) => {
                        return (
                          <StyledChip
                            label={stringCapitalization(
                              cell.row.original.warehouseRfq.partners[0].status
                                .name
                            )}
                            onClick={() => {
                              navigate(
                                `/inquiry/active-inquiry/${cell.row.original._id}`
                              );
                            }}
                          />
                        );
                      }
                    },
                    {
                      Header: "Comments",
                      accessor: "comments",
                      Cell: ({ value, cell }) => {
                        const row =
                          cell.row.original.warehouseRfq.partners[0].status
                            .name;
                        return row === statusKeys.OrderBooked ? (
                          <Button
                            variant="link"
                            disableRipple
                            sx={{ fontWeight: 600 }}
                            onClick={() => navigate("/orders/my-orders")}
                          >
                            View in Order
                          </Button>
                        ) : row === statusKeys.Cancelled ? (
                          <Typography fontSize={12}>
                            {`/ Cancelled on`}{" "}
                            <b style={{ fontWeight: 500 }}>{`${moment(
                              cell.row.original.warehouseRfq.cancel.date
                            ).format("DD MMM")}`}</b>
                          </Typography>
                        ) : row === statusKeys.Regret ? (
                          <Typography fontSize={12}>
                            {`/ Regret on`}{" "}
                            <b style={{ fontWeight: 500 }}>{`${moment(
                              cell.row.original.warehouseRfq.partners[0].regret
                                .date
                            ).format("DD MMM")}`}</b>
                          </Typography>
                        ) : (
                          <></>
                        );
                      }
                    }
                  ]}
                  data={pastInquiries || []}
                  mobileComponent={InquiryMobileCard}
                  isLoading={isClosedFetching}
                />
              </Box>
            )
          }
        ]}
        activeTab={activeInquiryTab}
        onChange={handleInquiryTabChange}
        secondaryTab={isMobileDevice ? true : false}
      />
      {hasActiveNextPage && (
        <Box
          ref={activeRef}
          display={"flex"}
          justifyContent={"center"}
          margin={"16px 0"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>
      )}
      {hasClosedNextPage && (
        <Box
          ref={pastRef}
          display={"flex"}
          justifyContent={"center"}
          margin={"16px 0"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Inquiry;

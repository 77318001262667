import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";

const BorderLinearProgress = styled((props) => (
  <LinearProgress
    {...props}
    sx={{
      borderRadius: {
        xs: 0,
        md: "500px 200px 200px 0",
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: {
          xs: "0 200px 0 0",
          md: "100px 100px 100px 0",
        },
      },
    }}
  />
))(({ theme, value, bgcolor }) => ({
  height: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor:
      bgcolor === "error"
        ? theme.palette[bgcolor].main
        : theme.palette[bgcolor].dark,
  },
}));

const BenchmarkRow = ({ label, progress, value, color }) => {
  return (
    <>
      <Grid item xs={4} xl={3.3}>
        <Typography
          fontSize={14}
          lineHeight={"20px"}
          color={"text.secondary"}
          whiteSpace={"nowrap"}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <BorderLinearProgress
          value={progress}
          variant="determinate"
          bgcolor={color}
        />
      </Grid>
      <Grid item xs={3} xl={3.7}>
        <Typography
          fontSize={color === "secondary" ? 14 : 16}
          fontWeight={color === "secondary" ? 600 : 700}
          lineHeight={"20px"}
          color={
            color === "secondary"
              ? "text.secondary"
              : color === "error"
              ? "error.main"
              : "text.primary"
          }
          textAlign={"right"}
        >
          {`₹${value}`}
        </Typography>
      </Grid>
    </>
  );
};

const PriceBenchmarks = ({
  competitors = [],
  proposedPrice = 0,
  yourPrice = 0,
  maxValue = 0,
}) => {
  return (
    <Box
      sx={{
        padding: {
          xs: "24px 16px",
          md: "40px 28px",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: 16,
          lineHeight: "20px",
          fontWeight: {
            xs: 500,
            md: 600,
          },
          letterSpacing: "0.005em",
          mb: 3,
        }}
      >
        Price benchmark
      </Typography>
      <Box>
        <Grid container spacing={"12px"}>
          {competitors.map((competitor, index) => (
            <Grid item xs={12} container spacing={"28px"} key={index}>
              <BenchmarkRow
                label={`Competitor ${index + 1}`}
                color={"secondary"}
                progress={(competitor?.totalAmount / maxValue) * 100}
                value={parseFloat(competitor?.totalAmount).toFixed(2)}
              />
            </Grid>
          ))}
          <Grid item xs={12} container spacing={"28px"}>
            <BenchmarkRow
              label={"Proposed price"}
              color={"success"}
              progress={(proposedPrice / maxValue) * 100}
              value={parseInt(proposedPrice)}
            />
          </Grid>
          <Grid item xs={12} container spacing={"28px"}>
            <BenchmarkRow
              label={"Your price :"}
              color={"error"}
              progress={(yourPrice / maxValue) * 100}
              value={parseFloat(yourPrice).toFixed(2)}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PriceBenchmarks;

import {
  Backpack,
  BackpackOutlined,
  CalendarMonthOutlined,
  PestControlOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import { Box, Button, Divider, Grid, Typography, styled } from "@mui/material";

import AdditionalDetailsCard from "../components/AdditionalDetailsCard";
import DetailsGrid from "../components/DetailsGrid";
import ImageGrid from "../components/ImageGrid";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";
import { currencyFormatter } from "../../shared/utils";
import { isMobileDevice } from "../../App";
import { labels } from "../../constants";
import { useFetchWarehouseDetailsQuery } from "../../query-hooks/warehouseDashboard/useFetchWarehouseDetailsQuery";
import { useParams } from "react-router-dom";

const StyledSectionHeader = styled((props) => (
  <StyledResponsiveText
    {...props}
    desktop={{
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24.2px",
      letterSpacing: "0.002em",
    }}
    mobile={{
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "19.36px",
      letterSpacing: "0.005em",
    }}
  >
    {props.children}
  </StyledResponsiveText>
))(({ theme }) => ({}));

export const StyledDivider = styled((props) => (
  <Divider
    {...props}
    sx={{
      borderBottomWidth: "0.4px",
      borderBottomColor: "#B7BEC7",
      borderRadius: "0.5px",
      mt: isMobileDevice ? 3 : 2,
      mb: isMobileDevice ? 2 : 5,
    }}
  />
))(({ theme }) => ({}));

export const StyledBookNowButton = styled((props) => (
  <Button
    {...props}
    sx={{
      width: 200,
      height: 48,
      fontSize: 15,
      fontWeight: 600,
      lineHeight: "20.43px",
      letterSpacing: "0.02em",
    }}
  />
))(({ theme }) => ({}));

const WarehouseDetail = () => {
  const { pid } = useParams();

  const { data } = useFetchWarehouseDetailsQuery(pid);

  return (
    <Box paddingBottom={2}>
      <Box
        maxHeight={360}
        overflow="hidden"
        marginBottom={5}
        sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
        }}
      >
        <img
          src={data?.partner.files[0].fileUrl}
          alt="warehouse"
          width={"100%"}
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          padding: {
            sm: "initial",
            xs: "0 16px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            marginBottom={1}
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            {/** images list will be updated with API integration */}
            <ImageGrid
              imageList={data?.partner.files.map((item, index) => {
                if (index === 0) {
                  return {
                    img: item.fileUrl,
                    title: item.originalName,
                    rows: 2,
                    cols: 2,
                  };
                } else {
                  return {
                    img: item.fileUrl,
                    title: item.originalName,
                  };
                }
              })}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid
              item
              xs={12}
              lg={7}
              container
              spacing={2}
              height="fit-content"
            >
              <Grid item xs={12}>
                <StyledResponsiveText
                  color={"text.primary"}
                  desktop={{
                    fontSize: 28,
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    letterSpacing: "-0.002em",
                  }}
                  mobile={{
                    fontSize: 20,
                    fontWeight: 500,
                    lineHeight: "24.2px",
                    letterSpacing: "0.002em",
                  }}
                >
                  {data?.partner.name}
                </StyledResponsiveText>
                <Box
                  component="div"
                  marginTop={"12px"}
                  display="flex"
                  alignItems="center"
                >
                  <PlaceOutlined fontSize="small" sx={{ marginRight: 1 }} />
                  <Typography
                    component="p"
                    color={"text.primary"}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${data?.partner.address.cluster}, `}
                    <Typography
                      component="span"
                      color={"text.secondary"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "16.94px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {`${data?.partner.address.city}, ${data?.partner.address.state}`}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontSize={14}
                  lineHeight={"20px"}
                  sx={{ mb: 3, mr: 3 }}
                >
                  {data?.partner.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {labels.storageDetailsTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 1 : 3}>
                <DetailsGrid
                  gridData={[
                    {
                      icon: (
                        <CalendarMonthOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.yearEstablishedLabelText,
                      value: data?.partner.establishedYear,
                    },
                    {
                      icon: (
                        <Backpack
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.totalStorageSpaceLabelText,
                      value: `${currencyFormatter(
                        data?.partner.storageArea.total
                      )} sq.ft.`,
                    },
                    {
                      icon: (
                        <BackpackOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.availableSpaceLabelText,
                      value: `${currencyFormatter(
                        data?.partner.storageArea.available
                      )} sq.ft.`,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {labels.storageTypesAndPricingTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12} marginTop={3}>
                <DetailsGrid
                  gridData={data?.partner.storageTypes.map((item) => {
                    return {
                      label: item.displayName,
                      value: `₹${item.rate} / sqft`,
                    };
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" } }}>
                <StyledSectionHeader>
                  {labels.additionalDetailsTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 0 : 3}>
                <AdditionalDetailsCard
                  title={labels.servicesAvailableLabelText}
                  services={data?.partner?.features.map((item) => {
                    return {
                      name: item.name,
                      displayName: item.displayName,
                      icon: (
                        <PestControlOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                    };
                  })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display={{
                  xs: "block",
                  sm: "none",
                }}
              >
                <StyledDivider />
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 0 : 3}>
                <AdditionalDetailsCard
                  title={labels.approvedProductsLabelText}
                  products={data?.partner.materialCategories.map(
                    (item) => item.displayName
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WarehouseDetail;

import { Box, Container, Grid, Typography } from "@mui/material";

import LoginForm from "../pages/LoginForm";
import { home_labels } from "../../constants";
import WarehouseBg from "../../assets/warehouse.png";
import transportBg from "../../assets/transport.png";
import { useState } from "react";
import ForgotPasswordForm from "../pages/ForgotPasswordForm";
import Carousel from "react-material-ui-carousel";

const Images = [
  { id: 1, image: WarehouseBg, title: "Warehouse" },
  { id: 2, image: transportBg, title: "Transport" },
];

const PartnerSection = () => {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <Box
      position={"relative"}
      sx={{
        maxHeight: {
          xs: 650,
          md: "100%",
        },
      }}
    >
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        columnGap={5}
      >
        <Typography
          sx={{
            fontSize: 20,
            lineHeight: "40px",
            textAlign: "center",
            fontWeight: "600",
            letterSpacing: "-0.02em",
            color: "text.title",
          }}
        >
          {home_labels.partnerLabelText}
        </Typography>
        <Carousel
          sx={{
            width: "100%",
            zIndex: -1,
          }}
        >
          {Images.map((item) => (
            <img
              src={item.image}
              alt={item.title}
              key={item.id}
              height={300}
              width={"100%"}
            />
          ))}
        </Carousel>
      </Box>
      <Box
        sx={{
          position: "relative",
          bottom: {
            xs: 210,
            md: 0,
          },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xs: 0,
              md: "0 95px !important",
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
                position={"relative"}
                zIndex={-1}
                width={"100%"}
              >
                <Carousel sx={{ width: "100%" }}>
                  {Images.map((item) => (
                    <img src={item.image} alt={item.title} key={item.id} />
                  ))}
                </Carousel>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                textAlign={"center"}
                sx={{
                  display: { xs: "none", md: "block" },
                  fontSize: 32,
                  lineHeight: "64px",
                  fontWeight: "600",
                  color: "text.title",
                }}
              >
                {home_labels.partnerLabelText}
              </Typography>
              <Box
                sx={{
                  padding: {
                    xs: "0 20px 0 20px",
                    md: "0 50px 0 50px",
                  },
                  mt: {
                    xs: "30px",
                    md: 0,
                  },
                }}
              >
                {activeStep === 0 ? (
                  <LoginForm setActiveStep={setActiveStep} />
                ) : (
                  <ForgotPasswordForm
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          <Typography
            color={"text.secondary"}
            sx={{
              fontSize: {
                xs: 12,
                md: 22,
              },
              lineHeight: {
                xs: "18px",
                md: "33px",
              },
              mt: {
                xs: 0,
                md: "12px",
              },
              letterSpacing: "-0.01em",
              color: "#607088",
              fontWeight: "700",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
                textAlign: "left",
              }}
            >
              {home_labels.partnerSubText1}
            </Box>
            <Box
              sx={{
                display: {
                  md: "none",
                },
                padding: {
                  xs: "15px 0",
                  md: 0,
                },
                textAlign: "center",
              }}
            >
              {home_labels.partnerSubText2}
            </Box>
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default PartnerSection;

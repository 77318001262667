import { Box, Grid, Typography } from "@mui/material";

const DetailsGrid = ({ gridData }) => {
  return (
    <Grid container spacing={2}>
      {gridData?.map((item, index) => (
        <Grid
          item
          xs={6}
          md={item.icon ? 3 : 2}
          display="flex"
          alignItems="flex-start"
          key={index}
        >
          {item.icon && item.icon}
          <Box>
            <Typography
              fontWeight={500}
              fontSize={14}
              lineHeight="20px"
              color="text.secondary"
            >
              {item.label}
            </Typography>
            <Typography
              fontSize={16}
              lineHeight="19px"
              letterSpacing="0.005em"
              fontWeight={600}
              marginTop={1}
            >
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DetailsGrid;

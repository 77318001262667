import { Box, Typography } from "@mui/material";

import Invoices from "./Invoices";
import MyOrders from "./MyOrders";
import { CustomTabs as OrderTabs } from "../../shared/customTab/CustomTabs";
import { useState } from "react";

const Orders = () => {
  const [activeInquiryTab, setActiveInquiryTab] = useState(0);

  const handleInquiryTabChange = (index) => {
    setActiveInquiryTab(index);
  };
  return (
    <Box>
      <Typography
        sx={{
          padding: "37px 21px 18px 21px",
          background: "#EEF1F4"
        }}
      >
        Manage Orders
      </Typography>
      <OrderTabs
        tabs={[
          {
            label: "My Orders",
            Component: <MyOrders />
          },
          {
            label: "Invoices & Docs",
            Component: <Invoices />
          }
        ]}
        activeTab={activeInquiryTab}
        onChange={handleInquiryTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default Orders;

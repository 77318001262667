import { QueryKeys } from "../../constants/query-keys";
import { getWarehouseInquiryData } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseInquiry = (inquiryId) => {
  const warehouseInquiryQuery = useQuery(
    [QueryKeys.queryWarehouseInquiry, inquiryId],
    () => getWarehouseInquiryData(inquiryId),
    {
      enabled: Boolean(inquiryId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return warehouseInquiryQuery;
};

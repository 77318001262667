import { AppBar, Container, Grid, ThemeProvider, Toolbar } from "@mui/material";

import MobileNavLinkItem from "./MobileNavLinkItem";
import { NAV_LINK, PROFILE_NAV_LINK } from "./SideDrawer";
import { bottomAppBarTheme } from "../theme/bottomAppBarTheme";
import { useLocation } from "react-router-dom";

const BottomMobileAppBar = () => {
  const location = useLocation();
  const isProfileScreen = location.pathname.includes("/profile") || false;
  const LINK = isProfileScreen ? PROFILE_NAV_LINK : NAV_LINK;
  return (
    <ThemeProvider theme={bottomAppBarTheme}>
      <Toolbar />
      <AppBar position="fixed" color="primary">
        <Toolbar component={Container}>
          <Grid container spacing={1}>
            {LINK.map(({ icon, name, to, from }) => (
              <Grid item xs key={name}>
                <MobileNavLinkItem
                  name={name}
                  icon={icon}
                  path={to ? to : from}
                />
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default BottomMobileAppBar;

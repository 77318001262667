import { QueryKeys } from "../../constants/query-keys";
import { fetchTransportOrderDetails } from "../../transport/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchOrderDetailsQuery = (orderId) => {
  const transportOrdersDetails = useQuery(
    [QueryKeys.queryTransportOrderDetails, orderId],
    () => fetchTransportOrderDetails(orderId)
  );
  return transportOrdersDetails;
};

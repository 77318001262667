import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Backdrop,
  Box,
  Button,
  // CircularProgress,
  Divider,
  Typography
} from "@mui/material";

import AssignDriverForm from "../components/AssignDriverForm";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import InvoiceDetailTab from "../components/InvoiceDetailTab";
import OrderDetailTab from "../../warehouse/components/OrderDetailTab";
import { labels, statusKeys } from "../../constants";
import { useCustomDialog } from "../../shared/customDialog";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "@emotion/styled";
import { isMobileDevice } from "../../App";
import { East, ExpandMore } from "@mui/icons-material";
import { useFetchOrderDetailsQuery } from "../../query-hooks/TransportOrders/useFetchOrderDetailsQuery";
import moment from "moment";
import { DetailsGrid, TextBox } from "../components/InquiryDetails";
import { useFetchTransportInvoiceFormData } from "../../query-hooks/TransportInvoice/useFetchTransportInvoiceFormData";
import InvoiceDetailManualTab from "../../warehouse/components/InvoiceDetailManualTab";

const StyleBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  background: isMobileDevice ? "#F3F5F9" : theme.palette.success.background,
  borderRadius: 4,
  marginTop: isMobileDevice ? 0 : "20px",
  padding: "12px"
}));

const StyledAccordion = styled(Accordion)(() => ({
  border: "0.4px solid #95A1B0",
  borderRadius: "8px !important",
  padding: "8px 0px",
  marginBottom: "0 !important"
}));

const OrderSummary = () => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.isCreateInvoice ? 1 : 0);
  const [showMore, setShowMore] = useState(false);
  const [isManualInvoice, setIsManualInvoice] = useState(true);
  const [isCreateInvoice, setIsCreateInvoice] = useState(
    state?.isCreateInvoice || false
  );
  const [manualInvoiceData, setManualInvoiceData] = useState(null);

  const { showDialog, hideDialog } = useCustomDialog();

  const { orderId } = useParams();

  const { data } = useFetchOrderDetailsQuery(orderId);

  const { data: invoiceFormData, isFetched: isFormFetched } =
    useFetchTransportInvoiceFormData(orderId);

  const handleTabChange = (index) => {
    setActiveTab(index);
    setIsCreateInvoice(index);
  };

  const tranportOrderDetail = {
    detailsList: [
      {
        itemNo: 1,
        itemDesc: `Transport services Material : ${data?.order?.searchForm?.materialCategory?.displayName} HSN Code : 99679`,
        uom: `${data?.order?.recentOrderItem?.transportCharges?.services?.[0].rateUnit}`,
        distance:
          data?.order?.recentOrderItem?.transportCharges?.services?.[0].quantity?.toFixed(
            2
          ), // here need to give the distance
        rate: data?.order?.recentOrderItem?.transportCharges?.services?.[0]
          .rate,
        totalAmount:
          data?.order?.recentOrderItem?.transportCharges?.services?.[0]
            ?.totalAmount
      },
      {
        itemNo: 2,
        itemDesc: `Additional Costs Material Handling`,
        uom: "",
        duration: "",
        rate: "",
        totalAmount:
          data?.order?.recentOrderItem?.transportCharges
            ?.additionalServices?.[0]?.totalAmount ?? 0
      },
      {
        itemNo: "",
        itemDesc: `Sub Total`,
        uom: "",
        duration: "",
        rate: "",
        totalAmount:
          data?.order?.recentOrderItem?.transportCharges?.subTotalAmount
      }
    ],
    gstRegime: data?.order?.recentOrderItem?.transportCharges?.taxes?.[0].rate,
    gstAmount:
      data?.order?.recentOrderItem?.transportCharges?.taxes?.[0].totalAmount,
    totalAmount: data?.order?.recentOrderItem?.transportCharges?.totalAmount
  };

  return (
    <Box
      sx={{
        padding: {
          xs: 2,
          md: "40px  0"
        }
      }}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
    >
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <Box
          display={"flex"}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 5 }
          }}
        >
          <Box display={"flex"} gap={1}>
            <Typography fontSize={14} color={"text.secondary"}>
              Order ID :
            </Typography>
            <Typography fontSize={14} fontWeight={500}>
              {data?.order?.transportOrder?.code}
            </Typography>
          </Box>
          <Box display={"flex"} gap={1}>
            <Typography fontSize={14} color={"text.secondary"}>
              Order date :
            </Typography>
            <Typography fontSize={14} fontWeight={500}>
              {moment(data?.order?.createdAt).format("DD MMM,YYYY")}
            </Typography>
          </Box>
        </Box>

        {!isMobileDevice && !isCreateInvoice && (
          <Box display={"flex"} gap={"10px"}>
            {data?.order?.transportOrder?.transportStatus?.name ===
              statusKeys.ToBePicked && (
              <Button
                variant="soft"
                sx={{ padding: "14px 16px" }}
                onClick={() =>
                  showDialog({
                    component: (
                      <AssignDriverForm
                        close={hideDialog}
                        createInvoiceHandler={() => {
                          hideDialog();
                          setIsCreateInvoice(true);
                          setActiveTab(1);
                        }}
                      />
                    )
                  })
                }
              >
                Assign Driver
              </Button>
            )}
            <Button
              variant="soft"
              sx={{ padding: "14px 16px" }}
              onClick={() => {
                setIsCreateInvoice(true);
                setActiveTab(1);
              }}
            >
              Create Invoice
            </Button>
            {data?.order?.documents?.length > 0 && (
              <Button variant="soft" sx={{ padding: "14px 16px" }}>
                View documents
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          borderRadius: "8px",
          border: "0.4px solid #95A1B0"
        }}
      >
        {/* mobile view */}
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none"
            },
            flexDirection: "column",
            gap: "12px"
          }}
        >
          <StyledAccordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMore color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                "& > div": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 2
                }
              }}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.inboundDate).format(
                    "DD MMM,YYYY"
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.inboundDateLabelText}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {showMore ? (
                    <div onClick={() => setShowMore((prev) => !prev)}>
                      {data?.order?.searchForm?.pickup?.address}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowMore((prev) => !prev)}
                      style={{ display: showMore ? "none" : "flex" }}
                    >
                      {data?.order?.searchForm?.pickup?.address.slice(0, 15)}
                      {"..."}
                    </div>
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupLocationLabelText}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {showMore ? (
                    <div onClick={() => setShowMore((prev) => !prev)}>
                      {data?.order?.searchForm?.destination?.address}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowMore((prev) => !prev)}
                      style={{ display: showMore ? "none" : "flex" }}
                    >
                      {data?.order?.searchForm?.destination?.address.slice(
                        0,
                        15
                      )}
                      {"..."}
                    </div>
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.destinationLabelText}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingTop: 0,
                paddingBottom: 0
              }}
            >
              <DetailsGrid data={data?.order?.searchForm} />
              <Box marginTop={3} marginBottom={3}>
                <TextBox
                  direction={true}
                  value={"Item Description"}
                  label={data?.order?.searchForm?.itemDescription}
                  gap={"8px"}
                />
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </Box>

        {/* desktop view */}
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex"
            },
            flexDirection: "column",
            gap: "12px",
            padding: 5
          }}
        >
          <Box display={"flex"} gap={"60px"}>
            <StyleBox display={"flex"} gap={6}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.inboundDate).format(
                    "DD MMM,YYYY"
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.inboundDateLabelText}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {moment(data?.order?.searchForm?.inboundDate).format(
                    "DD MMM,YYYY"
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupDateLabelText}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {data?.order?.transportOrder?.pickupTime}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  Pickup time
                </Typography>
              </Box>
            </StyleBox>
            <Divider orientation="vertical" sx={{ height: "auto" }} />
            <StyleBox display={"flex"} gap={6}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {showMore ? (
                    <div onClick={() => setShowMore((prev) => !prev)}>
                      {data?.order?.searchForm?.pickup?.address}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowMore((prev) => !prev)}
                      style={{ display: showMore ? "none" : "flex" }}
                    >
                      {data?.order?.searchForm?.pickup?.address.slice(0, 15)}
                      {"..."}
                    </div>
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.pickupLocationLabelText}
                </Typography>
              </Box>
              <East />
              <Box display={"flex"} flexDirection={"column"}>
                <Typography fontSize={14} fontWeight={500}>
                  {showMore ? (
                    <div onClick={() => setShowMore((prev) => !prev)}>
                      {data?.order?.searchForm?.destination?.address}
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowMore((prev) => !prev)}
                      style={{ display: showMore ? "none" : "flex" }}
                    >
                      {data?.order?.searchForm?.destination?.address.slice(
                        0,
                        15
                      )}
                      {"..."}
                    </div>
                  )}
                </Typography>
                <Typography fontSize={14} color={"text.secondary"}>
                  {labels.destinationLabelText}
                </Typography>
              </Box>
            </StyleBox>
          </Box>
        </Box>
      </Box>
      <Box marginTop={2}>
        <CustomTabs
          tabs={[
            {
              label: "Order detail",
              Component: (
                <OrderDetailTab
                  customerBillingDetails={data?.order?.customerBillingDetails}
                  inboundDate={data?.order?.recentOrderItem?.inboundDate}
                  orderCode={data?.order?.transportOrder?.code}
                  orderDate={data?.order?.createdAt}
                  orderDetail={tranportOrderDetail}
                  partnerBillingDetails={invoiceFormData?.transport}
                />
              )
            },
            {
              label: "Invoice detail",
              Component: isManualInvoice ? (
                <InvoiceDetailManualTab
                  setIsManualInvoice={setIsManualInvoice}
                  setManualInvoiceData={setManualInvoiceData}
                />
              ) : (
                <InvoiceDetailTab
                  customerBillingDetails={data?.order?.customerBillingDetails}
                  invoiceFormData={invoiceFormData}
                  isFormFetched={isFormFetched}
                  setIsManualInvoice={setIsManualInvoice}
                  manualInvoiceData={manualInvoiceData}
                  orderId={orderId}
                />
              )
            }
          ]}
          activeTab={activeTab}
          onChange={handleTabChange}
        />
      </Box>
    </Box>
  );
};

export default OrderSummary;

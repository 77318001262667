import { QueryKeys } from "../../constants/query-keys";
import { getRetrievePackagingNotes } from "../../warehouse/actions";
import { useQuery } from "@tanstack/react-query";

export const useFetchRetrievePackagingNotes = (retrieveId) => {
  const retrievePackagingNote = useQuery(
    [QueryKeys.queryFetchRetrievePackagingNotes, retrieveId],
    () => getRetrievePackagingNotes(retrieveId)
  );

  return retrievePackagingNote;
};

import { Grid } from "@mui/material";
import TransportDetailCard from "./TransportDetailCard";
import TruckDetailCard from "./TruckDetailCard";
import UpdatePricesForm from "../pages/UpdatePricesForm";
import UpdateTruckDetailsForm from "../pages/UpdateTruckDetailsForm";
import moment from "moment";
import { useCustomDialog } from "../../shared/customDialog";

const DashboardListItem = (props) => {
  const { showDialog } = useCustomDialog();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <TransportDetailCard
          name={props.name}
          city={props.address.cluster}
          state={props.address.city}
          country={props.address.state}
          listingDate={moment(props.listedAt).format("MMMM DD, YYYY")}
          additionalServices={props.additionalServices}
          id={props._id}
          handleEdit={() => {
            showDialog({
              component: <UpdatePricesForm {...props} />,
            });
          }}
          data={props}
        />
      </Grid>
      {props.status.name === "Active" && (
        <Grid item xs={12} md={7} container spacing={"12px"}>
          {props.vehicles.map((item, index) => (
            <Grid item xs={12} key={index}>
              <TruckDetailCard
                imageSrc={item?.heroImage?.thumbUrl}
                truckNumber={item?.regNo}
                truckMake={ `${item?.weightCapacity} Kgs`}
                truckModel={`${ item?.make?.displayName} | ${item?.model?.displayName}`}
                pricePerKm={item?.rate}
                onEdit={() => {
                  showDialog({
                    component: (
                      <UpdateTruckDetailsForm
                        {...item}
                        partnerId={props.partnerId}
                        refetch={props.refetch}
                      />
                    ),
                  });
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardListItem;

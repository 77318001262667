import * as yup from "yup";

import { Amplify, Auth } from "aws-amplify";
import { Box, FormHelperText, Grid, Link, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { error_msg, labels } from "../../constants";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/auth-context";
import { CountdownTimer } from "../utils/CountdownTimer";
import { MuiOtpInput } from "mui-one-time-password-input";
import { SearchFormKeys } from "../../constants/formKeys";
import { StyledButton } from "../formElements/StyledButton";
import TextInput from "../formElements/TextInput";
import { awsExports } from "../../aws-exports";
import { generatePassword } from "../utils";
import styled from "@emotion/styled";
import { useCustomDialog } from "../customDialog";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import Storage from "../utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { isMobileDevice } from "../../App";

Amplify.configure(awsExports);
const schema = yup.object({
  [SearchFormKeys.loginMobileNumber]: yup
    .string()
    .required(error_msg.enterMobileNumber)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  //[SearchFormKeys.otp]: yup.string().required(error_msg.enterOtp),
});

const typographyStyle = {
  fontSize: "13px",
  letterSpacing: "0em",
};

const h4Style = {
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.002em",
  textAlign: "left",
  display: "flex",
};

const h5Style = {
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "19px",
  letterSpacing: "0.005em",
  textAlign: "left",
  color: "#607088",
  position: "relative",
  top: "3px",
};

const MobileNumberScreen = ({ control, error }) => {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} display={"flex"}>
        <Typography component={"h4"} sx={h4Style}>
          {labels.login}
        </Typography>
        <Typography px={1} component={"h5"} sx={h5Style}>
          or
        </Typography>
        <Typography component={"h4"} sx={h4Style}>
          {labels.signup}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          type="number"
          name={SearchFormKeys.loginMobileNumber}
          placeholder={labels.enterMobileNumber}
        />
      </Grid>
      {!isMobileDevice && (
        <Grid item xs={12}>
          <Typography sx={typographyStyle}>
            {labels.byContinueIAgree}
            <Link href="#" underline="none" px={1}>
              {labels.termsOfUse}
            </Link>
            &
            <Link href="#" underline="none" px={1}>
              {labels.privacyPolicy}
            </Link>
          </Typography>
        </Grid>
      )}
      {isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12} mt={3}>
        <StyledButton fullWidth type="submit" variant="contained">
          {labels.proceed}
        </StyledButton>
      </Grid>
      <Grid item>
        <FormHelperText error>{error}</FormHelperText>
      </Grid>
      {!isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12} textAlign={"center"}>
        <Typography sx={typographyStyle}>
          {labels.havingTroubleLoggingIn}
          <Link href="#" underline="none" px={1}>
            {labels.getHelp}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

const OtpConfirmScreen = ({ control, error, mobileNumber, resendOTP }) => {
  const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
    display: "flex",
    gap: "16px",
    maxWidth: 650,
    marginInline: "auto",
    "& .css-91vcyr-MuiInputBase-root-MuiOutlinedInput-root": {
      height: 44,
      width: 44,
      borderWidth: 0,
      borderRadius: 8,
    },
    "& .css-91vcyr-MuiInputBase-root-MuiOutlinedInput-root:hover .Mui-focused":
      {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
  }));
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Typography component={"h4"} sx={h4Style}>
          {labels.verifyWithOTP}
        </Typography>
        <Typography sx={typographyStyle}>
          {labels.sentTo} +91 {mobileNumber}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={SearchFormKeys.otp}
          render={({ field, fieldState: { error } }) => (
            <Box>
              <MuiOtpInputStyled
                autoFocus
                sx={{ gap: 1 }}
                {...field}
                length={6}
                id={SearchFormKeys.otp}
              />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={12} mt={4} textAlign={isMobileDevice ? "center" : "left"}>
        <Typography sx={typographyStyle}>
          <CountdownTimer resendOTP={() => resendOTP(mobileNumber)} />
        </Typography>
      </Grid>
      {isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12} mt={3}>
        <StyledButton fullWidth type="submit" variant="contained">
          {labels.continue}
        </StyledButton>
      </Grid>
      <Grid item>
        <FormHelperText error>{error}</FormHelperText>
      </Grid>
      {!isMobileDevice && <Grid item m={8}></Grid>}
      <Grid item xs={12}>
        <Typography sx={typographyStyle}>
          {labels.havingTroubleLoggingIn}
          <Link href="#" underline="none" px={1}>
            {labels.getHelp}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Login = () => {
  const { control, getValues, handleSubmit, register, unregister } = useForm({
    defaultValues: {
      [SearchFormKeys.loginMobileNumber]: "",
      [SearchFormKeys.otp]: "",
    },
    resolver: yupResolver(schema),
  });
  const { hideDialog } = useCustomDialog();
  const navigate = useNavigate();
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);

  const [showOTP, setShowOTP] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [error, setError] = useState("");

  const { setLoginToken } = useContext(AuthContext);

  const handleMobileNumberSubmit = async (mobileNumber, signUp = false) => {
    try {
      if (signUp) {
        await Auth.signUp({
          username: mobileNumber,
          password: generatePassword(),
        });
        handleMobileNumberSubmit(mobileNumber, false);
      } else {
        const user = await Auth.signIn(mobileNumber);
        setCognitoUser(user);
        console.log("OTP ", user?.challengeParam?.passCode);
      }
      setShowOTP(true);
    } catch (error) {
      if (error.code === "UserNotFoundException") {
        handleMobileNumberSubmit(mobileNumber, true);
        return;
      }
      console.error("Error signing in: ", error);
      setError(error?.message || error_msg.errorSigningMobile);
    }
  };

  const handleOTPSubmit = async (otp) => {
    try {
      if (otp) {
        await Auth.sendCustomChallengeAnswer(cognitoUser, otp);
        const { signInUserSession } = await Auth.currentAuthenticatedUser();
        setLoginToken(
          signInUserSession?.accessToken?.jwtToken,
          signInUserSession?.idToken?.jwtToken
        );
        if (!service) navigate("/explore/warehouse-search-form");
        hideDialog();
      }
    } catch (error) {
      console.error("Error signing in: ", error);
      setError(error || error_msg.errorSigningOTP);
    }
  };

  const formHandler = (data) => {
    showOTP
      ? handleOTPSubmit(data[SearchFormKeys.otp])
      : handleMobileNumberSubmit(data[SearchFormKeys.loginMobileNumber], false);
  };

  useEffect(() => {
    if (showOTP) {
      register(SearchFormKeys.otp);
    } else {
      register(SearchFormKeys.loginMobileNumber);
      unregister(SearchFormKeys.otp);
    }
  }, [register, unregister, showOTP]);

  return (
    <>
      <Box component={"form"} onSubmit={handleSubmit(formHandler)}>
        {showOTP ? (
          <OtpConfirmScreen
            control={control}
            error={error}
            mobileNumber={getValues(SearchFormKeys.loginMobileNumber)}
            resendOTP={handleMobileNumberSubmit}
          />
        ) : (
          <MobileNumberScreen control={control} error={error} />
        )}
      </Box>
    </>
  );
};

export default Login;

import { AuthContext } from "../shared/context/auth-context";
import { QueryKeys } from "../constants/query-keys";
import Storage from "../shared/utils/Storage";
import { StorageKeys } from "../constants/storage-keys";
import { fetchProfile } from "../warehouse/actions";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

export const useFetchProfileQueryHook = () => {
  const { currentUser } = useContext(AuthContext);
  const profileQuery = useQuery(
    [QueryKeys.queryGetProfile],
    () => fetchProfile(currentUser),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(currentUser),
      onSuccess: (res) => {
        Storage.setItem(StorageKeys.PARTNER_ID, res.user.partner);
      },
    }
  );
  return profileQuery;
};

import React, { useEffect } from "react";
import TextInput from "../../shared/formElements/TextInput";
import DropDown from "../../shared/formElements/DropDown";
import { transport_labels, warehouse_labels } from "../../constants";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import { transportSearchFormKeys } from "../../constants/formKeys";
import { Box, Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Remove } from "@mui/icons-material";

export const vehicleFormSchema = yup.object({
  [transportSearchFormKeys.truckNumber]: yup
    .string()
    .required(
      `${transport_labels.truckNumberLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.truckMake]: yup
    .string()
    .required(
      `${transport_labels.truckMakeLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.truckModel]: yup
    .string()
    .required(
      `${transport_labels.truckModelLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.pricePerKm]: yup
    .string()
    .required(
      `${transport_labels.pricePerKmLabelText} ${warehouse_labels.isRequired}`
    ),
  [transportSearchFormKeys.truckImage]: yup
    .mixed()
    .required("Image is required")
    .test(
      "is-single-image",
      `${transport_labels.truckUploadButtonText}`,
      (value) => Boolean(value) && value
    )
});

const AddVehicleForm = ({
  formData,
  setTruckDetails,
  setShowVehicleForm,
  showRemove,
  editVehicleData,
  showDefaultValues
}) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: showDefaultValues
      ? {
          [transportSearchFormKeys.truckNumber]: editVehicleData.truckNumber,
          [transportSearchFormKeys.truckModel]: editVehicleData.truckModel,
          [transportSearchFormKeys.truckMake]: editVehicleData.truckMake,
          [transportSearchFormKeys.pricePerKm]: editVehicleData.pricePerKm,
          [transportSearchFormKeys.truckImage]: editVehicleData.truckImage
        }
      : {},
    resolver: yupResolver(vehicleFormSchema)
  });

  const truckMake = watch(transportSearchFormKeys.truckMake);

  const onSubmit = (data) => {
    setTruckDetails((prevDetails) => [
      ...prevDetails,
      {
        truckNumber: data[transportSearchFormKeys.truckNumber],
        truckModel: data[transportSearchFormKeys.truckModel],
        truckMake: data[transportSearchFormKeys.truckMake],
        pricePerKm: data[transportSearchFormKeys.pricePerKm],
        truckImage: data[transportSearchFormKeys.truckImage]
      }
    ]);
    reset();
    setShowVehicleForm(false);
  };

  const onRemove = () => {
    reset();
    setShowVehicleForm(false);
  };

  useEffect(
    () => {
      if (truckMake !== editVehicleData?.truckMake) {
        reset((formValues) => ({
          ...formValues,
          [transportSearchFormKeys.truckModel]: ""
        }));
      }
    }, // eslint-disable-next-line
    [truckMake]
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        border: "0.6px solid #B7BEC7",
        padding: "12px 16px 14px 12px",
        borderRadius: "8px",
        height: "fit-content"
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            control={control}
            name={transportSearchFormKeys.truckNumber}
            label={transport_labels.truckNumberLabelText}
            placeholder={transport_labels.truckNumberPlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropDown
            control={control}
            name={transportSearchFormKeys.truckMake}
            label={transport_labels.truckMakeLabelText}
            placeholder={transport_labels.truckMakePlaceholderText}
            options={formData?.vehicles}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropDown
            control={control}
            name={transportSearchFormKeys.truckModel}
            label={transport_labels.truckModelLabelText}
            placeholder={transport_labels.truckModelPlaceholderText}
            options={
              truckMake
                ? Object.values(
                    formData?.vehicles.find((item) => item.name === truckMake)
                      ?.models
                  )
                : []
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={transportSearchFormKeys.pricePerKm}
            label={transport_labels.pricePerKmLabelText}
            placeholder={transport_labels.pricePerKmPlaceholderText}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUpload
            name={transportSearchFormKeys.truckImage}
            control={control}
            compact={true}
            uploadButtonText={transport_labels.truckUploadButtonText}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={6} md={5}>
          <Button
            variant="contained"
            sx={{ height: "40px", width: { xs: "140px", md: "160px" } }}
            onClick={handleSubmit(onSubmit)}
          >
            Save Vehicle
          </Button>
        </Grid>
        {showRemove && (
          <Grid item xs={6} md={7}>
            <Button
              variant="text"
              sx={{ height: "40px", width: "160px" }}
              startIcon={<Remove />}
              color="error"
              onClick={onRemove}
            >
              Remove
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AddVehicleForm;

import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { DraftsOutlined, PhoneInTalkOutlined } from "@mui/icons-material";

import ContactForm from "./ContactForm";
import React from "react";
import { home_labels } from "../../constants";

const StyledContact = ({ title, icon: Icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& > svg": {
          color: "#B7BEC7",
          mr: 3,
          fontSize: 20,
        },
        mb: {
          xs: "26px",
          md: 3,
        },
      }}
    >
      <Icon />
      <Typography
        sx={{
          fontSize: {
            xs: 15,
            md: 16,
          },
          lineHeight: {
            xs: "21.9px",
            md: "24px",
          },
          letterSpacing: "-0.01em",
          fontWeight: {
            xs: 400,
            md: 300,
          },
        }}
        color="#95A1B0"
      >
        {title}
      </Typography>
    </Box>
  );
};

const ContactUsSection = ({ title }) => {

  return (
    <Box
      id="contact-us"
      sx={{
        background: "#0F2744",
        padding: {
          xs: "60px 0 40px 0",
          md: "100px 0 70px 0",
        },
        mt: {
          xs: "18px",
          md: "55px",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xs: "0 20px",
            md: "0 95px",
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: {
                  xs: 26,
                  md: 36,
                },
                lineHeight: {
                  xs: "40px",
                  md: "64px",
                },
                letterSpacing: "-0.02em",
                mb: {
                  xs: "20px",
                  md: 2,
                },
              }}
              color="white"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: 16,
                  md: 16,
                },
                lineHeight: {
                  xs: "24px",
                  md: "24px",
                },
                letterSpacing: "-0.01em",
                maxWidth: {
                  xs: "auto",
                  md: 352,
                },
              }}
              color="#B7BEC7"
              fontWeight={300}
            >
              <Box display={"flex"}>
                <Box width={"20%"}>{home_labels.contactUsSubText1}</Box>
                <Box width={"80%"}>{home_labels.contactUsSubText2}</Box>
              </Box>
            </Typography>
            <Divider
              sx={{
                borderBottom: "0.4px solid #B7BEC7",
                borderBottomWidth: "0.4px",
                maxWidth: 370,
                mt: {
                  xs: "20px",
                  md: 5,
                },
                mb: {
                  xs: 3,
                  md: 4,
                },
              }}
            />
            <StyledContact
              title={home_labels.contactNumberText}
              icon={PhoneInTalkOutlined}
            />
            <StyledContact
              title={home_labels.infoEmailText}
              icon={DraftsOutlined}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mt: {
                xs: "30px",
                md: 0,
              },
            }}
          >
            <ContactForm/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUsSection;

import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import WarexLogo from "../../assets/Warex-white.png";
import { home_labels } from "../../constants";
import { socialLinks } from "../../shared/layouts/SocialBar";
import { Link } from "react-router-dom";

const footer_links = [
  {
    label: "About Us",
    id: "about-us",
  },
  {
    label: "Contact",
    id: "contact-us",
  },
  {
    label: "Industry",
    id: "industries",
  },
  {
    label: "Terms and Conditions",
    id: "terms",
  },
  {
    label: "Privacy Policy",
    id: "privacy",
  }
];

const Footer = () => {
  return (
    <Box
      sx={{
        background: "#000000",
        padding: {
          xs: "60px 0 73px 0",
          md: "69px 0 109px 0",
        },
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xs: "0 20px",
            md: "0 95px",
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box marginBottom={"12px"} marginLeft={-1.9}>
              <img src={WarexLogo} alt="warex" />
            </Box>
            <Typography
              fontSize={14}
              lineHeight={"21px"}
              color="#B7BEC7"
              fontWeight={300}
              letterSpacing={"-0.01em"}
              maxWidth={252}
            >
              {home_labels.footerSubtext}
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              marginTop={4}
              sx={{
                mb: {
                  xs: 6,
                  md: 0,
                },
              }}
            >
              {socialLinks.map(({ icon: Icon, label }) => (
                <Box
                  key={label}
                  sx={{
                    mr: 1,
                    "& > button:first-child": {
                      paddingLeft: 0,
                    },
                    "& > button > svg": {
                      color: "white",
                      fontSize: 18,
                    },
                  }}
                >
                  <IconButton>
                    <Icon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} flexDirection={"row"}>
            <Box
              sx={{
                mr: {
                  xs: "60px",
                  md: "84px",
                },
              }}
            >
              {footer_links.map(
                (item, index) =>
                  index < 3 && (
                    <Typography
                      key={index}
                      display={"flex"}
                      fontSize={14}
                      lineHeight={"21px"}
                      color="#B7BEC7"
                      fontWeight={300}
                      letterSpacing={"-0.01em"}
                      underline="none"
                      marginBottom={"20px"}
                      component={"a"}
                      href={`#${item.id}`}
                      sx={{ textDecoration: "none", whiteSpace: "nowrap" }}
                    >
                      {item.label}
                    </Typography>
                  )
              )}
            </Box>
            <Box>
              {footer_links.map(
                (item, index) =>
                  index > 2 && (
                    <Typography
                      key={index}
                      display={"flex"}
                      fontSize={14}
                      lineHeight={"21px"}
                      color="#B7BEC7"
                      fontWeight={300}
                      letterSpacing={"-0.01em"}
                      underline="none"
                      marginBottom={"20px"}
                      component={"a"}
                      href={`#${item.id}`}
                      sx={{ textDecoration: "none", whiteSpace: "nowrap" }}
                    >
                      {item.label}
                    </Typography>
                  )
              )}
              <Typography
                display={"flex"}
                fontSize={14}
                lineHeight={"21px"}
                color="#B7BEC7"
                fontWeight={300}
                letterSpacing={"-0.01em"}
                underline="none"
                marginBottom={"20px"}
                component={Link}
                to={"/faqs"}
                sx={{ textDecoration: "none", whiteSpace: "nowrap" }}
              >
                {"FAQs"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

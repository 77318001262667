import { createTransportInvoice } from "../../transport/actions";
import { useMutation } from "@tanstack/react-query";

export const useMutateCreateTransportInvoice = () => {
  const createTransportInvoiceMutate = useMutation(({ formData }) =>
    createTransportInvoice(formData)
  );

  return createTransportInvoiceMutate;
};

import { Typography } from "@mui/material";

export const quotationForm = [
  {
    label: "Storage Cost",
    uom: "storage_cost_uom",
    duration: "storage_cost_duration",
    quantity: "storage_cost_quantity",
    rate: "storage_cost_rate",
    total: "storage_cost_total"
  },
  {
    label: "Labor Cost",
    uom: "labor_cost_uom",
    duration: "labor_cost_duration",
    quantity: "labor_cost_quantity",
    rate: "labor_cost_rate",
    total: "labor_cost_total"
  },
  {
    label: "Equipments",
    uom: "equipments_uom",
    duration: "equipments_duration",
    quantity: "equipments_quantity",
    rate: "equipments_rate",
    total: "equipments_total"
  },
  {
    label: "Other / Misc.",
    uom: "other_uom",
    duration: "other_duration",
    quantity: "other_quantity",
    rate: "other_rate",
    total: "other_total"
  }
];

export const quotation_labels = [
  "UOM",
  "Duration",
  "Quantity",
  "Rate",
  "Total"
];

export const packageConditionColorCodes = {
  Damaged: "error.main",
  "Critically Damaged": "error.dark",
  Healthy: "success.dark"
};

export const packageConditionOptions = [
  {
    name: "Critical Damaged!",
    displayName: (
      <Typography fontSize={14} color={"error.dark"}>
        Critical Damaged!
      </Typography>
    )
  },
  {
    name: "Damaged",
    displayName: (
      <Typography fontSize={14} color={"error.main"}>
        Damaged
      </Typography>
    )
  },
  {
    name: "Healthy",
    displayName: (
      <Typography fontSize={14} color={"success.dark"}>
        Healthy
      </Typography>
    )
  }
];

export const mrr_dummy_data = [
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "Healthy",
    rackNumber: ""
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: ""
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: ""
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: ""
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: ""
  },
  {
    packageName: "Cotton shirt_30gsm",
    packagingMaterial: "Cartoon",
    packageSize: "3’ X 3’",
    packageCondition: "",
    rackNumber: ""
  }
];

import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";

import DashboardListItem from "../components/DashboardListItem";
import { useFetchProfileQueryHook } from "../../query-hooks/useFetchProfileQueryHook";
import { useFetchWarehouseDetailsQuery } from "../../query-hooks/warehouseDashboard/useFetchWarehouseDetailsQuery";

const Listings = () => {
  const { data: profileData } = useFetchProfileQueryHook();
  const {
    data: warehouseDetail,
    isFetching,
    isFetched,
    refetch,
  } = useFetchWarehouseDetailsQuery(profileData?.user?.partner);

  return (
    <Box
      sx={{
        padding: {
          xs: 0,
          md: "16px 0",
        },
      }}
    >
      <Backdrop
        sx={{ color: "#FFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isFetched && (
        <Grid container spacing={"28px"}>
          <Grid item xs={12}>
            <DashboardListItem
              {...warehouseDetail?.partner}
              partnerId={profileData?.user?.partner}
              refetch={refetch}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Listings;
